import React, { Component, useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
  Label,
} from "reactstrap";
import { ChangeSiteInformation } from "../../stores/actions/ChangeSiteInformationAction.js";
//@ts-ignore
import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import { logout } from "../../stores/actions/authentificationActions";

import LogoReact from "../../assets/Logo_Saur_Industrieblanc.svg";
import Avatar from "react-avatar";
import "./SaurHeader.css";
import { GetAccessToken } from "../../authentication/token";
import { logoutRequest } from "../../utils/utils";
import { LocalStorage } from "../../utils/localStorage";
import { useKeycloak } from "@react-keycloak/web";
import { ReduxAuthState } from "../../types/authentificationstoretypes";
import { SiteInformation } from "../../types/siteInformation";

interface SaurHeaderProps {
  authentification: ReduxAuthState;
  siteInformation: SiteInformation;
}

interface Site {
  id: string;
  libelleFr: string;
}

const SaurHeader = (props: SaurHeaderProps) => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const [listeSite, setListeSite] = useState<Site[]>([]);
  const [nomSite, setNomSite] = useState<string>("");
  const [siteId, setSiteId] = useState<string>("");

  useEffect(() => {
    getListSite();
  }, []);

  const onchangeSite = async (siteId: string, nomSite: string) => {
    setNomSite(nomSite);
    setSiteId(siteId);
    await dispatch(ChangeSiteInformation(siteId, nomSite));
  };

  // On click logout
  const onClick = async () => {
    keycloak.logout();
    localStorage.setItem("logginWith", "");
    await dispatch(logout());
  };

  const getListSite = useCallback(async () => {
    try {
      let result = await fetch(
        `${(window as any).appSettings.apiendpoint}/Site`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${await GetAccessToken(
              props.authentification
            )}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) {
        let data = await result.json();
        setListeSite(data);
        setNomSite(data[0].libelleFr);
        setSiteId(data[0].id);
        await dispatch(ChangeSiteInformation(data[0].id, data[0].libelleFr));
      }
    } catch (ex) {}
  }, []);

  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none " display="lg" mobile />
      <AppNavbarBrand
        full={{
          src: LogoReact,
          text: "Nextep",
          width: 200,
          height: 100,
          alt: "Saur Logo",
        }}
        minimized={{
          src: LogoReact,
          height: 100,
          alt: "Saur Logo",
        }}
      />
      <AppSidebarToggler className="d-md-down-none" />
      <div>
        <Nav style={{ marginRight: "20px" }} className="ml-auto" navbar>
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
              <Label
                style={{
                  color: "#FFCD00",
                  fontSize: "24px",
                  marginLeft: "10px",
                  display: "flex",
                }}
              >
                <div className="site-title-parenthese left" />
                {nomSite}
                <div className="site-title-parenthese right" />
              </Label>
            </DropdownToggle>
            {listeSite && listeSite.length > 1 && (
              <DropdownMenu style={{ left: 15 }}>
                {listeSite.map((site, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => onchangeSite(site.id, site.libelleFr)}
                  >
                    <i></i>
                    {site.libelleFr}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            )}
          </UncontrolledDropdown>
        </Nav>
      </div>
      <Nav style={{ marginRight: "20px" }} className="ml-auto" navbar>
        <UncontrolledDropdown nav direction="down">
          <DropdownToggle nav>
            <Label
              style={{
                color: "#0a3c5c",
                fontSize: "13px",
                marginLeft: "10px",
                marginRight: "16px",
              }}
            >
              {props.authentification && props.authentification.userInfo
                ? props.authentification.userInfo.name
                : ""}{" "}
            </Label>
            <Avatar
              name={
                props.authentification && props.authentification.userInfo
                  ? props.authentification.userInfo.name
                  : ""
              }
              color="gray"
              size="40"
              round={true}
            />
          </DropdownToggle>
          <DropdownMenu right style={{ right: 15 }}>
            <DropdownItem onClick={onClick}>
              <i className="fa fa-lock"></i>Se déconnecter
            </DropdownItem>
            <DropdownItem onClick={onClick}>
              <i className="cui-settings"></i>Paramètres
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </React.Fragment>
  );
};

export default connect((state) => state)(SaurHeader);
