//Librairies
import React, { Component } from "react";
import { Label, Spinner } from "reactstrap";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { connect } from "react-redux";
import moment from "moment";
//Services
import { GetAccessToken } from "../../authentication/token";
//css
import "react-circular-progressbar/dist/styles.css";
import "./WasteAndUnderProduct.scss";

class WasteAndUnderProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ListDechetsProduits: null,
      SiteId: this.props.siteInformation.siteId,
      Unite: "S",
      Loading: true,
    };
  }

  async LoadData() {
    try {
      this.setState({ Loading: true });
      let result = await fetch(
        `${window.appSettings.apiendpoint}/DonneeIndicateur/GetDechetsProduits`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",

            Authorization: `Bearer ${await GetAccessToken(
              this.props.authentification
            )}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            DateDebut: moment(this.state.DateDebut).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            TypeAffichage: this.state.Action,
            SiteId: this.state.SiteId,
          }),
        }
      );
      if (result.status === 200) {
        let data = await result.json();
        this.setState({ ListDechetsProduits: data, Loading: false });
      }
    } catch (ex) {}
  }

  //Method executed After rendering compenont
  async componentDidMount() {
    await this.LoadData();
  }

  // Method to set state when props change
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.DateDebut !== prevState.DateDebut) {
      return {
        DateDebut: nextProps.DateDebut,
        Action: nextProps.Action,
      };
    } else return null;
  }

  // Method de refresh data after changing prpos
  async componentDidUpdate(prevProps) {
    if (prevProps.DateDebut !== this.state.DateDebut) {
      this.setState({
        DateDebut: prevProps.DateDebut,
        Action: prevProps.Action,
      });
      await this.LoadData();
      if (prevProps.Action !== this.state.Action) {
        this.SetUnite();
      }
    }
    if (this.state.SiteId !== this.props.siteInformation.siteId) {
      this.setState({ SiteId: this.props.siteInformation.siteId });
      await this.LoadData();
    }
  }

  SetUnite() {
    switch (this.state.Action) {
      case "Annee":
        this.setState({ Unite: "A" });
        break;

      case "Mois":
        this.setState({ Unite: "M" });
        break;

      case "Semaine":
        this.setState({ Unite: "S" });
        break;

      default:
        break;
    }
  }

  render() {
    return (
      <div>
        <Label className="Label-Widget">Déchets et sous produits</Label>
        {this.state.Loading && (
          <div className="Spinner">
            <Spinner size="100px" style={{ color: "#FFCD00" }} />
          </div>
        )}
        <div className="row no-gutters flex-lg-nowrap">
          {this.state.ListDechetsProduits &&
            this.state.ListDechetsProduits.map((dechet, index) => (
              <div
                key={index}
                className="containerBorder p-2 col-12 col-lg-4 m-1 flex-grow-1"
              >
                <div className="ProductTitleDiv  ">
                  <Label className="ProductTitle">
                    <strong>{dechet.titreDechet}</strong>
                  </Label>
                </div>
                <div className="InformationsDiv">
                  <div className="QuantityDiv">
                    <div className="QuantityTitleDiv">
                      <Label className="QuantityTitle">Volume Evacué</Label>
                    </div>
                    <div className="QuantityInformationDiv">
                      <Label className="QuantityInformation">
                        {" "}
                        <strong className="text-center">
                          {!dechet.volume ? "--" : parseInt(dechet.volume)}{" "}
                          {dechet.uniteVolume}/{this.state.Unite}
                        </strong>
                      </Label>
                      {this.state.Action !== "Semaine" && (
                        <Label className="QuantityInformationComparison">
                          {" "}
                          <strong className="text-center">
                            {!dechet.differenceVolume
                              ? "--"
                              : parseInt(dechet.differenceVolume, 10)}{" "}
                            {dechet.uniteVolume} ({dechet.periode}){" "}
                          </strong>
                        </Label>
                      )}
                    </div>
                  </div>
                  {dechet.titreQuantite && <div className="SeparationBorder" />}
                  {dechet.titreQuantite && (
                    <div style={{ display: "inline-grid", width: "50%" }}>
                      <div className="QualityTitleDiv">
                        <Label className="QualityTitle">
                          {dechet.titreQuantite}
                        </Label>
                      </div>
                      <div className="QualityInformationDiv">
                        <div className="CircularProgressbarWithChildrenDiv">
                          <CircularProgressbarWithChildren
                            background
                            value={
                              !dechet.quantite
                                ? 0
                                : parseInt(dechet.quantite, 10)
                            }
                          >
                            <div style={{ display: "inline-grid" }}>
                              <Label className="PourcentageQuality">
                                <strong className="text-center">
                                  {!dechet.quantite
                                    ? "--"
                                    : parseInt(dechet.quantite, 10)}
                                  {dechet.uniteQuantite}
                                </strong>
                              </Label>
                              {this.state.Action !== "Semaine" && (
                                <Label className="PourcentageQualityComparaison">
                                  <strong className="text-center">
                                    {!dechet.differenceQuantite
                                      ? "--"
                                      : parseInt(dechet.differenceQuantite, 10)}
                                    {dechet.uniteQuantite} {dechet.periode}
                                  </strong>
                                </Label>
                              )}
                            </div>
                          </CircularProgressbarWithChildren>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default connect((state) => state)(WasteAndUnderProduct);
