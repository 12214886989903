import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default function Delete({ onSubmit, loadingModal, onClose, openModal, rows = [] }) {

    const CloseButton = (
        <div>
            <button onClick={onClose} className="btn close-btn"></button>
        </div>
    )

    return (<Modal isOpen={openModal} size="lg" >
        <ModalHeader className="text-primary pl-5" close={CloseButton} ></ModalHeader>
        <ModalBody className="p-5">
            <form id="deleteItem" onSubmit={(e)=>{e.preventDefault(); onSubmit()}}>
                <div> Êtes-vous sûr(e) de vouloir supprimer le(s) document(s) suivants ?</div>
                <ul>
                    {rows.map((name, idx) => <div key={idx}>{name}</div>)}
                </ul>
            </form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
            <button disabled={loadingModal} className="btn btn-outline-primary btn-lg mb-3" onClick={onClose}>Annuler</button>
            <button form="deleteItem" type="submit" disabled={loadingModal} className="btn btn-primary btn-lg mb-3">Valider</button>
        </ModalFooter>
    </Modal>)
}