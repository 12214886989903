import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

export const validate = (getValues) => (value) => {
  const { valeurLabo = "", valeurSiccite = "" } = getValues();

  if (valeurLabo !== "" || value !== "" || valeurSiccite !== "") {
    return true;
  }

  return false;
};

export default function AddValue({
  newModal,
  toggleValidEdit,
  handleSubmit = () => {},
  register = () => {},
  loadingModal,
  cell,
  errors,
  errorMessage,
  onCloseAll,
  getValues,
  triggerValidation,
}) {
  const CloseButton = (
    <div>
      <button onClick={onCloseAll} className="btn close-btn"></button>
    </div>
  );

  return (
    <Modal isOpen={newModal} size="lg">
      <ModalHeader className="pl-5" close={CloseButton}>
        <label className="firstUpper mt-1 ml-4 h2 d-block">
          {cell && cell.rowInfo && cell.rowInfo.libelleFr}
        </label>
        <label className="ml-4 d-block">{cell && cell.date}</label>
      </ModalHeader>
      <ModalBody className="p-5">
        <form
          id="newvaleur"
          onSubmit={handleSubmit((data) => toggleValidEdit(data, cell))}
        >
          <div className="mb-4">
            <div className="edit-label">
              <input
                disabled={loadingModal}
                placeholder="Valeur"
                name="valeur"
                className={` ${
                  errors && errors.valeur ? "error-field" : ""
                } edit-input`}
                ref={register({
                  validate: validate(getValues),

                  // value => {
                  //     const { valeurLabo = "", valeurSiccite = "" } = getValues();

                  //     if (valeurLabo !== "" || value !== "" || valeurSiccite !== "") {
                  //         return true
                  //     }

                  //     return false;

                  // }
                  pattern: /^\d*((,)\d+)?$/,
                })}
              />{" "}
              {cell && cell.rowInfo && cell.rowInfo.unite}
            </div>
            {cell && cell.rowInfo && cell.rowInfo.indicateurIdSiccite && (
              <div className="edit-label">
                <input
                  disabled={loadingModal}
                  onChange={() => {
                    triggerValidation();
                  }}
                  placeholder="Valeur de siccite"
                  name="valeurSiccite"
                  className={`edit-input ${
                    errors && errors.valeurSiccite ? "error-field" : ""
                  }  `}
                  ref={register({ pattern: /^\d*((,)\d+)?$/ })}
                />{" "}
                {cell && cell.rowInfo && cell.rowInfo.uniteSiccite}
              </div>
            )}
            {cell && cell.rowInfo && cell.rowInfo.valeurLabo && (
              <div className="edit-label">
                <input
                  disabled={loadingModal}
                  onChange={() => {
                    triggerValidation();
                  }}
                  placeholder="Valeur analyse agréée"
                  name="valeurLabo"
                  className={`edit-input ${
                    errors && errors.valeurLabo ? "error-field" : ""
                  }  `}
                  ref={register({ pattern: /^\d*((,)\d+)?$/ })}
                />{" "}
                {cell && cell.rowInfo && cell.rowInfo.unite}
              </div>
            )}
            {loadingModal && (
              <div className="Spinner d-flex justify-content-center">
                <Spinner style={{ color: "#FFCD00" }} />
              </div>
            )}
            {errorMessage && (
              <p className="error-text d-flex justify-content-center">
                {errorMessage}
              </p>
            )}
          </div>
        </form>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <button
          disabled={loadingModal}
          className="btn btn-outline-primary btn-lg mb-3"
          onClick={onCloseAll}
        >
          Annuler
        </button>
        <button
          disabled={loadingModal}
          type="submit"
          form="newvaleur"
          className="btn btn-primary btn-lg mb-3"
        >
          Valider
        </button>
      </ModalFooter>
    </Modal>
  );
}
