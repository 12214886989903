//librairies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useTable, useRowSelect, usePagination, useSortBy } from "react-table";
import fileDownload from "js-file-download";
//Assets
import "./document.scss";
import chevron from "../../assets/doc-chevron.svg";
import bin from "../../assets/doc-bin.svg";
import edit from "../../assets/doc-edit.svg";
import share from "../../assets/doc-share.svg";
import download from "../../assets/doc-download.svg";
import update from "../../assets/doc-update.svg";
import ModalEdit from "./Modals/edit";
import ModalDelete from "./Modals/delete";
import Modalimport from "./Modals/import";
//Services
import * as DocumentServices from "../../services/documentServices";
//Application
import * as Navigation from "../../components/SaurLayout/navigation";
import {
  IndeterminateCheckbox,
  formatedDate,
  Pagination,
  Tableau,
} from "../../components/Helpers/ReactTableHelper";
import { addListener } from "@okta/okta-auth-js";

export async function importFile(
  { authentification, SiteId, categorieDocId, TypeDocId, LibelleFr, File },
  { pageSize, pageNumber, sortBy, sortOrder, onDataFetch, setImportModal }
) {
  try {
    const resultDoc = await DocumentServices.ImportFile(authentification, {
      siteId: SiteId,
      CotegorieDocId: categorieDocId,
      TypeDocId: TypeDocId,
      LibelleFr: LibelleFr,
      File: File,
    });

    //réponse correcte on recharge le tableau
    if (resultDoc.status === 200) {
      onDataFetch({
        pageSize: pageSize,
        pageIndex: pageNumber,
        SortBy: sortBy,
        SortOrder: sortOrder,
      }).then(() => {
        setImportModal(false);
      });
    }
  } catch (error) {
    return Promise.reject("Erreur serveur");
  }
}

function Table({
  data,
  onSelectedRow = () => {},
  onDataFetch,
  pageNumber = 1,
  pageSize = 30,
}) {
  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: "id", // accessor is the "key" in the data
      },
      {
        Header: "Libellé",
        accessor: "libelleFr", // accessor is the "key" in the data
      },
      {
        Header: "Nom du fichier",
        accessor: "nomFichier",
      },
      {
        Header: "Ajouté le",
        accessor: "dateCreation",
        Cell: formatedDate,
      },
      {
        Header: "Modifié le",
        accessor: "dateModification",
        Cell: formatedDate,
      },
    ],
    []
  );

  const {
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    gotoPage,
    prepareRow,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    previousPage,
    nextPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: "id",
        sortBy: [{ id: "dateModification", desc: true }],
        pageSize: pageSize,
      },
      manualPagination: true, // Tell the usePagination
      manualSortBy: true,
      defaultCanSort: true,
      disableSortRemove: true,
      disableMultiSort: true,
      pageCount: pageNumber,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((visiblecolumns) => [
        // Let's make a column for selection
        {
          id: "selection",
          accessor: "selector",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          disableSortBy: true,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...visiblecolumns,
      ]);
    }
  );

  useEffect(() => {
    onSelectedRow(selectedFlatRows);
  }, [onSelectedRow, selectedFlatRows]);

  useEffect(() => {
    const SortBy = sortBy[0].id;
    const SortOrder = sortBy[0].desc ? "desc" : "asc";
    onDataFetch({ pageIndex, pageSize, SortBy, SortOrder });
  }, [onDataFetch, pageIndex, pageSize, sortBy]);

  return (
    <>
      <Tableau
        className="tableaudoc"
        prepareRow={prepareRow}
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        isSorted={true}
        headerGroups={headerGroups}
        rows={rows}
      />
      {rows.length > pageSize - 1 && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          previousPage={previousPage}
        />
      )}
    </>
  );
}

function loadData(
  authentification,
  { categorieDocId, siteId, pageNumber, pageSize, SortBy, SortOrder }
) {
  return DocumentServices.GetListOfModeleDocumentSimple(authentification, {
    CategorieDocId: categorieDocId,
    siteId: siteId,
    PageNumber: pageNumber,
    PageSize: pageSize,
    SortBy,
    SortOrder,
  });
}

function Document({ authentification, siteInformation }) {
  //const notif = useContext(NotifContext);

  //gestion des modales
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [importModal, setImportModal] = useState(false);

  //récupération du titre via l'url
  const pathname = useHistory().location.pathname;
  const history = useHistory();
  const categorieDocId = Navigation.getIdfromPathName(pathname);
  const categorieDocName = Navigation.getNameFromPathName(pathname);
  const GroupDocName = Navigation.getGroupByPathName(pathname);

  //gestion tableau document genere
  const [_data, setData] = useState([]);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [_pageSize, setPageSize] = React.useState(20);
  const [sortBy, setSortBy] = React.useState(null);
  const [sortOrder, setOrder] = React.useState(null);

  const onDataFetch = React.useCallback(
    async ({ pageSize, pageIndex, SortBy, SortOrder }) => {
      if (siteInformation.siteId !== null) {
        setLoading(true);
        setPageNumber(pageIndex);
        setPageSize(pageSize);
        setSortBy(SortBy);
        setOrder(SortOrder);

        Promise.all([
          loadData(authentification, {
            siteId: siteInformation.siteId,
            categorieDocId: categorieDocId,
            pageNumber: pageIndex,
            pageSize: pageSize,
            SortBy,
            SortOrder,
          }),
          DocumentServices.GetCountOfModeleDocument(authentification, {
            siteId: siteInformation.siteId,
            categorieDocId: categorieDocId,
            typeDocId: "DOC_SIMPLE",
          }),
        ])
          .then(async (result) => {
            if (!`${result[0].status}`.startsWith("20")) {
              return Promise.reject("erreur serveur");
            }

            if (!`${result[1].status}`.startsWith("20")) {
              return Promise.reject("erreur serveur");
            }

            let data = [];
            let count = 0;
            if (result[0].status === 200) {
              data = await result[0].json();
              count = await result[1].json();
            }

            setData(data);
            setPageCount(Math.ceil(count / pageSize));
            setLoading(false);
            return Promise.resolve();
          })
          .catch(() => {
            return Promise.reject("erreur serveur");
          });
      }
    },
    [authentification, categorieDocId, siteInformation]
  );

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  //gestion de l'import
  const onImport = async (data) => {
    setLoadingSubmit(true);
    importFile(
      {
        authentification,
        SiteId: siteInformation.siteId,
        categorieDocId: categorieDocId,
        TypeDocId: "DOC_SIMPLE",
        LibelleFr: data.LibelleFr,
        File: data.File,
      },
      {
        onDataFetch: onDataFetch,
        pageSize: _pageSize,
        pageNumber: pageNumber,
        sortBy: sortBy,
        sortOrder: sortOrder,
        setImportModal: setImportModal,
      }
    )
      .then(() => {
        setLoadingSubmit(false);
      })
      .catch((e) => {
        setLoadingSubmit(false);
        alert(e);
      });
  };

  //gestion du telechargement
  const onDownload = () => {
    const listModeleDocumentId = row.map((item) => item.values.id);
    DocumentServices.ExportFiles(authentification, {
      siteId: siteInformation.siteId,
      listModeleDocumentId,
    })
      .then(async (result) => {
        const filename =
          row.length === 1 ? row[0].values.nomFichier : `${categorieDocId}.zip`;
        fileDownload(await result.blob(), filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onUpdate = (data) => {
    DocumentServices.UpdateModeleDocument(authentification, {
      siteId: siteInformation.siteId,
      LibelleFr: data.LibelleFr,
      modeleDocumentId: row[0].values.id,
    })
      .then(async (res) => {
        if (res.status === 200) {
          onDataFetch({
            pageSize: _pageSize,
            pageIndex: pageNumber,
            SortBy: sortBy,
            SortOrder: sortOrder,
          }).then(() => {
            setEditModal(false);
          });
        }
      })
      .catch(() => {
        throw new Error("erreur serveur");
      });
  };

  const onDelete = () => {
    const listModeleDocumentId = row.map((item) => item.values.id);
    DocumentServices.deleteModeleDocument(authentification, {
      siteId: siteInformation.siteId,
      listModeleDocumentId: listModeleDocumentId,
    })
      .then(async (res) => {
        if (res.status === 200) {
          onDataFetch({
            pageSize: _pageSize,
            pageIndex: pageNumber,
            SortBy: sortBy,
            SortOrder: sortOrder,
          }).then(() => {
            setDeleteModal(false);
          });
        }
      })
      .catch((errors) => {
        throw new Error(errors);
      });
  };

  return (
    <>
      <div id="document">
        <ModalEdit
          tag={row.length === 1 ? row[0].values.libelleFr : ""}
          openModal={editModal}
          onClose={() => {
            setEditModal(false);
          }}
          onSubmit={onUpdate}
        />
        <ModalDelete
          rows={row.map((item) => item.values.libelleFr)}
          openModal={deleteModal}
          onClose={() => {
            setDeleteModal(false);
          }}
          onSubmit={onDelete}
        />
        <Modalimport
          openModal={importModal}
          onClose={() => {
            setImportModal(false);
          }}
          loadingModal={loadingSubmit}
          onSubmit={onImport}
        />

        <Container fluid>
          <div className="doc-title py-3">
            <button
              className="btn"
              type="button"
              onClick={() => {
                history.push("/document");
              }}
            >
              <img src={chevron} alt="chevron" />
            </button>{" "}
            <label
              className="text-uppercase"
              style={{ fontSize: "18px", color: "#B4B6B5", verticalAlign: -4 }}
            >{`${GroupDocName} / ${categorieDocName}`}</label>
          </div>

          <div className="tab-content py-1">
            <div className="template-title ml-4 mt-4 d-flex justify-content-between">
              <label>Historique de documents</label>{" "}
              <div className="pr-4">
                {loading && <Spinner style={{ color: "#FFCD00" }} />}
              </div>{" "}
            </div>

            <div className="d-flex justify-content-end py-4 px-3">
              <div className="ml-4 px-2">
                <button
                  className="btn"
                  onClick={() => {
                    onDataFetch({
                      pageSize: _pageSize,
                      pageIndex: pageNumber,
                      SortBy: sortBy,
                      SortOrder: sortOrder,
                    });
                  }}
                >
                  <img src={update} alt="actualiser" />{" "}
                  <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                    Actualiser
                  </span>
                </button>
              </div>
              <div className="ml-4 px-2">
                <button
                  disabled={row.length === 0 || row.length > 1}
                  className="btn"
                  onClick={() => {
                    setEditModal(true);
                  }}
                >
                  <img src={edit} alt="modifier" />{" "}
                  <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                    Modifier
                  </span>
                </button>
              </div>
              <div className="ml-4 px-2">
                <button
                  disabled={row.length === 0}
                  className="btn"
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                >
                  <img src={bin} alt="effacer" />{" "}
                  <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                    Supprimer
                  </span>
                </button>
              </div>
              <div className="ml-4 px-2">
                <button
                  disabled={row.length === 0}
                  className="btn"
                  onClick={() => {
                    onDownload();
                  }}
                >
                  <img src={download} alt="download" />
                  <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                    {" "}
                    Télécharger{" "}
                  </span>
                </button>
              </div>
              <div className="ml-4 px-2">
                <button
                  disabled={row.length > 0}
                  className="btn"
                  onClick={() => {
                    setImportModal(true);
                  }}
                >
                  <img src={share} alt="import" />{" "}
                  <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                    Importer
                  </span>
                </button>
              </div>
            </div>

            <div className="px-4">
              <Table
                data={_data}
                onSelectedRow={(selectedrow) => {
                  setRow(selectedrow);
                }}
                pageNumber={pageCount}
                loading={loading}
                onDataFetch={onDataFetch}
                pageSize={_pageSize}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default connect((state) => state)(Document);
