import {
  ReduxAuthAction,
  ReduxAuthState,
  ReduxAuthType,
} from "../types/authentificationstoretypes";

export const initialState: ReduxAuthState = {
  initializing: false,
  initialized: false,
  idToken: "",
  refreshToken: "",
  token: "",
  authenticated: false,
  userInfo: {
    name: "",
    email: "",
    roles: [],
  },
  state: ReduxAuthType.Initializing,
  tokenExp: 0,
};

const authentification = (
  state = initialState,
  action: ReduxAuthAction = {
    type: ReduxAuthType.Initializing,
    payload: {},
  }
) => {
  switch (action.type) {
    case ReduxAuthType.Initializing:
      return {
        ...state,
        initializing: true,
        initialized: false,
        authenticated: null,
        state: action.type,
      };
    case ReduxAuthType.LoginSuccess:
      return {
        ...state,
        initializing: false,
        initialized: true,
        state: action.type,
        userInfo: action.payload.userInfo,
        idToken: action.payload.idToken,
        refreshToken: action.payload.refreshToken,
        token: action.payload.token,
        authenticated: true,
      };
    case ReduxAuthType.LoginError:
      return {
        ...state,
        initializing: false,
        initialized: true,
        state: action.type,
      };
    case ReduxAuthType.RefreshToken:
      return {
        ...state,
        token: action.payload.token,
        tokenExp: action.payload.tokenExp,
        state: action.type,
      };
    case ReduxAuthType.Logout:
      return {
        ...state,
        authenticated: false,
        state: action.type,
        idToken: "",
        refreshToken: "",
        token: "",
        userInfo: {
          name: "",
          email: "",
          roles: [],
        },
      };
    default:
      return state;
  }
};

export default authentification;
