//Librairies
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { allReducers } from "./stores/index";
import * as serviceWorker from "./serviceWorker";
import { LocalStorage } from "./utils/localStorage";
//Application
import App from "./App";
import "./styles/index.css";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import LoginKeyCloack from "./components/login/LoginKeyCloak";
import Keycloak from "keycloak-js";
import { getAuthProvider } from "./authentication/authProvider";

const tokenCallback = (keycloak) => {
  LocalStorage.token.set(keycloak.token);
  LocalStorage.refreshToken.set(keycloak.refreshToken);
  LocalStorage.userInfo.roles.set(keycloak.tokenParsed.realm_access.roles);
  LocalStorage.userInfo.email.set(keycloak.tokenParsed.email);
  LocalStorage.userInfo.name.set(keycloak.tokenParsed.name);
};

// set local storage logginWith
localStorage.setItem("logginWith", "");

fetch("../../../appsettings.json")
  .then((response) => response.json())
  .then((config) => {
    window.appSettings = config;
    const keycloak = getAuthProvider();
    // eslint-disable-next-line no-undef
    ReactDOM.render(
      <Provider store={allReducers}>
        <ReactKeycloakProvider authClient={keycloak}>
          <App />
        </ReactKeycloakProvider>
      </Provider>,
      document.getElementById("root")
    ); // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
