import React, { FC, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { login } from "../../stores/actions/authentificationActions";
import "./LoginKeyCloak.css";
import { KeyCloakProvider } from "../../interfaces/keycloakProvider";
import { getAuthProvider } from "../../authentication/authProvider";
import LogoReact from "../../assets/Logo_Saur_Industrieblanc.svg";
import { KeycloakLoginOptions } from "keycloak-js";
import { saurYellow } from "../../constants";

type LoginKeyCloakProps = {};

const LoginKeyCloak: FC<LoginKeyCloakProps> = (props: LoginKeyCloakProps) => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({ width: window.innerWidth, height: window.innerHeight });
  const [loginHover, setLoginHover] = useState<boolean>(false);
  const [loginClicked, setLoginClicked] = useState<boolean>(false);
  const [currentProvider, setCurrentProvider] = useState<KeyCloakProvider>(
    JSON.parse(
      localStorage.getItem("provider") ??
        JSON.stringify({
          url: process.env.REACT_APP_URL_KEYCLOAK,
          realm: process.env.REACT_APP_KEYCLOAK_REALM,
          clientId: process.env.REACT_APP_KEYCLOAK_NEXTEP_CLIENT_ID,
        })
    )
  );
  // at start, launching athentification
  // at first launch, authentification is not good, that's why whe authentificated with hence useEffect
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  const dispatch = useDispatch();
  // trigger opening site when sign in is good
  useEffect(() => {
    if (isLoggedIn) {
      localStorage.setItem("logginWith", "keyCloak");
      // get user infos for props of nextep
      keycloak.loadUserInfo().then((userInfo: any) => {
        dispatch(login(keycloak, userInfo));
      });
    }
    const resizeWindow = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, [isLoggedIn]);
  const buttonsContainerStyle: React.CSSProperties = {
    position: "fixed",
    top: windowSize.height / 2 - 200 + "px",
    left: windowSize.width / 2 - 150 + "px",
    width: "300px",
    height: "250px",
    backgroundColor: saurYellow,
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "white",
    fontFamily: "Verdana",
  };
  const loginButtonStyle: React.CSSProperties = {
    width: "150px",
    height: "40px",
    backgroundColor: loginHover ? "#9e8b8b" : "#675d5d",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "500",
    transition: "background-color 0.25s",
  };

  const toogleProvider = () => {
    const newProvider: KeyCloakProvider = {
      url: process.env.REACT_APP_URL_KEYCLOAK ?? "",
      realm: process.env.REACT_APP_KEYCLOAK_REALM ?? "",
      clientId:
        currentProvider.clientId !==
        process.env.REACT_APP_KEYCLOAK_NEXTEP_CLIENT_ID
          ? process.env.REACT_APP_KEYCLOAK_NEXTEP_CLIENT_ID ?? ""
          : process.env.REACT_APP_KEYCLOAK_NEXTEP_CLIENT_ID_INTERNE ?? "",
    };

    localStorage.setItem("provider", JSON.stringify(newProvider));
    setCurrentProvider(newProvider);
    window.location.reload();
  };

  return (
    <div style={buttonsContainerStyle}>
      <img style={{ width: "200px", marginBottom: "8px" }} src={LogoReact} />
      <div
        style={loginButtonStyle}
        className="text-blue-800"
        onClick={() => {
          keycloak.login({
            scope: process.env.REACT_APP_KEYCLOAK_NEXTEP_CLIENT_SCOPE,
          });
          localStorage.setItem("logginWith", "keyCloak");
          setLoginClicked(true);
        }}
        onMouseEnter={() => {
          setLoginHover(true);
        }}
        onMouseLeave={() => {
          setLoginHover(false);
        }}
      >
        {!loginClicked ? (
          <>Login</>
        ) : (
          <div style={{ transform: "scale(40%)" }} className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            marginRight: "8px",
            ...(currentProvider.clientId ===
            process.env.REACT_APP_KEYCLOAK_NEXTEP_CLIENT_ID
              ? { fontWeight: "500" }
              : {}),
          }}
        >
          Externe
        </p>
        <label className="switch">
          <input
            type="checkbox"
            onClick={toogleProvider}
            defaultChecked={
              currentProvider.clientId !==
              process.env.REACT_APP_KEYCLOAK_NEXTEP_CLIENT_ID
            }
          />
          <span className="slider round"></span>
        </label>
        <p
          style={{
            marginLeft: "8px",
            ...(currentProvider.clientId ===
            process.env.REACT_APP_KEYCLOAK_NEXTEP_CLIENT_ID
              ? {}
              : { fontWeight: "500" }),
          }}
        >
          Interne
        </p>
      </div>
    </div>
  );
};

export default connect((state) => state)(LoginKeyCloak);
