import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Select from "react-select";

export default function Addindicator({
  selectionModal,
  loadingModal,
  indicatorOptionsFiltered,
  handleChangeIndicator,
  EtapeTraitementOptions,
  handleChangeEtape,
  onCloseAll,
  refSlect,
  submitIndicator,
  selectionIndicatorValue,
}) {
  const CloseButton = (
    <div>
      <button onClick={onCloseAll} className="btn close-btn"></button>
    </div>
  );

  return (
    <Modal isOpen={selectionModal} size="lg">
      <ModalHeader className="pl-5" close={CloseButton}>
        <label className="firstUpper mt-2 pl-1  h4 d-block">
          Ajouter un indicateur
        </label>
      </ModalHeader>
      <ModalBody className="p-5">
        {loadingModal ? (
          <div className="Spinner d-flex justify-content-center">
            <Spinner style={{ color: "#FFCD00" }} />
          </div>
        ) : (
          <div className="mb-4">
            <Select
              placeholder="Séléctionner une étape"
              className="mb-4"
              options={EtapeTraitementOptions}
              isClearable
              onChange={handleChangeEtape}
            />
            <Select
              placeholder="Ajouter une indicateur"
              ref={refSlect}
              options={indicatorOptionsFiltered}
              isClearable
              onChange={handleChangeIndicator}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <button
          disabled={loadingModal}
          className="btn btn-outline-primary btn-lg mb-3"
          onClick={onCloseAll}
        >
          Annuler
        </button>
        <button
          type="button"
          onClick={submitIndicator}
          disabled={
            (selectionIndicatorValue &&
              selectionIndicatorValue.value == null) ||
            loadingModal
          }
          className="btn btn-primary btn-lg mb-3"
        >
          Valider
        </button>
      </ModalFooter>
    </Modal>
  );
}
