//Librairies
import { AppSwitch } from "@coreui/react";
import moment from "moment";
import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  CardColumns,
  Label,
} from "reactstrap";
//Services
import { GetAccessToken } from "../../authentication/token";
//Css
import "./WaterQuality.css";

class WaterQuality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: null,
      data: null,
      moyen: null,
      moyenCharge: null,
      charges: null,
      ListButton: null,
      DateDebut: this.props.DateDebut,
      DateFin: this.props.DateFin,
      Action: this.props.Action,
      ParametreId: "DCO",
      IndicateurSuiviId: this.props.EtapeTraitementId === "2" ? "NE5" : "NE4",
      UniteCharge: null,
      UniteConcentration: null,
      SiteId: this.props.siteInformation.siteId,
      BarConcentration: {
        labels: null,
        datasets: [
          {
            id: "concent",
            label: "Concentration",
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "#FFCD00",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "#FFCD00",
            pointBackgroundColor: "#FFCD00",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#FFCD00",
            pointHoverBorderColor: "#FFCD00",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: null,
          },
        ],
      },
      BarCharge: {
        labels: null,
        datasets: [
          {
            id: "charge",
            label: "Charge",
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "#FFCD00",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "#FFCD00",
            pointBackgroundColor: "#FFCD00",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#FFCD00",
            pointHoverBorderColor: "#FFCD00",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: null,
          },
        ],
      },
      compareGraph: false,
      chargesComparaison: null,
    };

    this.options = {
      legend: {
        display: false,
      },
    };

    this.OnClickButton = this.OnClickButton.bind(this);
    this.OncheckedMoyen = this.OncheckedMoyen.bind(this);

    this.lineSeuilMaxCharge = {
      type: "line",
      label: "Seuil Max",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "#F24C07",
      borderColor: "#F24C07",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#F24C07",
      pointBackgroundColor: "#F24C07",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#F24C07",
      pointHoverBorderColor: "#F24C07",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: null,
    };

    this.lineSeuilMaxConcentration = {
      label: "Seuil Max",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "#F24C07",
      borderColor: "#F24C07",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#F24C07",
      pointBackgroundColor: "#F24C07",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#F24C07",
      pointHoverBorderColor: "#F24C07",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: null,
    };

    this.BarConcentration = {
      labels: null,
      datasets: [
        {
          id: "concent",
          label: "Concentration",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "#FFCD00",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#FFCD00",
          pointBackgroundColor: "#FFCD00",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#FFCD00",
          pointHoverBorderColor: "#FFCD00",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: null,
        },
      ],
    };

    this.BarCharge = {
      labels: null,
      datasets: [
        {
          id: "charge",
          label: "Charge",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "#FFCD00",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#FFCD00",
          pointBackgroundColor: "#FFCD00",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#FFCD00",
          pointHoverBorderColor: "#FFCD00",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: null,
        },
      ],
    };

    this.onChangeGraph = this.onChangeGraph.bind(this);
  }

  OncheckedMoyen() {
    if (!this.state.DisplayMoyen) {
      this.BarConcentration.datasets[
        this.BarConcentration.datasets.findIndex(
          (item) => item.id === "concent"
        )
      ].data = this.state.moyen;
      this.BarCharge.datasets[
        this.BarCharge.datasets.findIndex((item) => item.id === "charge")
      ].data = this.state.moyenCharge;
      this.setState({
        BarConcentration: this.BarConcentration,
        BarCharge: this.BarCharge,
        DisplayMoyen: true,
      });
    } else {
      this.BarConcentration.datasets[
        this.BarConcentration.datasets.findIndex(
          (item) => item.id === "concent"
        )
      ].data = this.state.data;
      this.BarCharge.datasets[
        this.BarCharge.datasets.findIndex((item) => item.id === "charge")
      ].data = this.state.charges;
      this.setState({
        BarConcentration: this.BarConcentration,
        BarCharge: this.BarCharge,
        DisplayMoyen: false,
      });
    }
  }

  async OnClickButton(parametreId, indicateurSuiviId) {
    await this.setState({
      ParametreId: parametreId,
      IndicateurSuiviId: indicateurSuiviId,
    });
    Promise.all([await this.LoadData(), await this.GetUnitIndicateur()]);
  }

  // // Method to get accessToken
  // async GetAccessToken() {
  //     if (this.props.authentification.accessToken && this.props.authentification.accessToken.expiresOn > new Date()) {
  //         return this.props.authentification.accessToken.accessToken;
  //     }
  //     else {
  //         let token = await getAuthProvider().getAccessToken();
  //         return token.accessToken;
  //     }
  // }

  async GetButtonList() {
    try {
      let result = await fetch(
        `${window.appSettings.apiendpoint}/IndicateurSuivi/${this.state.SiteId}/${this.props.GrandeurEvalueeId}/${this.props.EtapeTraitementId}`,
        {
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${await GetAccessToken(
              this.props.authentification
            )}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) {
        let data = await result.json();
        this.setState({ ListButton: data });
      }
    } catch (ex) {}
  }

  async LoadData() {
    this.onChangeGraph(true);

    try {
      let result = await fetch(
        `${window.appSettings.apiendpoint}/DonneeIndicateur`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${await GetAccessToken(
              this.props.authentification
            )}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            DateDebut: moment(this.state.DateDebut).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            DateFin: moment(this.state.DateFin).format("DD/MM/YYYY HH:mm:ss"),
            IndicateurSuiviId: this.state.IndicateurSuiviId,
            TypeAffichage: this.state.Action,
            ParametreId: this.state.ParametreId,
            EtapeTraitementId: this.props.EtapeTraitementId,
            SiteId: this.state.SiteId,
          }),
        }
      );
      if (result.status === 200) {
        let data = await result.json();
        this.BarConcentration.labels = data.labels;
        this.BarConcentration.datasets[0].data = data.data;
        this.BarCharge.labels = data.labels;
        this.BarCharge.datasets[
          this.BarCharge.datasets.findIndex((item) => item.id === "charge")
        ].data = data.charges;

        //on est en jour donc pas d'incidence lors de la comparaison
        if (data.seuilMaxCharge !== null) {
          if (this.BarCharge.datasets.length === 1) {
            this.lineSeuilMaxCharge.data = data.seuilMaxCharge;
            this.BarCharge.datasets.push(this.lineSeuilMaxCharge);
          } else {
            this.BarCharge.datasets[1].data = data.seuilMaxCharge;
          }
        } else if (this.BarCharge.datasets.length > 1) {
          this.BarCharge.datasets = this.BarCharge.datasets.filter(
            (item) => item.type !== "line"
          );
        }

        if (data.seuilMax !== null) {
          if (this.BarConcentration.datasets.length === 1) {
            this.lineSeuilMaxConcentration.data = data.seuilMax;
            this.BarConcentration.datasets.push(this.lineSeuilMaxConcentration);
          } else {
            this.BarConcentration.datasets[1].data = data.seuilMax;
          }
        } else {
          this.BarConcentration.datasets[
            this.BarConcentration.datasets.findIndex(
              (item) => item.id === "concent"
            )
          ].data = [];
          this.BarConcentration.datasets =
            this.BarConcentration.datasets.filter(
              (item) => item.id === "concent"
            );
        }

        console.log(data);

        this.setState({
          BarConcentration: this.BarConcentration,
          BarCharge: this.BarCharge,
          data: data.data,
          charges: data.charges,
          moyen: data.moyen,
          moyenCharge: data.moyenCharges,
          chargesComparaison: data.chargesComparaison,
        });
      } else if (result.status === 204) {
        this.setState({
          labels: null,
          data: null,
          moyen: null,
          charges: null,
          chargesComparaison: null,
        });
      }
    } catch (ex) {
      console.log("Erreur");
    }
  }

  async GetUnitIndicateur() {
    try {
      let result = await fetch(
        `${window.appSettings.apiendpoint}/DonneeIndicateur/GetUnitesIndicateursSuivis?SiteId=${this.state.SiteId}&IndicateurSuiviId=${this.state.IndicateurSuiviId}&ParametreId=${this.state.ParametreId}&EtapeTraitementId=${this.props.EtapeTraitementId}&TypeAffichage=${this.props.Action}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${await GetAccessToken(
              this.props.authentification
            )}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) {
        let data = await result.json();
        this.setState({
          UniteCharge: data.uniteIndicateurSuiviCharge,
          UniteConcentration: data.uniteIndicateurSuiviConcentration,
        });
      }
    } catch (ex) {}
  }

  async componentDidMount() {
    await Promise.all([
      await this.GetButtonList(),
      await this.LoadData(),
      await this.GetUnitIndicateur(),
    ]);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.DateDebut !== prevState.DateDebut &&
      nextProps.DateFin !== prevState.DateFin
    ) {
      return {
        DateDebut: nextProps.DateDebut,
        DateFin: nextProps.DateFin,
        Action: nextProps.Action,
      };
    } else return null;
  }

  // Method de refresh data after changing prpos
  async componentDidUpdate(prevProps) {
    if (
      prevProps.DateDebut !== this.state.DateDebut &&
      prevProps.DateFin !== this.state.DateFin
    ) {
      this.setState({
        DateDebut: prevProps.DateDebut,
        DateFin: prevProps.DateFin,
        Action: prevProps.Action,
      });
      await this.LoadData();
    }
    if (prevProps.Action !== this.state.Action) {
      await this.GetUnitIndicateur();
    }
    if (this.state.SiteId !== this.props.siteInformation.siteId) {
      this.setState({
        SiteId: this.props.siteInformation.siteId,
        ParametreId: "DCO",
        IndicateurSuiviId: this.props.EtapeTraitementId === "2" ? "NE5" : "NE4",
      });
      await Promise.all([await this.LoadData(), await this.GetButtonList()]);
    }
  }

  onChangeGraph(reset) {
    if (reset === true) {
      this.BarCharge.datasets = this.BarCharge.datasets.filter(
        (item) => item.id !== "chargeN-1"
      );
      this.setState({ compareGraph: false, BarCharge: this.BarCharge });
      return;
    }

    if (!this.state.compareGraph) {
      this.BarCharge.datasets.push({
        id: "chargeN-1",
        label: "Charge N-1",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "#0a3c5c",
        borderColor: "#0a3c5c",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#0a3c5c",
        pointBackgroundColor: "#0a3c5c",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#0a3c5c",
        pointHoverBorderColor: "#0a3c5c",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: this.state.chargesComparaison,
      });
    } else {
      this.BarCharge.datasets = this.BarCharge.datasets.filter(
        (item) => item.id !== "chargeN-1"
      );
    }

    this.setState({
      compareGraph: !this.state.compareGraph,
      BarCharge: this.BarCharge,
    });
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <div>
          <div style={{ display: "initial" }}>
            <Label className="Label-Widget">{this.props.Titre}</Label>
          </div>
        </div>
        <ButtonToolbar style={{ marginBottom: "10px", justifyContent: "left" }}>
          {this.state.ListButton &&
            this.state.ListButton.map((button) => (
              <Button
                key={button.parametreId}
                onClick={() =>
                  this.OnClickButton(button.parametreId, button.id)
                }
                className={
                  this.state.ParametreId === button.parametreId
                    ? "btn-pill btn-selected"
                    : "btn-pill"
                }
              >
                {button.parametreId}
              </Button>
            ))}
          {this.props.Action === "Mois" && (
            <div
              style={{
                position: "absolute",
                left: "90%",
                marginLeft: "-15px",
                display: "inline-grid",
              }}
            >
              <AppSwitch
                className={"mx-1"}
                variant="3d"
                color="secondary"
                checked={this.state.compareGraph}
                onChange={this.onChangeGraph}
                label
                dataOn={"\u2713"}
                dataOff={"\u2715"}
              />
            </div>
          )}
        </ButtonToolbar>
        <CardColumns className="cols-2">
          <Card>
            <CardBody>
              <div>
                <Label
                  className="Label-Granularite"
                  style={{
                    display: "grid",
                    justifyContent: "right",
                    color: "black",
                  }}
                >
                  {this.state.Granularite}
                </Label>
              </div>
              <Line data={this.state.BarConcentration} options={this.options} />
              <div>
                <Label
                  className="Label-Granularite"
                  style={{
                    display: "grid",
                    fontSize: "16px",
                    justifyContent: "center",
                    color: "#0a3c5c",
                  }}
                >
                  Concentration ({this.state.UniteConcentration})
                </Label>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div>
                <Label
                  className="Label-Granularite"
                  style={{
                    display: "grid",
                    justifyContent: "right",
                    color: "black",
                  }}
                >
                  {this.state.GranulariteCharge}
                </Label>
              </div>
              <Line data={this.state.BarCharge} options={this.options} />
              <div>
                <Label
                  className="Label-Granularite"
                  style={{
                    display: "grid",
                    fontSize: "16px",
                    justifyContent: "center",
                    color: "#0a3c5c",
                  }}
                >
                  Charge ({this.state.UniteCharge})
                </Label>
              </div>
            </CardBody>
          </Card>
        </CardColumns>
      </div>
    );
  }
}

export default connect((state) => state)(WaterQuality);
