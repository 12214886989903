import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import Category from "./Categorie";
import { useHistory } from "react-router-dom";

//Button pour aller directement à l'historique
function GoToHistory() {
  const history = useHistory();
  return (
    <button
      className="ml-auto btn btn-link primary"
      onClick={() => {
        history.push("/history");
      }}
    >
      {" "}
      <u>Voir l'historique</u>
    </button>
  );
}

class CollectInformations extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: new Array(4).fill("1"),
    };
  }

  //active l'onglet
  toggle(tabPane, tab) {
    const newArray = this.state.activeTab.slice();
    newArray[tabPane] = tab;
    this.setState({
      activeTab: newArray,
    });
  }

  tabPane() {
    return (
      <>
        <TabPane tabId="1">
          {this.state.activeTab[0] === "1" && (
            <Category domain="D_WASTEWATER" name="qualite de l'effluent" />
          )}
        </TabPane>
        <TabPane tabId="2">
          {this.state.activeTab[0] === "2" && (
            <Category domain="D_WASTE" name="dechets" />
          )}
        </TabPane>
        <TabPane tabId="3">
          {this.state.activeTab[0] === "3" && (
            <Category domain="D_REAGENTS" name="reactifs" />
          )}
        </TabPane>
        <TabPane tabId="4">
          {this.state.activeTab[0] === "4" && (
            <Category domain="D_METERS" name="compteurs" />
          )}
        </TabPane>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              marginTop: "15px",
              marginBottom: "15px",
              borderBottom: "1px solid #B4B6B5",
            }}
          >
            <label style={{ fontSize: "18px", color: "#B4B6B5" }}>
              INDICATEURS STEP
            </label>
          </div>
          <div className="py-4 info-label">
            {/*  div reservé à de future infos  */}
          </div>
          <div id="collectInfo">
            <Nav tabs className="align-items-center">
              <NavItem>
                <NavLink
                  active={this.state.activeTab[0] === "1"}
                  onClick={() => {
                    this.toggle(0, "1");
                  }}
                  href="#"
                  className="px-0"
                >
                  <span className="px-4"> Qualité de l'effluent</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.activeTab[0] === "2"}
                  onClick={() => {
                    this.toggle(0, "2");
                  }}
                  href="#"
                  className="px-0"
                >
                  <span className="px-4">Déchets</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.activeTab[0] === "3"}
                  onClick={() => {
                    this.toggle(0, "3");
                  }}
                  href="#"
                  className="px-0"
                >
                  <span className="px-4"> Réactifs</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  active={this.state.activeTab[0] === "4"}
                  onClick={() => {
                    this.toggle(0, "4");
                  }}
                  href="#"
                  className="px-0"
                >
                  <span className="px-4"> Compteurs </span>
                </NavLink>
              </NavItem>
              <GoToHistory />
            </Nav>
            <TabContent activeTab={this.state.activeTab[0]}>
              {this.tabPane()}
            </TabContent>
          </div>
        </Container>
      </>
    );
  }
}

export default CollectInformations;
