import React, { useState, useEffect, useRef } from "react";
import Table from "./TableStep";
import { Spinner } from "reactstrap";
import { eachDayOfInterval, format, sub, add, isBefore } from "date-fns";
import { fr } from "date-fns/locale";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import AddIndicator from "./Categorie/AddIndicator";
import DeleteIndicator from "./Categorie/DeleteIndicator";
import DeleteValue from "./Categorie/DeleteValue";
import EditValue from "./Categorie/Editvalue";
import AddValue from "./Categorie/AddValue";
import InfoCell from "./Categorie/infoCell";
import * as IndicatorServices from "../../services/IndicatorServices";
import WeekPicker from "./Categorie/WeekPicker";
import moment, { Moment } from "moment";

export function formatIndicatorToSelect(datas) {
  return datas.map((data) => ({
    value: data.id,
    label: data.libelleFr,
    etapeTraitementId: data.etapeTraitement.id,
  }));
}

export function formatEtapeTraitementToSelect(datas) {
  return Array.from(
    new Set(
      datas
        .map((data) => ({
          value: data.etapeTraitement.id,
          label: data.etapeTraitement.libelleFr,
        }))
        .map(JSON.stringify)
    )
  ).map(JSON.parse);
}

export function getIndicatorData(data = []) {
  const formatData = data.map((dt) => {
    return {
      stepId: dt.stepId,
      nameStep: dt.nameStep,
      indicators: dt.indicateurs.map((info) => {
        let infoobj = {};

        info.valeursIndicateurs.forEach((vi, idx) => {
          infoobj[`id${idx}`] = vi;
        });

        infoobj.indc = info.informationsIndicateur;

        return infoobj;
      }),
    };
  });

  return formatData;
}

function Category({ authentification, siteInformation, domain, name }) {
  const [currentDate, setCurrentDate] = useState(moment());
  const [startFormated, setStartFormated] = useState(
    format(moment(moment().startOf("isoWeek")).toDate(), "dd/MM/yyyy")
  );
  const [endFormated, setEndFormated] = useState(
    format(moment(moment().endOf("isoWeek")).toDate(), "dd/MM/yyyy")
  );
  const [isAfterCurrentDay, setIsAfterCurrentDay] = useState(false);
  const [week, setWeek] = useState([]);

  useEffect(() => {
    const weekStart = currentDate.clone().startOf("isoWeek");
    const weekEnd = currentDate.clone().endOf("isoWeek");
    setStartFormated(format(moment(weekStart).toDate(), "dd/MM/yyyy"));
    setEndFormated(format(moment(weekEnd).toDate(), "dd/MM/yyyy"));
    setWeek(
      eachDayOfInterval(
        {
          start: moment(weekStart).toDate(),
          end: moment(weekEnd).toDate(),
        },
        {}
      )
    );
    setIsAfterCurrentDay(isBefore(weekEnd.add(1, "days").toDate(), new Date()));
  }, [currentDate]);
  //Calcul de la période en cours

  const onPrevious = () => {
    setCurrentDate(currentDate.clone().subtract(7, "d"));
  };

  const onNext = () => {
    setCurrentDate(currentDate.clone().add(7, "d"));
  };

  //Chargement des données
  useEffect(() => {
    setLoading(true);
    setLoadDataMessage("");

    if (siteInformation.siteId) {
      IndicatorServices.loadData(authentification, {
        siteId: siteInformation.siteId,
        domaineIdn: domain,
        dateDebut: startFormated,
        dateFin: endFormated,
      })
        .then((data) => {
          setIndicators(getIndicatorData(data));
          setLoading(false);
        })
        .catch((resp) => {
          setLoadDataMessage("erreur serveur");
          setLoading(false);
        });
    }
  }, [
    siteInformation.siteId,
    authentification,
    domain,
    startFormated,
    endFormated,
  ]);

  //gestion du chargement
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  //Gestion des tableaux d'indicateurs
  const [indicators, setIndicators] = useState([]);
  const [loadDataMessage, setLoadDataMessage] = useState("");

  //gestion de la modale de selection des indicators
  const [selectionModal, setSelectionModal] = useState(false);
  const [indicatorOptions, setIndicatorOptions] = useState([]);
  const [indicatorOptionsFiltered, setindicatorOptionsFiltered] = useState([]);
  const [EtapeTraitementOptions, setEtapeTraitementOptions] = useState(null);
  const [selectionIndicatorValue, setselectionIndicatorValue] = useState(null);
  const [deleteselectionModal, setDeleteSelectionModal] = useState(false);

  const refSlect = useRef(null);

  const onSubmitIndicator = () => {
    setLoadingModal(true);
    setSelectionModal(!selectionModal);
    setErrorMessage("");

    //Paralléliser l'appel
    IndicatorServices.loadDataIndicateurSuivi(authentification, {
      siteId: siteInformation.siteId,
      domaineId: domain,
    })
      .then(async (res) => {
        if (res.status === 200 || res.status === 204) {
          const datas = await res.json();
          setIndicatorOptions(formatIndicatorToSelect(datas));
          setindicatorOptionsFiltered(formatIndicatorToSelect(datas));
          setEtapeTraitementOptions(formatEtapeTraitementToSelect(datas));
        }
      })
      .finally(() => {
        setLoadingModal(false);
      })
      .catch(() => {
        setErrorMessage("Erreur Serveur");
        setLoadingModal(false);
      });
  };

  const handleChangeIndicator = (data) => {
    setselectionIndicatorValue(data);
  };

  const handleChangeEtape = (data) => {
    if (data === null) {
      setindicatorOptionsFiltered(indicatorOptions);
    } else {
      setindicatorOptionsFiltered(
        indicatorOptions.filter((x) => x.etapeTraitementId === data.value)
      );
      if (
        selectionIndicatorValue &&
        selectionIndicatorValue.etapeTraitementId !== data.value
      ) {
        refSlect.current.select.clearValue();
      }
    }
  };

  const submitIndicator = () => {
    setLoadingModal(true);
    setErrorMessage("");

    IndicatorServices.postIndicateurSuivi(authentification, {
      siteId: siteInformation.siteId,
      indicateurId: selectionIndicatorValue.value,
      afficher: true,
    })
      .then(() => {
        IndicatorServices.loadData(authentification, {
          siteId: siteInformation.siteId,
          domaineIdn: domain,
          dateDebut: startFormated,
          dateFin: endFormated,
        }).then((data) => {
          setIndicators(getIndicatorData(data));
        });
      })
      .finally(() => {
        setLoadingModal(false);
        onCloseAll();
      })
      .catch(() => {
        setErrorMessage("Erreur Serveur");
        setLoadingModal(false);
      });
  };

  const toggleDeleteSelection = () => {
    setDeleteSelectionModal(!deleteselectionModal);
  };

  const submitDeleteIndicator = () => {
    setLoadingModal(true);
    setErrorMessage("");
    IndicatorServices.postIndicateurSuivi(authentification, {
      siteId: siteInformation.siteId,
      indicateurId: _cell.rowInfo.indicateurId,
      afficher: false,
    })
      .then(() => {
        IndicatorServices.loadData(authentification, {
          siteId: siteInformation.siteId,
          domaineIdn: domain,
          dateDebut: startFormated,
          dateFin: endFormated,
        }).then((data) => {
          setIndicators(getIndicatorData(data));
        });
      })
      .finally(() => {
        setLoadingModal(false);
        toggleDeleteSelection();
      })
      .catch(() => {
        setErrorMessage("Erreur Serveur");
        setLoadingModal(false);
      });
  };

  //Gestion des cellules
  const [_cell, setCell] = useState(null);
  const [cellModal, setCellModal] = useState(false);
  const toggleCellModal = () => setCellModal(!cellModal);

  const openCellModal = ({ cellInfo, rowInfo, date, isIndc }) => {
    setCell({ cellInfo, rowInfo, date });

    if (isIndc) {
      toggleDeleteSelection();
      return;
    }

    if (
      cellInfo.valeur === null &&
      cellInfo.valeurLabo === null &&
      cellInfo.valeurSiccite === null
    ) {
      toggle_NewValue();
    } else {
      toggleCellModal();
    }
  };

  //gestion modale de création
  const [newModal, setNewModal] = useState(false);
  const toggle_NewValue = () => {
    setNewModal(!newModal);
  };

  //gestion de modale de suppression
  const [binModal, setBinModal] = useState(false);
  const toggleBin = () => {
    setBinModal(!binModal);
  };

  const onValidBin = (data, cellu) => {
    setLoadingModal(true);
    setErrorMessage("");

    IndicatorServices.deleteData(authentification, {
      valeur: data.valeur,
      valeurSiccite: data.valeurSiccite,
      valeurLabo: cellu.valeurLabo,
      date: cellu.date,
      indicateurId: cellu && cellu.rowInfo && cellu.rowInfo.indicateurId,
      indicateurIdSiccite:
        cellu && cellu.rowInfo && cellu.rowInfo.indicateurIdSiccite,
      siteId: siteInformation.siteId,
    })
      .then((response) => {
        if (response.status === 200) {
          IndicatorServices.loadData(authentification, {
            siteId: siteInformation.siteId,
            domaineIdn: domain,
            dateDebut: startFormated,
            dateFin: endFormated,
          }).then((dataind) => {
            setIndicators(getIndicatorData(dataind));
          });
        }

        if (response.status === 500) {
          setErrorMessage("erreur serveur");
        }
      })
      .finally(() => {
        setLoadingModal(false);
        onCloseAll();
      })
      .catch(() => {
        setLoadingModal(false);
        setErrorMessage("Erreur Serveur");
      });
  };

  //gestion de modale d'édition
  const [editModal, setEditModal] = useState(false);

  const toggleEdit = () => {
    setEditModal(!editModal);
  };

  const toggleValidEdit = (data, cell) => {
    setLoadingModal(true);
    setErrorMessage("");

    IndicatorServices.editData(authentification, {
      valeur: data.valeur,
      valeurSiccite: data.valeurSiccite,
      valeurLabo: data.valeurLabo,

      date: cell.date,
      indicateurId: cell && cell.rowInfo && cell.rowInfo.indicateurId,
      indicateurIdSiccite:
        cell && cell.rowInfo && cell.rowInfo.indicateurIdSiccite,
      siteId: siteInformation.siteId,
    })
      .then((res) => {
        if (res.status === 200) {
          IndicatorServices.loadData(authentification, {
            siteId: siteInformation.siteId,
            domaineIdn: domain,
            dateDebut: startFormated,
            dateFin: endFormated,
          }).then((dataind) => {
            setIndicators(getIndicatorData(dataind));
          });
        }

        if (res.status === 500) {
          setErrorMessage("erreur serveur");
        }
      })
      .finally(() => {
        setLoadingModal(false);
        onCloseAll();
      })
      .catch(() => {
        setLoadingModal(false);
        setErrorMessage("Erreur Serveur");
      });
  };

  //gestion de toutes les modales

  const onCloseAll = () => {
    setNewModal(false);
    setCellModal(false);
    setEditModal(false);
    setBinModal(false);
    setSelectionModal(false);
    setDeleteSelectionModal(false);
  };

  const { register, handleSubmit, errors, getValues, triggerValidation } =
    useForm({
      mode: "onChange",
    });

  if (loadDataMessage !== "")
    return <div className="d-flex justify-content-center">Erreur serveur</div>;

  return loading ? (
    <div
      className="Spinner d-flex justify-content-center align-items-center"
      style={{ minHeight: 400 }}
    >
      <Spinner style={{ color: "#FFCD00" }} />
    </div>
  ) : (
    <div>
      <AddValue
        newModal={newModal}
        toggleValidEdit={toggleValidEdit}
        handleSubmit={handleSubmit}
        register={register}
        loadingModal={loadingModal}
        cell={_cell}
        errors={errors}
        errorMessage={errorMessage}
        onCloseAll={onCloseAll}
        getValues={getValues}
        triggerValidation={triggerValidation}
      />
      <EditValue
        editModal={editModal}
        toggleValidEdit={toggleValidEdit}
        cell={_cell}
        loadingModal={loadingModal}
        errorMessage={errorMessage}
        register={register}
        errors={errors}
        onCloseAll={onCloseAll}
        handleSubmit={handleSubmit}
        getValues={getValues}
        triggerValidation={triggerValidation}
      />
      <InfoCell
        cellModal={cellModal}
        cell={_cell}
        toggleCellModal={toggleCellModal}
        onCloseAll={onCloseAll}
        toggleBin={toggleBin}
        toggleEdit={toggleEdit}
      />
      <AddIndicator
        EtapeTraitementOptions={EtapeTraitementOptions}
        handleChangeEtape={handleChangeEtape}
        handleChangeIndicator={handleChangeIndicator}
        indicatorOptionsFiltered={indicatorOptionsFiltered}
        loadingModal={loadingModal}
        selectionModal={selectionModal}
        onCloseAll={onCloseAll}
        refSlect={refSlect}
        submitIndicator={submitIndicator}
        selectionIndicatorValue={selectionIndicatorValue}
      />
      <DeleteIndicator
        deleteselectionModal={deleteselectionModal}
        cell={_cell}
        submitDeleteIndicator={submitDeleteIndicator}
        loadingModal={loadingModal}
        errorMessage={errorMessage}
        onCloseAll={onCloseAll}
        handleSubmit={handleSubmit}
      />
      <DeleteValue
        cell={_cell}
        onCloseAll={onCloseAll}
        binModal={binModal}
        handleSubmit={handleSubmit}
        onValidBin={onValidBin}
        loadingModal={loadingModal}
      />

      <div className="d-flex align-items-center">
        <button
          aria-label="Previous btn"
          className="page-link mr-1 rounded"
          onClick={onPrevious}
        >
          {"<<"}
        </button>
        <div className="period-label">
          <div style={{ paddingRight: "3px" }}>{`Période du `}</div>
          <WeekPicker
            currentDate={currentDate}
            onDateChange={(date) => {
              setCurrentDate(date);
            }}
          />
          <div style={{ paddingLeft: "3px" }}>{` au ${format(
            currentDate.clone().add(6, "d").toDate(),
            "dd/MM/yyyy",
            {
              locale: fr,
            }
          )}`}</div>
        </div>
        {isAfterCurrentDay && (
          <button
            aria-label="Previous btn"
            className="page-link ml-1 rounded"
            onClick={onNext}
          >
            {">>"}
          </button>
        )}
      </div>
      <div className="w-100 d-flex justify-content-end">
        {" "}
        <button
          onClick={onSubmitIndicator}
          className="btn btn btn-outline-primary"
        >
          Ajouter un indicateur
        </button>
      </div>
      {indicators.length === 0 && (
        <div
          className="alert alert-primary d-flex justify-content-center mt-5 m-5"
          role="alert"
        >
          Veuillez ajouter des indicateurs
        </div>
      )}

      {indicators.map((tableau, index) => (
        <div key={`${tableau.stepId}-${index}`}>
          <Table
            week={week}
            nameStep={tableau.nameStep}
            data={tableau.indicators}
            onClickCell={({ cellInfo, rowInfo, date, isIndc }) => {
              openCellModal({
                cellInfo,
                idtableau: tableau.stepId,
                rowInfo,
                date,
                isIndc,
              });
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default connect((state) => state)(Category);
