import React, { Component, useEffect, useState } from "react";
import moment, { Moment } from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { CalendarDay, CalendarDayShape, SingleDatePicker } from "react-dates";
import classNames from "classnames";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

moment.locale("fr");

interface WeekPickerProps {
  currentDate: Moment;
  onDateChange: (date: Moment | null) => void;
}

export const WeekPicker = (props: WeekPickerProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [selectedWorkWeek, setSelectedWorkWeek] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [hoveredDays, setHoveredDays] = useState<Array<Moment>>([]);
  const [workWeekMarginLeft, setWorkWeekMarginLeft] = useState<number>(0);

  moment.locale("fr");

  useEffect(() => {
    setSelectedWorkWeek(props.currentDate.isoWeek());
    setSelectedYear(props.currentDate.year());
    setHoveredDays(calculateActiveWeek(props.currentDate));
  }, [props.currentDate]);

  const openPicker = () => {
    if (!focused) {
      setFocused(true);
    }
  };

  const isDayHighlighted = (date: Moment) => {
    let isHighlighted = false;
    hoveredDays.forEach((hoveredDay) => {
      const isDayOfMonthMatch = hoveredDay.date() === date.date();
      const isMonthMatch = hoveredDay.month() === date.month();
      const isYearMatch = hoveredDay.year() === date.year();
      if (isDayOfMonthMatch && isMonthMatch && isYearMatch) {
        isHighlighted = true;
      }
    });
    return isHighlighted;
  };

  const calculateActiveWeek = (date: Moment) => {
    const mon = date.clone().startOf("isoWeek");
    const tue = mon.clone().add(1, "d");
    const wed = mon.clone().add(2, "d");
    const thu = mon.clone().add(3, "d");
    const fri = mon.clone().add(4, "d");
    const sat = mon.clone().add(5, "d");
    const sun = mon.clone().add(6, "d");
    return [sun, mon, tue, wed, thu, fri, sat];
  };

  const onDateHovered = (date: Moment) => {
    setHoveredDays(calculateActiveWeek(date));
  };

  const onDateChange = (date: Moment) => {
    const newSelectedYear = date.year();
    const newSelectedWorkWeek = date.isoWeek();
    setSelectedWorkWeek(newSelectedWorkWeek);
    setSelectedYear(newSelectedYear);
    setFocused(false);
    props.onDateChange(date);
  };

  const renderCalendarDay = (date: CalendarDayShape): JSX.Element => {
    const dayClasses = classNames(
      "CalendarDay",
      "CalendarDay__default",
      "CalendarDay_1",
      "CalendarDay__default_2"
    );
    let style = {
      width: "39px",
      height: "38px",
    };
    if (date.day) {
      const dayOfMonth = date.day.date();
      const isHighlighted = isDayHighlighted(date.day);
      let style = {
        width: "39px",
        height: "38px",
        backgroundColor: isHighlighted ? "#42a5f5" : "white",
        color: isHighlighted ? "white" : "black",
      };
      return (
        <td
          key={dayOfMonth}
          style={style}
          className={dayClasses}
          onClick={() => onDateChange(date.day!)}
          onMouseEnter={() => onDateHovered(date.day!)}
        >
          {dayOfMonth}
        </td>
      );
    } else {
      return <td key={Math.random()} style={style} className={dayClasses} />;
    }
  };

  const previousWeek = () => {
    let newSelectedWorkWeek = selectedWorkWeek - 1;
    let newSelectedYear = selectedYear;
    if (newSelectedWorkWeek === 0) {
      newSelectedWorkWeek = 52;
      newSelectedYear = selectedYear - 1;
    }
    const date = moment().year(newSelectedYear).isoWeek(newSelectedWorkWeek);

    setSelectedWorkWeek(newSelectedWorkWeek);
    setSelectedYear(newSelectedYear);
    setHoveredDays(calculateActiveWeek(date));
  };

  const nextWeek = () => {
    let newSelectedWorkWeek = selectedWorkWeek + 1;
    let newSelectedYear = selectedYear;
    if (newSelectedWorkWeek === 53) {
      newSelectedWorkWeek = 1;
      newSelectedYear = selectedYear + 1;
    }
    const date = moment().year(newSelectedYear).isoWeek(newSelectedWorkWeek);
    setSelectedWorkWeek(newSelectedWorkWeek);
    setSelectedYear(newSelectedYear);
    setHoveredDays(calculateActiveWeek(date));
  };
  const date = moment()
    .year(selectedYear)
    .isoWeek(selectedWorkWeek)
    .startOf("isoWeek");
  return (
    <SingleDatePicker
      focused={focused} // PropTypes.bool
      date={date}
      onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
      id="single_date_picker" // PropTypes.string.isRequired,
      numberOfMonths={1}
      hideKeyboardShortcutsPanel={true}
      isDayBlocked={() => false}
      isOutsideRange={() => false}
      renderCalendarDay={renderCalendarDay}
      onDateChange={() => {}}
      displayFormat={`${format(date.toDate(), "dd/MM/yyyy", {
        locale: fr,
      })}`}
    />
  );
};

export default WeekPicker;

/*
    
*/
