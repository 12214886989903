//Librairies
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Label } from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
import { AppSwitch } from "@coreui/react";
//Services
import { GetAccessToken } from "../../authentication/token";

class VolumeWater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: null,
      data: null,
      dataComparaison: [],
      moyen: null,
      DateDebut: this.props.DateDebut,
      DateFin: this.props.DateFin,
      Action: this.props.Action,
      Unite: null,
      SiteId: this.props.siteInformation.siteId,
      DisplayMoyen: false,
      BarVolume: {
        labels: null,
        datasets: [
          {
            label: "Volume d'eau",
            backgroundColor: "#FFCD00",
            borderColor: "#FFCD00",
            borderWidth: 1,
            hoverBackgroundColor: "#FFCD00",
            hoverBorderColor: "#FFCD00",
            data: null,
          },
        ],
      },
      compareGraph: false,
    };

    this.BarVolume = {
      labels: null,
      datasets: [
        {
          id: "n",
          label: "Volume d'eau",
          backgroundColor: "#FFCD00",
          borderColor: "#FFCD00",
          borderWidth: 1,
          hoverBackgroundColor: "#FFCD00",
          hoverBorderColor: "#FFCD00",
          data: null,
        },
      ],
    };

    this.LineSeuil = {
      fill: false,
      type: "line",
      lineTension: 0.1,
      backgroundColor: "#F24C07",
      borderColor: "#F24C07",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#F24C07",
      pointBackgroundColor: "#F24C07",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#F24C07",
      pointHoverBorderColor: "#F24C07",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: null,
    };

    this.options = {
      legend: {
        display: false,
      },
    };
    this.OncheckedMoyen = this.OncheckedMoyen.bind(this);
    this.onChangeGraph = this.onChangeGraph.bind(this);
  }

  OncheckedMoyen() {
    if (!this.state.DisplayMoyen) {
      this.BarVolume.datasets[
        this.BarVolume.datasets.findIndex((item) => item.id === "n")
      ].data = this.state.moyen;
      this.setState({ BarVolume: this.BarVolume, DisplayMoyen: true });
    } else {
      this.BarVolume.datasets[
        this.BarVolume.datasets.findIndex((item) => item.id === "n")
      ].data = this.state.data;
      this.setState({ BarVolume: this.BarVolume, DisplayMoyen: false });
    }
  }

  datasetKeyProvider() {
    return Math.random();
  }

  //Method to load data from back-end
  async LoadData() {
    this.onChangeGraph(true);

    try {
      let result = await fetch(
        `${window.appSettings.apiendpoint}/DonneeIndicateur`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await GetAccessToken(
              this.props.authentification
            )}`,
            Accept: "application/json",

            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            DateDebut: moment(this.state.DateDebut).format(
              "DD/MM/YYYY HH:mm:ss"
            ),

            IndicateurSuiviId: this.props.IndicateurSuiviId,

            ParametreId: null,
            SiteId: this.state.SiteId,
            TypeAffichage: this.state.Action,
            DateFin: moment(this.state.DateFin).format("DD/MM/YYYY HH:mm:ss"),
          }),
        }
      );
      if (result.status === 200) {
        let data = await result.json();
        this.BarVolume.labels = data.labels;
        this.BarVolume.datasets[
          this.BarVolume.datasets.findIndex((item) => item.id === "n")
        ].data = data.data;

        //on est en jour donc pas d'icidence lors de la comparaison
        if (this.state.Action === "Jour") {
          if (data.seuilMax !== null) {
            if (this.BarVolume.datasets.length === 1) {
              this.LineSeuil.data = data.seuilMax;
              this.BarVolume.datasets.push(this.LineSeuil);
            } else {
              this.BarVolume.datasets[1].data = data.seuilMax;
            }
          }
        } else {
          this.BarVolume.datasets = this.BarVolume.datasets.filter(
            (item) => item.type !== "line"
          );
        }

        this.setState({
          BarVolume: this.BarVolume,
          data: data.data,
          moyen: data.moyen,
          dataComparaison: data.dataComparaison,
        });
      }
    } catch (ex) {}
  }

  async GetUnitIndicateur() {
    try {
      let result = await fetch(
        `${window.appSettings.apiendpoint}/DonneeIndicateur/GetUnitesIndicateursSuivis?SiteId=${this.state.SiteId}&IndicateurSuiviId=${this.props.IndicateurSuiviId}&TypeAffichage=${this.props.Action}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",

            Authorization: `Bearer ${await GetAccessToken(
              this.props.authentification
            )}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) {
        let data = await result.json();
        this.setState({ Unite: data.uniteIndicateurSuivi });
      }
    } catch (ex) {}
  }

  //Method executed After rendering compenont
  async componentDidMount() {
    await Promise.all([await this.LoadData(), await this.GetUnitIndicateur()]);
  }

  // Method to set state when props change
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.DateDebut !== prevState.DateDebut &&
      nextProps.DateFin !== prevState.DateFin
    ) {
      return {
        DateDebut: nextProps.DateDebut,
        DateFin: nextProps.DateFin,
        Action: nextProps.Action,
      };
    } else return null;
  }

  // Method de refresh data after changing prpos
  async componentDidUpdate(prevuProp) {
    if (
      prevuProp.DateDebut !== this.state.DateDebut &&
      prevuProp.DateFin !== this.state.DateFin
    ) {
      this.setState({
        DateDebut: prevuProp.DateDebut,
        DateFin: prevuProp.DateFin,
        Action: prevuProp.Action,
      });
      await this.LoadData();
    }

    if (prevuProp.Action !== this.state.Action) {
      await this.GetUnitIndicateur();
    }

    if (this.state.SiteId !== this.props.siteInformation.siteId) {
      this.setState({ SiteId: this.props.siteInformation.siteId });
      await this.LoadData();
    }
  }

  onChangeGraph(reset) {
    if (reset === true) {
      this.BarVolume.datasets = this.BarVolume.datasets.filter(
        (item) => item.id !== "N-1"
      );
      this.setState({ compareGraph: false, BarVolume: this.BarVolume });
      return;
    }

    if (!this.state.compareGraph) {
      this.BarVolume.datasets.push({
        id: "N-1",
        label: "Volume d'eau n-1",
        backgroundColor: "#0a3c5c",
        borderColor: "#0a3c5c",
        borderWidth: 1,
        hoverBackgroundColor: "#0a3c5c",
        hoverBorderColor: "#0a3c5c",
        data: this.state.dataComparaison,
      });
    } else {
      this.BarVolume.datasets = this.BarVolume.datasets.filter(
        (item) => item.id !== "N-1"
      );
    }

    this.setState({
      compareGraph: !this.state.compareGraph,
      BarVolume: this.BarVolume,
    });
  }

  render() {
    return this.props.mini ? (
      <div style={{ backgroundColor: "white" }}>
        <Label className="widget-title">
          {this.props.Titre} ({this.state.Unite})
        </Label>

        <div className="mt-5 mt-lg-0">
          <Bar
            datasetKeyProvider={this.datasetKeyProvider}
            data={this.state.BarVolume}
            options={{
              legend: {
                display: false,
              },
              maintainAspectRatio: true,
              responsive: true,
            }}
            height={100}
            // width={300}
          />
        </div>
      </div>
    ) : (
      <div style={{ backgroundColor: "white" }}>
        <div>
          <div style={{ display: "initial" }}>
            <Label className="Label-Widget">
              {this.props.Titre} ({this.state.Unite})
            </Label>
            {this.props.Action === "Mois" && (
              <div
                style={{
                  position: "absolute",
                  left: "90%",
                  marginLeft: "-15px",
                  display: "inline-grid",
                }}
              >
                <AppSwitch
                  className={"mx-1"}
                  variant="3d"
                  color="secondary"
                  checked={this.state.compareGraph}
                  onChange={this.onChangeGraph}
                  label
                  dataOn={"\u2713"}
                  dataOff={"\u2715"}
                />
              </div>
            )}
          </div>
        </div>
        <div>
          <Label
            className="Label-Granularite"
            style={{ display: "grid", justifyContent: "right", color: "black" }}
          >
            {this.state.Granularite}
          </Label>
        </div>
        <Bar
          datasetKeyProvider={this.datasetKeyProvider}
          data={this.state.BarVolume}
          options={this.options}
        />
      </div>
    );
  }
}

export default connect((state) => state)(VolumeWater);
