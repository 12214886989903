import CollectInformations from "../views/CollectInformations/CollectInformations";
import StepIn from "../views/StepIn/StepIn";
import StepOut from "../views/StepOut/StepOut";
import Document from "../views/Documents/Document";
import Template from "../views/Documents/Template";
import Menu from "../components/SaurLayout/Menu";
import Home from "../views/Home/Home";
import History from "../views/Documents/History";

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", exact: true, name: "Welcome" },
  { path: "/home", name: "Home", component: Home },
  { path: "/stepin", name: "Entrée Step", component: StepIn },
  { path: "/stepout", name: "Sortie Step", component: StepOut },
  {
    path: "/collectinformations",
    name: "Collect Information",
    component: CollectInformations,
  },
  { path: "/history", name: "Historique", component: History },
  { path: "/document", name: "Menu", component: Menu },
  { path: "/analyses", name: "Analyses", component: Document },
  { path: "/metrologie", name: "Métrologie", component: Document },
  { path: "/suivi_agro", name: "Suivi agronomique", component: Document },
  {
    path: "/suivi_dechets",
    name: "Bordereaux de suivi des déchets",
    component: Document,
  },
  { path: "/gidaf", name: "GIDAF", component: Template },
  { path: "/travaux", name: "Travaux", component: Document },
  { path: "/maintenance", name: "Maintenance", component: Document },
  { path: "/contrat", name: "Contrat", component: Document },
  { path: "/facturation", name: "Facturation", component: Document },
  { path: "/autres_doc", name: "Autres document", component: Document },
  { path: "/report_annu", name: "Rapports annuels", component: Document },
  { path: "/report_mens", name: "Rapports mensuels", component: Document },
  { path: "/cr_reu", name: "CR de réunion", component: Document },
  { path: "/autres_report", name: "Autres   ", component: Document },
  { path: "/securite", name: "Securité", component: Document },
  {
    path: "/controle_regl",
    name: "Controle réglementaire  ",
    component: Document,
  },
];

export default routes;
