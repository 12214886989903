//Librairies
import React, { useEffect, useState } from "react";
import { useTable, useRowSelect, usePagination, useSortBy } from "react-table";
import fileDownload from "js-file-download";

//Assets
import "../document.scss";
import "../template.scss";
//Applications
import ModalGenerate from "../Modals/generate";
import DeleteDoc from "../Modals/delete";
import * as DocumentServices from "../../../services/documentServices";
import { formatedDate } from "../../../components/Helpers/ReactTableHelper";
import {
  Pagination,
  Tableau,
} from "../../../components/Helpers/ReactTableHelper";

export function generateModel(
  authentification,
  { siteId, periode, idDocument }
) {
  return DocumentServices.GenerateGidafFile(authentification, {
    siteId,
    periode,
    idDocument,
  });
}

export default function TemplateTable({
  datas = [],
  onDataFetchModel = () => {},
  onDataFetch = () => {},
  pageNumber = 1,
  pageSize = 5,
  authentification,
  siteId,
  fileCount,
}) {
  const [generateModal, setGenerateModal] = useState({
    open: false,
    payload: null,
  });
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    payload: null,
  });

  const data = React.useMemo(() => datas, [datas]);

  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: "id", // accessor is the "key" in the data
      },
      {
        Header: "Nom du fichier",
        accessor: "nomFichier",
      },
      {
        Header: "Date Ajout",
        accessor: "dateAjout",
        Cell: formatedDate,
      },
      {
        Header: "",
        accessor: "generate",
        Cell: (cell) => (
          <>
            <button
              type="button"
              className="btn link"
              onClick={(e) => {
                e.preventDefault();
                setGenerateModal({
                  open: true,
                  payload: {
                    id: cell.row.values.id,
                    title: cell.row.values.nomFichier,
                  },
                });
              }}
            >
              Générer
            </button>
            <button
              type="button"
              className="btn link "
              onClick={(e) => {
                e.preventDefault();

                DocumentServices.ExportFiles(authentification, {
                  siteId,
                  listModeleDocumentId: [cell.row.values.id],
                })
                  .then(async (result) => {
                    const filename = cell.row.values.nomFichier;
                    fileDownload(await result.blob(), filename);
                  })
                  .catch((message) => {});
              }}
            >
              <i className="fa fa-download text-black-50 font-2xl "></i>
            </button>
            <button
              type="button"
              className="btn link"
              onClick={(e) => {
                e.preventDefault();
                setDeleteModal({
                  open: true,
                  payload: {
                    id: cell.row.values.id,
                    title: cell.row.values.nomFichier,
                  },
                });
              }}
            >
              <i className="fa fa-trash-o text-black-50 font-2xl"></i>
            </button>
          </>
        ),
      },
    ],
    [setGenerateModal]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: "id",
        pageIndex: 0,
        pageSize: pageSize,
        sortBy: [{ id: "dateAjout", desc: true }],
      },
      manualPagination: true, // Tell the usePagination
      manualSortBy: true,
      defaultCanSort: true,
      disableSortRemove: true,
      disableMultiSort: true,
      pageCount: pageNumber,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    const SortBy = sortBy[0].id;
    const SortOrder = sortBy[0].desc ? "desc" : "asc";
    onDataFetchModel({ pageIndex, pageSize, SortBy, SortOrder });
  }, [onDataFetchModel, pageIndex, pageSize, sortBy]);

  return (
    <>
      <ModalGenerate
        id={generateModal && generateModal.payload && generateModal.payload.id}
        title={
          generateModal && generateModal.payload && generateModal.payload.title
        }
        openModal={generateModal.open}
        onClose={() => {
          setGenerateModal({ open: false, payload: null });
        }}
        onSubmit={({ periode, id }) => {
          generateModel(authentification, {
            siteId,
            periode: periode,
            idDocument: id,
          })
            .then(async () => {
              setGenerateModal({ open: false, payload: null });
              onDataFetchModel({ pageIndex, pageSize });
              onDataFetch({ pageIndex, pageSize });
            })
            .catch((error) => {
              console.log("error generate", error);
            });
        }}
      />
      <DeleteDoc
        rows={deleteModal.payload ? [deleteModal.payload.title] : []}
        onClose={() => {
          setDeleteModal({ open: false, payload: null });
        }}
        onSubmit={() => {
          DocumentServices.deleteModeleDocument(authentification, {
            siteId: siteId,
            listModeleDocumentId: [deleteModal.payload.id],
          })
            .then(async (res) => {
              if (res.status === 200) {
                onDataFetchModel({ pageIndex, pageSize });
                setDeleteModal({ open: false, payload: null });
              }
            })
            .catch(() => {
              throw new Error("erreur serveur");
            });
        }}
        openModal={deleteModal.open}
      />

      <Tableau
        isSorted={true}
        className="tableaudoctemplate"
        prepareRow={prepareRow}
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
      />
      {rows.length > pageSize - 1 && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          previousPage={previousPage}
        />
      )}
    </>
  );
}
