const initialState = {
  siteId: null,
  nomSite: null,
};

const siteinformation = (state = initialState, action = { type: null }) => {
  if (action.type === "InformationChange") {
    return {
      ...state,
      siteId: action.siteId,
      nomSite: action.nomSite,
    };
  } else {
    return state;
  }
};

export default siteinformation;
