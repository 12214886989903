import { format } from "date-fns";
import { GetAccessToken } from "../authentication/token";

export async function GetListOfModeleDocumentSimple(
  authentification,
  { siteId, CategorieDocId, PageNumber, PageSize, SortBy, SortOrder }
) {
  try {
    return await fetch(
      `${window.appSettings.apiendpointdocument}/ModeleDocument/GetListOfModeleDocumentSimple?siteId=${siteId}&CategorieDocId=${CategorieDocId}&PageNumber=${PageNumber}&PageSize=${PageSize}&SortBy=${SortBy}&SortOrder=${SortOrder}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function GetListOfModeleDocumentModele(
  authentification,
  { siteId, CategorieDocId, PageNumber, PageSize, SortBy, SortOrder }
) {
  try {
    return await fetch(
      `${window.appSettings.apiendpointdocument}/ModeleDocument/GetListOfModeleDocumentModele?siteId=${siteId}&CategorieDocId=${CategorieDocId}&PageNumber=${PageNumber}&PageSize=${PageSize}&SortBy=${SortBy}&SortOrder=${SortOrder}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function GetListOfModeleDocumentGenere(
  authentification,
  { siteId, CategorieDocId, PageNumber, PageSize, SortBy, SortOrder }
) {
  try {
    return await fetch(
      `${window.appSettings.apiendpointdocument}/ModeleDocument/GetListOfModeleDocumentGenere?siteId=${siteId}&CategorieDocId=${CategorieDocId}&PageNumber=${PageNumber}&PageSize=${PageSize}&SortBy=${SortBy}&SortOrder=${SortOrder}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function GetCountOfModeleDocument(
  authentification,
  { siteId, categorieDocId, typeDocId }
) {
  try {
    return await fetch(
      `${window.appSettings.apiendpointdocument}/ModeleDocument/${siteId}/GetCountOfModeleDocument/${categorieDocId}/${typeDocId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function ExportFiles(
  authentification,
  { siteId, listModeleDocumentId }
) {
  try {
    return await fetch(
      `${
        window.appSettings.apiendpointdocument
      }/ModeleDocument/ExportFiles?siteId=${siteId}&${listModeleDocumentId
        .map((id) => `listModeleDocumentId=${id}`)
        .join("&")}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
        },
        responseType: "stream",
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function ImportFile(
  authentification,
  { siteId, CotegorieDocId, TypeDocId, LibelleFr, File }
) {
  const formData = new FormData();
  formData.append("siteId", siteId);
  formData.append("CotegorieDocId", CotegorieDocId);
  formData.append("TypeDocId", TypeDocId);
  formData.append("LibelleFr", LibelleFr);
  formData.append("File", File[0]);

  try {
    return fetch(
      `${window.appSettings.apiendpointdocument}/ModeleDocument/ImportFile`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
        },
        body: formData,
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function UpdateModeleDocument(
  authentification,
  { siteId, modeleDocumentId, LibelleFr }
) {
  try {
    return await fetch(
      `${window.appSettings.apiendpointdocument}/ModeleDocument/${siteId}/UpdateLibelleFrDocuement/${modeleDocumentId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
        body: `"${LibelleFr}"`,
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function deleteModeleDocument(
  authentification,
  { siteId, listModeleDocumentId }
) {
  try {
    return await fetch(
      `${
        window.appSettings.apiendpointdocument
      }/ModeleDocument?siteId=${siteId}&${listModeleDocumentId
        .map((id) => `listModeleDocumentId=${id}`)
        .join("&")}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function loadEtapeTraitement(authentification, { siteId }) {
  try {
    return await fetch(
      `${window.appSettings.apiendpoint}/EtapeTraitement/${siteId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function GetIndicateursSuivisFiltre(authentification, { siteId }) {
  try {
    return await fetch(
      `${window.appSettings.apiendpoint}/IndicateurSuivi/GetIndicateursSuivisFiltre/${siteId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function GetHistoriqueDonnees(
  authentification,
  {
    siteId,
    DateDebut,
    DateFin,
    EtapeTraitementId,
    IndicateursSuivisIds,
    Releve,
    PageNumber,
    PageSize,
  }
) {
  try {
    return await fetch(
      `${window.appSettings.apiendpoint}/HistoriqueDonnees?siteId=${siteId}${
        DateDebut === null ? "" : `&DateDebut=${DateDebut}`
      }${DateFin === null ? "" : `&DateFin=${DateFin}`}${
        EtapeTraitementId === null
          ? ""
          : `&EtapeTraitementId=${EtapeTraitementId}`
      }&${IndicateursSuivisIds.map((id) => `IndicateursSuivisIds=${id}`).join(
        "&"
      )}&Releve=${Releve}&PageNumber=${PageNumber}&PageSize=${PageSize}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject(ex);
  }
}

export async function DownloadHistorique(
  authentification,
  {
    siteId,
    DateDebut,
    DateFin,
    EtapeTraitementId,
    IndicateursSuivisIds,
    Releve,
  }
) {
  try {
    return await fetch(
      `${
        window.appSettings.apiendpoint
      }/HistoriqueDonnees/DownloadHistoriqueFile?siteId=${siteId}${
        DateDebut === null ? "" : `&DateDebut=${DateDebut}`
      }${DateFin === null ? "" : `&DateFin=${DateFin}`}${
        EtapeTraitementId === null
          ? ""
          : `&EtapeTraitementId=${EtapeTraitementId}`
      }&${IndicateursSuivisIds.map(
        (item) => `IndicateursSuivisIds=${item.value}`
      ).join("&")}&Releve=${Releve}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
        },
        responseType: "stream",
      }
    );
  } catch (ex) {
    return Promise.reject(ex);
  }
}

export async function GenerateGidafFile(
  authentification,
  { siteId, periode, idDocument }
) {
  try {
    let response = await fetch(
      `${window.appSettings.apiendpointdocument}/ModeleDocument/GenerateGidafFile`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          siteId: siteId,
          periode: format(periode, "MM/yyyy"),
          idDocument: idDocument,
        }),
      }
    );

    if (response.status === 200) {
      return await response;
    } else {
      return Promise.reject("Erreur serveur");
    }
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}
