import React from 'react';
import { NavLink } from 'react-router-dom';



export const CATEGORIES = {
  ANALYSES: "ANALYSES",
  AUTRES_DOC: "AUTRES_DOC",
  AUTRES_REPORT: "AUTRES_REPORT",
  CONTRAT: "CONTRAT",
  CONTROLE_REGL: "CONTROLE_REGL",
  CR_REU: "CR_REU",
  FACTURATION: "FACTURATION",
  GIDAF: "GIDAF",
  MAINTENANCE: "MAINTENANCE",
  METROLOGIE: "METROLOGIE",
  REPORT_ANNU: "REPORT_ANNU",
  REPORT_MENS: "REPORT_MENS",
  SECURITE: "SECURITE",
  SUIVI_AGRO: "SUIVI_AGRO",
  SUIVI_DECHETS: "SUIVI_DECHETS",
  TRAVAUX: "TRAVAUX",
}

export const NAMES = {
  ANALYSES: "analyses",
  AUTRES_DOC: "autres documents contractuels",
  AUTRES_REPORT: "reporting - autres",
  CONTRAT: "contrat",
  CONTROLE_REGL: "contrôles réglementaires",
  CR_REU: "Comptes rendus de réunion",
  FACTURATION: "facturation",
  GIDAF: "gidaf",
  MAINTENANCE: "maintenance",
  METROLOGIE: "métrologie",
  REPORT_ANNU: "Rapports annuels​",
  REPORT_MENS: "Rapports mensuels",
  SECURITE: "sécurité",
  SUIVI_AGRO: "suivi agronomique",
  SUIVI_DECHETS: "bordereaux de suivi de déchets",
  TRAVAUX: "travaux",
}


export const Group = [
  { value:["/analyses","/controle_regl","/metrologie","/suivi_agro","/suivi_dechets", "/gidaf"], name:"Suivi Réglementaire (ICPE)"},
  { value:["/contrat", "/facturation", "/autres_doc"], name:"Documents contractuels"},
  { value:["/securite"], name:"Sécurité"},
  { value:[ "/travaux", "/maintenance"], name:"Travaux et maintenance"},
  { value:["/report_annu","/report_mens","/cr_reu", "/autres_report"], name:"Reporting"},
]


export const ANALYSES = () => (<NavLink to={{ pathname: "/analyses", state: { id: CATEGORIES.ANALYSES } }} className="nav-link pl-5 sublink" activeClassName="active"   ><label className="firstUpper m-0">{NAMES.ANALYSES}</label></NavLink>)
export const METROLOGIE = () => <NavLink to={{ pathname: "/metrologie", state: { id: CATEGORIES.METROLOGIE } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.METROLOGIE}</label></NavLink>
export const SUIVI_AGRO = () => <NavLink to={{ pathname: "/suivi_agro", state: { id: CATEGORIES.SUIVI_AGRO } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.SUIVI_AGRO}</label></NavLink>
export const SUIVI_DECHETS = () => <NavLink to={{ pathname: "/suivi_dechets", state: { id: CATEGORIES.SUIVI_DECHETS } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.SUIVI_DECHETS}</label></NavLink>
export const GIDAF = () => <NavLink to={{ pathname: "/gidaf", state: { id: CATEGORIES.GIDAF } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0 text-uppercase">{NAMES.GIDAF}</label></NavLink>
export const TRAVAUX = () => <NavLink to={{ pathname: "/travaux", state: { id: CATEGORIES.TRAVAUX } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.TRAVAUX}</label></NavLink>
export const MAINTENANCE = () => <NavLink to={{ pathname: "/maintenance", state: { id: CATEGORIES.MAINTENANCE } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.MAINTENANCE}</label></NavLink>
export const CONTRAT = () => <NavLink to={{ pathname: "/contrat", state: { id: CATEGORIES.CONTRAT } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.CONTRAT}</label></NavLink>
export const FACTURATION = () => <NavLink to={{ pathname: "/facturation", state: { id: CATEGORIES.FACTURATION } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.FACTURATION}</label></NavLink>
export const AUTRES_DOC = () => <NavLink to={{ pathname: "/autres_doc", state: { id: CATEGORIES.AUTRES_DOC } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.AUTRES_DOC}</label></NavLink>
export const REPORT_ANNU = () => <NavLink to={{ pathname: "/report_annu", state: { id: CATEGORIES.REPORT_ANNU } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.REPORT_ANNU}</label></NavLink>
export const REPORT_MENS = () => <NavLink to={{ pathname: "/report_mens", state: { id: CATEGORIES.REPORT_MENS } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.REPORT_MENS}</label></NavLink>
export const CR_REU = () => <NavLink to={{ pathname: "/cr_reu", state: { id: CATEGORIES.CR_REU } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.CR_REU}</label></NavLink>
export const AUTRES_REPORT = () => <NavLink to={{ pathname: "/autres_report", state: { id: CATEGORIES.AUTRES_REPORT } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.AUTRES_REPORT}</label></NavLink>
export const REGLEMENTAIRE = () => <NavLink to={{ pathname: "/controle_regl", state: { id: CATEGORIES.CONTROLE_REGL } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.CONTROLE_REGL}</label></NavLink>
export const SECURITE = () => <NavLink to={{ pathname: "/securite", state: { id: CATEGORIES.SECURITE } }} className="nav-link pl-5 sublink" activeClassName="active"  ><label className="firstUpper m-0">{NAMES.SECURITE}</label></NavLink>


//return la liste des categories formatées en tableau pathArray = [/analyses, /autres_doc,...] 
// ou en objet paths={ ANALYSES: "/analyses", AUTRES_DOC: "/autre_doc",...} 
export function CategoriesHelper() {
  const items = { ...CATEGORIES }
  const pathArray = [];
  const paths = {};

  for (var key in items) {
    pathArray.push(`/${items[key]}`.toLowerCase());
    paths[key] = `/${items[key]}`.toLowerCase();
  }

  return { pathArray, paths };
}

export function getIdfromPathName(path = "") {
  if (path === null) { throw new Error("le paramètre est null") }
  return path.replace('/', '').toUpperCase();
}

//récupère le nom du document en se basant sur l'url
export function getNameFromPathName(path = "") {
  if (path === null) { throw new Error("le paramètre est null") }
  const name = NAMES[path.replace('/', '').toUpperCase()];
  return `${name === undefined ? "" : name}`.toUpperCase();
}

//récupère le nom du groupe de l'item en se basant sur l'url
export function getGroupByPathName(pathname){
  const item  = Group.find(x=> x.value.includes(pathname));
  return item ? item.name : "";
}