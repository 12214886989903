import { OktaAuth } from "@okta/okta-auth-js";
import Keycloak from "keycloak-js";
import { KeyCloakProvider } from "../interfaces/keycloakProvider";
import { LocalStorage } from "../utils/localStorage";
import { allReducers } from "../stores/index";
import { ReduxAuthType } from "../types/authentificationstoretypes";

let authProvider: Keycloak | undefined = undefined;

const tokenCallback = (keycloak: Keycloak) => {
  LocalStorage.token.set(keycloak.token);
  LocalStorage.refreshToken.set(keycloak.refreshToken);
  LocalStorage.userInfo.roles.set(keycloak.tokenParsed?.realm_access?.roles);
  LocalStorage.userInfo.email.set(keycloak.tokenParsed?.email);
  LocalStorage.userInfo.name.set(keycloak.tokenParsed?.name);
};

const initKeycloakProvider = (): Keycloak => {
  const keycloak = new Keycloak(
    JSON.parse(
      localStorage.getItem("provider") ??
        JSON.stringify({
          url: process.env.REACT_APP_URL_KEYCLOAK,
          realm: process.env.REACT_APP_KEYCLOAK_REALM,
          clientId: process.env.REACT_APP_KEYCLOAK_NEXTEP_CLIENT_ID,
        })
    )
  );
  return keycloak;
};

export const getAuthProvider = (): Keycloak => {
  // eslint-disable-next-line no-undef
  if (typeof authProvider === "undefined") {
    authProvider = initKeycloakProvider();
  }
  return authProvider;
};
