import { GetAccessToken } from "../authentication/token";
import { DonneesIndicateurCollectionModel } from "../types/api/donneesIndicateurs/donneesIndicateurCollectionModel";
import { DonneesIndicateurConsolideeModel } from "../types/api/donneesIndicateurs/donneesIndicateurConsolideeModel";
import { DonneesIndicateurPostModel } from "../types/api/donneesIndicateurs/donneesIndicateurPostModel";
import { DonneesIndicateurSuiviByDomainModel } from "../types/api/donneesIndicateurs/donneesIndicateurSuiviByDomainModel";
import { DonneesIndicateurSuiviModel } from "../types/api/donneesIndicateurs/donneesIndicateurSuiviModel";
import { DonneesIndicateurSuiviUnitModel } from "../types/api/donneesIndicateurs/donneesIndicateurSuiviUnitModel";
import { SiteConfig } from "../types/api/siteConfig";
import { ReduxAuthState } from "../types/authentificationstoretypes";
import { SiteInformation } from "../types/siteInformation";

export async function loadData(
  authentification: ReduxAuthState,
  { siteId, domaineIdn, dateDebut, dateFin }: DonneesIndicateurCollectionModel
) {
  try {
    let result = await fetch(
      `${
        (window as any).appSettings.apiendpoint
      }/DonneeIndicateur/GetIndicateursCollections`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          siteId: siteId,
          domaineId: domaineIdn,
          dateDebut: dateDebut,
          dateFin: dateFin,
        }),
      }
    );

    if (result.status === 200) {
      return await result.json();
    }

    if (result.status === 204) {
      return await [];
    }

    return Promise.reject("Erreur serveur");
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function editData(
  authentification: ReduxAuthState,
  {
    siteId,
    indicateurId,
    valeur,
    valeurLabo,
    date,
    indicateurIdSiccite,
    valeurSiccite,
  }: DonneesIndicateurConsolideeModel
) {
  try {
    return await fetch(
      `${
        (window as any).appSettings.apiendpoint
      }/DonneeIndicateur/AddOrUpdateDonneeIndicateurConsolidees`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          siteId: siteId ?? "",
          indicateurId: indicateurId ?? "",
          valeur: valeur ?? "",
          valeurLabo: valeurLabo ?? "",
          date: date ?? "",
          indicateurIdSiccite: indicateurIdSiccite ?? "",
          valeurSiccite: valeurSiccite ?? "",
        }),
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function deleteData(
  authentification: ReduxAuthState,
  {
    siteId,
    indicateurId,
    valeur,
    valeurLabo,
    date,
    indicateurIdSiccite,
    valeurSiccite,
  }: DonneesIndicateurConsolideeModel
) {
  try {
    return await fetch(
      `${
        (window as any).appSettings.apiendpoint
      }/DonneeIndicateur/DeleteDonneeIndicateurConsolidees`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          siteId: siteId ?? "",
          indicateurId: indicateurId ?? "",
          valeur: valeur ?? "",
          valeurLabo: valeurLabo ?? "",
          date: date ?? "",
          indicateurIdSiccite: indicateurIdSiccite ?? "",
          valeurSiccite: valeurSiccite ?? "",
        }),
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function loadDataIndicateurSuivi(
  authentification: ReduxAuthState,
  { siteId, domaineId }: DonneesIndicateurSuiviByDomainModel
) {
  try {
    return await fetch(
      `${
        (window as any).appSettings.apiendpoint
      }/IndicateurSuivi/GetIndicateurs/${siteId}/${domaineId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function loadEtapeTraitement(
  authentification: ReduxAuthState,
  { siteId, domaineId }: DonneesIndicateurSuiviByDomainModel
) {
  try {
    return await fetch(
      `${(window as any).appSettings.apiendpoint}/EtapeTraitement/${siteId}${
        domaineId !== undefined ? "/" + domaineId : ""
      }`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function postIndicateurSuivi(
  authentification: ReduxAuthState,
  { siteId, indicateurId, afficher }: DonneesIndicateurSuiviModel
) {
  try {
    return await fetch(
      `${(window as any).appSettings.apiendpoint}/IndicateurSuivi`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          siteId: siteId,
          indicateurId: indicateurId,
          afficher: afficher,
        }),
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export async function getSiteConfig(
  authentification: ReduxAuthState,
  siteId: string
): Promise<SiteConfig | undefined> {
  try {
    let responce = await fetch(
      `${
        (window as any).appSettings.apiendpoint
      }/Site/GetConfig?SiteId=${siteId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
    Promise.resolve(await responce.json());
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
}

export const getUnitIndicateur = async (
  authentification: ReduxAuthState,
  {
    siteId,
    indicateurSuiviId,
    etapeTraitementId,
    afficher,
  }: DonneesIndicateurSuiviUnitModel
) => {
  try {
    return await fetch(
      `${
        (window as any).appSettings.apiendpoint
      }/DonneeIndicateur/GetUnitesIndicateursSuivis?SiteId=${siteId}&IndicateurSuiviId=${indicateurSuiviId}&ParametreId=DCO&EtapeTraitementId=${etapeTraitementId}&TypeAffichage=${afficher}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
};

export const fetchDonneesIndicateurPost = async (
  authentification: ReduxAuthState,
  {
    siteId,
    dateDebut,
    dateFin,
    indicateurSuiviId,
    etapeTraitementId,
    typeAffichage,
    parametreId,
  }: DonneesIndicateurPostModel
) => {
  try {
    return await fetch(
      `${(window as any).appSettings.apiendpoint}/DonneeIndicateur`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dateDebut: dateDebut,
          DateFin: dateFin,
          IndicateurSuiviId: indicateurSuiviId,
          TypeAffichage: typeAffichage,
          ParametreId: parametreId,
          EtapeTraitementId: etapeTraitementId,
          SiteId: siteId,
        }),
      }
    );
  } catch (ex) {
    return Promise.reject("Erreur serveur");
  }
};
