import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { lastDayOfMonth, isExists, eachDayOfInterval, eachMonthOfInterval, eachYearOfInterval, startOfYear, endOfYear, startOfMonth, endOfMonth, format, subYears, set, isBefore } from 'date-fns'
import {fr} from "date-fns/locale"
import { useForm, Controller } from 'react-hook-form';

import '../history.scss';


export default function DatePicker({ onSubmitDate, onCancelDate }) {
    const { handleSubmit, errors, control, setError, setValue } = useForm();

    const currentDay = new Date();

    const [currentStartDay, setCurrentStartDay] = useState(new Date());
    const [currentEndtDay, setCurrentEndDay] = useState(new Date());

    const dayInterval = eachDayOfInterval({ start: startOfMonth(currentStartDay), end: endOfMonth(currentStartDay) })
    const monthInterval = eachMonthOfInterval({ start: startOfYear(currentDay), end: endOfYear(currentDay) })
    const yearInterval = eachYearOfInterval({ start: subYears(currentDay, 5), end: currentDay })

    const dayRightInterval = eachDayOfInterval({ start: startOfMonth(currentEndtDay), end: endOfMonth(currentEndtDay) })
    const monthRightInterval = eachMonthOfInterval({ start: startOfYear(currentDay), end: endOfYear(currentDay) })
    const yearRightInterval = eachYearOfInterval({ start: subYears(currentDay, 5), end: currentDay })

    useEffect(() => {

        if (isExists(currentStartDay.getFullYear(), currentStartDay.getMonth(), currentStartDay.getDate())) {
            setValue("leftDay", { label: format(currentStartDay, 'd',{locale:fr}), value: currentStartDay.getDate() })
            setValue("leftMonth", { label: format(currentStartDay, 'MMM',{locale:fr}), value: currentStartDay.getMonth() })
            setValue("leftYear", { label: format(currentStartDay, 'yyy',{locale:fr}), value: currentStartDay.getFullYear() })
        } else {
            setValue("leftMonth", { label: format(lastDayOfMonth(currentStartDay), 'MMM'), value: currentStartDay.getMonth() })
        }

        if (isExists(currentEndtDay.getFullYear(), currentEndtDay.getMonth(), currentEndtDay.getDate())) {
            setValue("rightDay", { label: format(currentEndtDay, 'd',{locale:fr}), value: currentEndtDay.getDate() })
            setValue("rightMonth", { label: format(currentEndtDay, 'MMM',{locale:fr}), value: currentEndtDay.getMonth() })
            setValue("rightYear", { label: format(currentEndtDay, 'yyy',{locale:fr}), value: currentEndtDay.getFullYear() })
        }
        else {
            setValue("rightMonth", { label: format(lastDayOfMonth(currentEndtDay), 'MMM'), value: currentEndtDay.getMonth() })
        }

    }, [setValue, currentStartDay, currentEndtDay])


    return (<>
        <form  data-testid="form" id="datepicker" onSubmit={handleSubmit(() => {

            if (isBefore(currentEndtDay, currentStartDay)) {
                setError('form', "inval", "la date de début ne peut être inférieure à la date de fin");
                return;
            }

            onSubmitDate({ left: currentStartDay, right: currentEndtDay });

        })} className="d-flex align-items-center">
            <Controller
                as={Select}
                name="leftDay"
                options={dayInterval.map(day => ({ label: format(day, 'd',{locale:fr}), value: format(day, 'd',{locale:fr}) }))}
                className="flex-grow-1 mr-2"
                control={control}
                onChange={(data) => {
                    setCurrentStartDay(set(currentStartDay, { date: data[0].value }))
                }}
            />
            <Controller
                as={Select}
                options={monthInterval.map(day => ({ label: format(day, 'MMM',{locale:fr}), value: day.getMonth() }))}
                className="flex-grow-1 mx-2"
                name="leftMonth"
                control={control}
                onChange={(data) => {
                    setCurrentStartDay(set(currentStartDay, { month: data[0].value }))
                }}
            />
            <Controller
                as={Select}
                options={yearInterval.map(day => ({ label: format(day, 'yyy',{locale:fr}), value: format(day, 'y',{locale:fr}) }))}
                className="flex-grow-1 mx-2"
                name="leftYear"
                control={control}
                onChange={(data) => {
                    setCurrentStartDay(set(currentStartDay, { year: data[0].value }))
                }}
            />
            Au
            <Controller
                as={Select}
                options={dayRightInterval.map(day => ({ label: format(day, 'd',{locale:fr}), value: format(day, 'd',{locale:fr}) }))}
                className="flex-grow-1 mx-2"
                name="rightDay"
                control={control}
                onChange={(data) => {
                    setCurrentEndDay(set(currentEndtDay, { date: data[0].value }))
                }}
            />

            <Controller
                as={Select}
                options={monthRightInterval.map(day => ({ label: format(day, 'MMM',{locale:fr}), value: day.getMonth() }))}
                className="flex-grow-1 mx-2"
                name="rightMonth"
                control={control}
                onChange={(data) => {
                    setCurrentEndDay(set(currentEndtDay, { month: data[0].value }))
                }}
            />
            <Controller
                as={Select}
                options={yearRightInterval.map(day => ({ label: format(day, 'yyy',{locale:fr}), value: format(day, 'y',{locale:fr}) }))}
                className="flex-grow-1 ml-2"
                name="rightYear"
                control={control}
                onChange={(data) => {
                    setCurrentEndDay(set(currentEndtDay, { year: data[0].value }))
                }}
            />

        </form>
        <div className="d-flex justify-content-center mt-2 ">
            <span className="error-field">{errors && errors.form && errors.form.message}</span>
        </div>
        <div className="d-flex justify-content-center">
            <button className="btn btn-primary mx-2 mt-2" type="button" onClick={onCancelDate} form="datepicker">Annuler</button>
            <button className="btn btn-primary mx-2 mt-2" type="submit" form="datepicker">OK</button>
        </div>
    </>
    )
}