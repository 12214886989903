import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select'
import { eachMonthOfInterval, format } from 'date-fns';
import { fr } from 'date-fns/locale';

import { useForm, Controller } from 'react-hook-form';

export default function Generate({ onSubmit, loadingModal, onClose, openModal, id, title }) {

    const { handleSubmit, errors, control } = useForm();


    const today = new Date()

    const monthInterval = eachMonthOfInterval({
        start: new Date(today.getFullYear(), 0),
        end: today
    })

    const optionsData = monthInterval.map(month => ({ value: month, label: format(month, "LLLL yyyy", {locale:fr}) }))

    const CloseButton = (
        <div>
            <button onClick={onClose} className="btn close-btn"></button>
        </div>
    )

    return (<Modal isOpen={openModal} size="lg" >
        <ModalHeader className="text-primary pl-5" close={CloseButton} ></ModalHeader>
        <ModalBody className="p-5">
            <form
                id="generateForm"
                onSubmit={handleSubmit((data) => {
                    onSubmit({periode:data.periode.value, id:id});
                })}>
                <h3 className="mb-4">Document à générer</h3>
                <div className="d-flex">
                    <label className="w-25 mr-4 my-auto "><b>{title}</b></label>
                    <Controller
                        rules={{ required: "la sélection d'une période est obligatoire" }}
                        as={Select}
                        options={optionsData}
                        className="ml-5 w-75"
                        name="periode"
                        control={control}
                        placeholder="Date"
                    />
                </div>
                {errors && <p className="error-text d-flex justify-content-center">{errors && errors.periode && errors.periode.message}</p>}
            </form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
            <button disabled={loadingModal} className="btn btn-outline-primary btn-lg mb-3" onClick={onClose}>Annuler</button>
            <button form="generateForm" type="submit" className="btn btn-primary btn-lg mb-3">Valider</button>
        </ModalFooter>
    </Modal>)
}