export function parseJwt(token: string) {
  if (typeof token === "undefined") return {};
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export async function logoutRequest(refreshToken: string): Promise<void> {
  const TOKEN_URL = "/logout";

  const body: any = {
    refresh_token: refreshToken,
    client_id: "NEXTEP-KEYCLOAK-DEV", //process.env.REACT_APP_KEYCLOAK_NEXTEP_CLIENT_ID,
  };

  var formBody = [];
  for (var property in body) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(body[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  const stringBody = formBody.join("&");

  fetch(
    `${
      process.env.REACT_APP_KEYCLOAK_REALM_NEXTEP_URL
    }/protocol/openid-connect/logout&redirect_uri=${encodeURIComponent(
      window.origin
    )}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: stringBody,
      method: "POST",
    }
  )
    .then((e) => {
      console.log(e);
    })
    .catch((e) => {
      console.log(e);
    });
  return Promise.resolve();
}
