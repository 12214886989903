import React, {  } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';





export default function DeleteValue ({onCloseAll,binModal,handleSubmit=()=>{},  onValidBin , loadingModal , cell}){
   
    const CloseButton = (
        <div>
            <button onClick={onCloseAll} className="btn close-btn"></button>
        </div>
    )

    return ( <Modal isOpen={binModal}>
        <ModalHeader close={CloseButton} >{cell && cell.rowInfo && cell.rowInfo.libelleFr}</ModalHeader>
        <ModalBody>
            <form id="deletevaleur" data-testid="form" onSubmit={handleSubmit((data) => onValidBin(data, cell))}>
                <div>
                    Êtes-vous sûr(e) de vouloir supprimer cette donnée ?
                    </div>
            </form>
        </ModalBody>
        <ModalFooter>
            <button disabled={loadingModal} className="btn btn-outline-primary btn-lg mb-3" onClick={onCloseAll}>Non</button>
            <button disabled={loadingModal} type="submit" form="deletevaleur" className="btn btn-primary btn-lg mb-3">Oui</button>
        </ModalFooter>
    </Modal>)
}