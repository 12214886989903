import { ReduxAuthState } from "../types/authentificationstoretypes";
import { getAuthProvider } from "./authProvider";

export async function GetAccessToken(authentification) {
  if (!authentification) return "";
  if (authentification.token) {
    return authentification.token;
  } else {
    return localStorage.token;
  }
}
