import React, { Component, useCallback, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Label } from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
import { GetAccessToken } from "../../authentication/token";
import { SiteInformation } from "../../types/siteInformation";
import { DCOLine, DCOLineDataset } from "../../types/components/dcoLine";
import { ReduxAuthState } from "../../types/authentificationstoretypes";
import {
  fetchDonneesIndicateurPost,
  getUnitIndicateur,
  loadData,
} from "../../services/IndicatorServices";
import authentification from "../../stores/authentification";

interface DCOProps {
  authentification: ReduxAuthState;
  siteInformation: SiteInformation;
  dateDebut: string;
  dateFin: string;
  action: string;
  etapeTraitementId: string;
  indicateurSuiviId: string;
  titre: string;
  moyen: string;
}
// Component to Get Input and Output DCO
const DCO = (props: DCOProps) => {
  const [labels, setLabels] = useState(null);
  const [data, setData] = useState(null);
  const [moyen, setMoyen] = useState(null);
  const [dateDebut, setDateDebut] = useState(props.dateDebut);
  const [dateFin, setDateFin] = useState(props.dateFin);
  const [uniteCharge, setUniteCharge] = useState<string>("");
  const [line, setLine] = useState<DCOLine>({
    labels: null,
    datasets: [
      {
        label: "Charge DCO",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#FFCD00",
        pointBackgroundColor: "#FFCD00",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#FFCD00",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: null,
        borderColor: "#FFCD00",
        borderCapStyle: "butt",
      },
    ],
  });

  const baseLineSeuilMax: DCOLineDataset = {
    label: "Max Charge",
    fill: false,
    lineTension: 0.1,
    backgroundColor: "#F24C07",
    borderColor: "#F24C07",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: "#F24C07",
    pointBackgroundColor: "#F24C07",
    pointHoverBackgroundColor: "#F24C07",
    pointHoverBorderColor: "#F24C07",
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: null,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
  };

  useEffect(() => {
    const initData = async () => {
      Promise.all([await LoadData(), await GetUnitIndicateur()]);
    };
    initData();
  }, []);

  const GetUnitIndicateur = useCallback(async () => {
    try {
      let result = await getUnitIndicateur(props.authentification, {
        siteId: props.siteInformation.siteId,
        indicateurSuiviId: props.indicateurSuiviId,
        etapeTraitementId: props.etapeTraitementId,
        afficher: props.action,
      });
      if (result.status === 200) {
        let data = await result.json();
        setUniteCharge(data.uniteIndicateurSuiviCharge);
      }
    } catch (ex) {}
  }, []);

  const LoadData = useCallback(async () => {
    try {
      let result = await fetchDonneesIndicateurPost(props.authentification, {
        dateDebut: moment(props.dateDebut).format("DD/MM/YYYY HH:mm:ss"),
        dateFin: moment(props.dateFin).format("DD/MM/YYYY HH:mm:ss"),
        indicateurSuiviId: props.indicateurSuiviId,
        typeAffichage: props.action,
        parametreId: "DCO",
        etapeTraitementId: props.etapeTraitementId,
        siteId: props.siteInformation.siteId,
      });
      if (result.status === 200) {
        let data = await result.json();
        const newLine = { ...line };
        newLine.labels = data.labels;
        newLine.datasets[0].data = data.charges;
        if (data.seuilMaxCharge !== null) {
          if (line.datasets.length === 1) {
            newLine.datasets.push({
              ...baseLineSeuilMax,
              data: data.seuilMaxCharge,
            });
          } else {
            newLine.datasets[1].data = data.seuilMaxCharge;
          }
        }
        setLine(newLine);
        setMoyen(data.moyen);
      }
    } catch (ex) {}
  }, []);

  useEffect(() => {
    LoadData();
  }, [props.dateDebut, props.dateFin]);

  useEffect(() => {
    GetUnitIndicateur();
  }, [props.action]);

  return (
    <div style={{ backgroundColor: "white" }}>
      <Label className="widget-title">
        {props.titre} ({uniteCharge})
      </Label>
      <div className="mt-5 mt-lg-0">
        {" "}
        <Line
          height={100}
          data={line}
          options={{
            legend: {
              display: false,
            },
            maintainAspectRatio: true,
            responsive: true,
          }}
        />
      </div>
    </div>
  );
};

export default connect((state) => state)(DCO);
