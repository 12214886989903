import React, { useMemo, useState, useEffect } from "react";
import { useTable, useRowSelect, usePagination } from "react-table";

import {
  Pagination,
  Tableau,
} from "../../../components/Helpers/ReactTableHelper";
import "../history.scss";

export default function Table({
  data = [],
  onSelectedRow = () => [],
  onDataFetch = () => {},
  pageNumber = 1,
  pageSize = 20,
  DateDebut,
  DateFin,
  EtapeTraitementId,
  IndicateursSuivisIds,
  Releve,
  SiteId,
  authentification,
}) {
  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: "id", // accessor is the "key" in the data
      },
      {
        Header: "Date",
        accessor: "date", // accessor is the "key" in the data
      },
      {
        Header: " Catégorie",
        accessor: "domaineLibelleFr",
      },
      {
        Header: "",
        accessor: "etapeTraitementId",
      },
      {
        Header: "Etape de la filière",
        accessor: "etapeTraitementLibelleFr",
      },
      {
        Header: "",
        accessor: "indicateurSuiviId",
      },
      {
        Header: "Indicateur ",
        accessor: "libelleIndicateurSuivi",
      },
      {
        Header: "Valeur",
        accessor: "valeur",
      },
      {
        Header: "Valeur certifiée",
        accessor: "valeurLabo",
      },
    ],
    []
  );

  const [datas, setDatas] = useState(data);
  const [_pageNumber, setPageNumber] = React.useState(pageNumber);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: useMemo(() => datas, [datas]),
      initialState: {
        hiddenColumns: ["id", "etapeTraitementId", "indicateurSuiviId"],
        pageIndex: 0,
        pageSize: pageSize,
      },
      manualPagination: true, // Tell the usePagination
      pageCount: _pageNumber,
    },
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    onSelectedRow(selectedFlatRows);
  }, [onSelectedRow, selectedFlatRows]);

  useEffect(() => {
    if (SiteId !== null) {
      onDataFetch({
        pageIndex,
        pageSize,
        DateDebut,
        DateFin,
        EtapeTraitementId,
        IndicateursSuivisIds,
        Releve,
        SiteId,
        authentification,
      }).then((res) => {
        setDatas(res.historiqueDonnees);
        setPageNumber(Math.ceil(res.nbrPage / pageSize));
      });
    }
  }, [
    onDataFetch,
    pageIndex,
    pageSize,
    DateDebut,
    DateFin,
    EtapeTraitementId,
    IndicateursSuivisIds,
    Releve,
    SiteId,
    authentification,
  ]);

  return (
    <>
      <Tableau
        className="tableauhistory"
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        rows={rows}
        getTableBodyProps={getTableBodyProps}
      />
      {rows.length > pageSize - 1 && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          previousPage={previousPage}
        />
      )}
    </>
  );
}
