import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from "react-hook-form";


export default function Import({ onSubmit, loadingModal, onClose, openModal=false }) {

    const { register, handleSubmit , errors} = useForm();

    const CloseButton = (
        <div>
            <button onClick={onClose} className="btn close-btn"></button>
        </div>
    )

    return (<Modal isOpen={openModal} size="lg" >
        <ModalHeader className="text-primary pl-5" close={CloseButton} ></ModalHeader>
        <ModalBody className="p-5">
            <form id="importform" onSubmit={handleSubmit(onSubmit)}>
                <h3 className="mb-4">Le fichier à importer</h3>
                    <input type="file" name="File" className={`form-control p-1 w-100`} id="importFile" aria-describedby="importFile" ref={register({required : "Veuillez sélectionner un fichier"})} />
                <div className="py-2 d-flex" >
                    <label className="col-form-label text-nowrap mr-2">   Nom du fichier </label>
                    <input type="text" name="LibelleFr" className={`form-control ${errors && errors.LibelleFr ? "error-field" : ""}`} ref={register({required : "obligatoire"})} />
                </div>
                {errors && <p className="error-text d-flex justify-content-center">{errors && errors.File && errors.File.message}</p>}
            </form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
            <button disabled={loadingModal} className="btn btn-outline-primary btn-lg mb-3" onClick={onClose}>Annuler</button>
            <button type="submit" form="importform" disabled={loadingModal} className="btn btn-primary btn-lg mb-3">Valider</button>
        </ModalFooter>
    </Modal>)
}