//Librairies
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardColumns,
  Button,
  ButtonToolbar,
  Spinner,
  Container,
} from "reactstrap";
import { set, format } from "date-fns";
//Applications
import VolumeWater from "../../components/VolumeWater/VolumeWater";
import Ph from "../../components/PH/Ph.js";
import WaterQuality from "../../components/WaterQuality/WaterQuality.js";
import WaterTemperature from "../../components/WaterTemperature/WaterTemperature.js";
import WasteAndUnderProduct from "../../components/WasteAndUnderProduct/WasteAndUnderProduct.js";
import DateRangePicker from "../StepIn/Datepicker";
import { StartOfWeek, month_name } from "../core";
//Assets
import "../../styles/Button.css";
import "../../styles/Label.css";
import "../../styles/Spinner.css";

export function ButtonPeriodBar({
  DateDebut = null,
  DateFin = null,
  action = null,
  CustomDate = null,
  OnClickAction = () => {},
}) {
  return (
    <ButtonToolbar style={{ marginBottom: "10px", justifyContent: "left" }}>
      <Button
        outline
        className={action === "Annee" ? "btn-pill btn-selected" : "btn-pill"}
        onClick={() => {
          if (CustomDate) {
            OnClickAction("Custom", {
              start: DateDebut,
              end: DateFin,
              time: "Annee",
            });
          } else {
            OnClickAction("Annee");
          }
        }}
      >
        Année
      </Button>
      <Button
        outline
        className={action === "Mois" ? "btn-pill btn-selected" : "btn-pill"}
        onClick={() => {
          if (CustomDate) {
            OnClickAction("Custom", {
              start: DateDebut,
              end: DateFin,
              time: "Mois",
            });
          } else {
            OnClickAction("Mois");
          }
        }}
      >
        Mois
      </Button>
      <Button
        outline
        className={action === "Semaine" ? "btn-pill btn-selected" : "btn-pill"}
        onClick={() => {
          if (CustomDate) {
            OnClickAction("Custom", {
              start: DateDebut,
              end: DateFin,
              time: "Semaine",
            });
          } else {
            OnClickAction("Semaine");
          }
        }}
      >
        Semaine
      </Button>
      <Button
        outline
        className={action === "Jour" ? "btn-pill btn-selected" : "btn-pill"}
        onClick={() => {
          if (CustomDate) {
            OnClickAction("Custom", {
              start: DateDebut,
              end: DateFin,
              time: "Jour",
            });
          } else {
            OnClickAction("Jour");
          }
        }}
      >
        Jour
      </Button>
      <Button
        outline
        className={action === "Heure" ? "btn-pill btn-selected" : "btn-pill"}
        onClick={() => {
          if (CustomDate) {
            OnClickAction("Custom", {
              start: DateDebut,
              end: DateFin,
              time: "Heure",
            });
          } else {
            OnClickAction("Heure");
          }
        }}
      >
        Heure
      </Button>
    </ButtonToolbar>
  );
}

export function CardView({
  DateDebut = null,
  DateFin = null,
  action = {},
  volumeWater = { Titre: "", IndicateurSuiviId: "", Moyen: "" },
  ph = { IndicateurSuiviId: "" },
  waterTemperature = { IndicateurSuiviId: "" },
  waterQuality = { Titre: "", EtapeTraitementId: "", GrandeurEvalueeId: "" },
}) {
  return (
    <>
      <CardColumns className="cols-3">
        <Card>
          <CardBody>
            <VolumeWater
              DateDebut={DateDebut}
              DateFin={DateFin}
              Action={action}
              IndicateurSuiviId={volumeWater.IndicateurSuiviId}
              Titre={volumeWater.Titre}
              Moyen={volumeWater.Moyen}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Ph
              DateDebut={DateDebut}
              DateFin={DateFin}
              Action={action}
              IndicateurSuiviId={ph.IndicateurSuiviId}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <WaterTemperature
              DateDebut={DateDebut}
              DateFin={DateFin}
              Action={action}
              IndicateurSuiviId={waterTemperature.IndicateurSuiviId}
            />
          </CardBody>
        </Card>
      </CardColumns>
      <Card>
        <CardBody>
          <WaterQuality
            DateDebut={DateDebut}
            DateFin={DateFin}
            Action={action}
            Titre={waterQuality.Titre}
            GrandeurEvalueeId={waterQuality.GrandeurEvalueeId}
            EtapeTraitementId={waterQuality.EtapeTraitementId}
          />
        </CardBody>
      </Card>
    </>
  );
}

class StepOut extends Component {
  constructor(props) {
    super(props);
    let dateTime = new Date();
    this.DateNow = new Date(
      dateTime.getFullYear(),
      dateTime.getMonth(),
      dateTime.getDate()
    );
    this.OnClickAction = this.OnClickAction.bind(this);
    this.OnClichChangeDate = this.OnClichChangeDate.bind(this);
    this.OnClickActionDechet = this.OnClickActionDechet.bind(this);
    this.OnClichChangeDateDechet = this.OnClichChangeDateDechet.bind(this);
    let datedebut = new Date(
      this.DateNow.getFullYear(),
      this.DateNow.getMonth(),
      this.DateNow.getDate() - 14
    );
    let datefin = new Date(
      this.DateNow.getFullYear(),
      this.DateNow.getMonth(),
      this.DateNow.getDate(),
      0,
      0,
      0
    );
    let datedebutDechet = StartOfWeek(
      new Date(
        this.DateNow.getFullYear(),
        this.DateNow.getMonth(),
        this.DateNow.getDate()
      ),
      0,
      0,
      0
    );
    let datefinDechet = new Date(
      datedebutDechet.getFullYear(),
      datedebutDechet.getMonth(),
      datedebutDechet.getDate() + 6,
      0,
      0,
      0
    );
    this.state = {
      action: "Jour",
      DateDebut: datedebut,
      DateFin: datefin,
      TextInterval: `Du ${datedebut.getDate()} ${month_name(
        datedebut
      )} au ${datefin.getDate()} ${month_name(datefin)}`,
      actionDechet: "Semaine",
      TextDechet: `Du ${datedebutDechet.getDate()} ${month_name(
        datedebutDechet
      )} au ${datefinDechet.getDate()} ${month_name(datefinDechet)}`,
      DateDebutDechet: datedebutDechet,
      DateFinDechet: datefinDechet,
      DisableButton: true,
      DisableButtonDechet: true,
      CustomDate: false,
    };
  }

  // On click Next or Privious
  OnClichChangeDate(props) {
    if (props === "Next") {
      this.ChangeDate(1);
    } else {
      this.ChangeDate(-1);
    }
  }

  // On click Next or Privious
  OnClichChangeDateDechet(props) {
    if (props === "Next") {
      this.ChangeDateDechet(1);
    } else {
      this.ChangeDateDechet(-1);
    }
  }

  // Method to change date
  OnClickActionDechet(props, payload) {
    let datedebut;
    switch (props) {
      case "Annee":
        datedebut = new Date(this.DateNow.getFullYear(), 0, 1, 0, 0, 0);
        this.setState({
          actionDechet: "Annee",
          DateDebutDechet: datedebut,
          TextDechet: `Année ${datedebut.getFullYear()}`,
          DisableButtonDechet: true,
        });
        break;
      case "Mois":
        datedebut = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          1,
          0,
          0,
          0
        );
        this.setState({
          actionDechet: "Mois",
          DateDebutDechet: datedebut,
          TextDechet: `${month_name(datedebut)} ${datedebut.getFullYear()}`,
          DisableButtonDechet: true,
        });
        break;
      case "Semaine":
        datedebut = StartOfWeek(
          new Date(
            this.DateNow.getFullYear(),
            this.DateNow.getMonth(),
            this.DateNow.getDate()
          ),
          0,
          0,
          0
        );
        let datefin = new Date(
          datedebut.getFullYear(),
          datedebut.getMonth(),
          datedebut.getDate() + 6,
          0,
          0,
          0
        );
        this.setState({
          actionDechet: "Semaine",
          DateDebutDechet: datedebut,
          DateFinDechet: datefin,
          TextDechet: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} au ${datefin.getDate()} ${month_name(datefin)}`,
          DisableButtonDechet: true,
        });
        break;

      default:
        break;
    }
  }

  ChangeDateDechet(props) {
    let datedebut;
    switch (this.state.actionDechet) {
      case "Annee":
        datedebut = new Date(
          this.state.DateDebutDechet.getFullYear() + props,
          0,
          1
        );
        this.setState({
          DateDebutDechet: datedebut,
          TextDechet: `Année ${datedebut.getFullYear()}`,
          DisableButtonDechet:
            datedebut.getFullYear() === this.DateNow.getFullYear()
              ? true
              : false,
        });
        break;
      case "Mois":
        datedebut = new Date(
          this.state.DateDebutDechet.getFullYear(),
          this.state.DateDebutDechet.getMonth() + props,
          1
        );
        this.setState({
          DateDebutDechet: datedebut,
          TextDechet: `${month_name(datedebut)} ${datedebut.getFullYear()}`,
          DisableButtonDechet:
            datedebut.getFullYear() === this.DateNow.getFullYear() &&
            datedebut.getMonth() === this.DateNow.getMonth()
              ? true
              : false,
        });
        break;
      case "Semaine":
        datedebut = new Date(
          this.state.DateDebutDechet.getFullYear(),
          this.state.DateDebutDechet.getMonth(),
          this.state.DateDebutDechet.getDate() + props * 7
        );
        let datefin = new Date(
          this.state.DateFinDechet.getFullYear(),
          this.state.DateFinDechet.getMonth(),
          this.state.DateFinDechet.getDate() + props * 7
        );
        let currentFistDayofWeek = StartOfWeek(
          new Date(
            this.DateNow.getFullYear(),
            this.DateNow.getMonth(),
            this.DateNow.getDate()
          ),
          0,
          0,
          0
        );
        let currentLastDayofWeek = new Date(
          currentFistDayofWeek.getFullYear(),
          currentFistDayofWeek.getMonth(),
          currentFistDayofWeek.getDate() + 6,
          0,
          0,
          0
        );
        this.setState({
          DateDebutDechet: datedebut,
          DateFinDechet: datefin,
          TextDechet: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} au ${datefin.getDate()} ${month_name(datefin)}`,
          DisableButtonDechet:
            datedebut.getTime() === currentFistDayofWeek.getTime() &&
            datefin.getTime() === currentLastDayofWeek.getTime()
              ? true
              : false,
        });
        break;

      default:
        break;
    }
  }

  // Method to change date
  ChangeDate(props) {
    let datedebut, datefin;
    switch (this.state.action) {
      case "Annee":
        datedebut = new Date(this.state.DateDebut.getFullYear() + props, 0, 1);
        datefin = new Date(this.state.DateFin.getFullYear() + props, 0, 1);
        this.setState({
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `De ${datedebut.getFullYear()} à ${datefin.getFullYear()} `,
          DisableButton:
            datefin.getFullYear() === this.DateNow.getFullYear() ? true : false,
        });
        break;
      case "Mois":
        datedebut = new Date(
          this.state.DateDebut.getFullYear(),
          this.state.DateDebut.getMonth() + props,
          1
        );
        datefin = new Date(
          this.state.DateFin.getFullYear(),
          this.state.DateFin.getMonth() + props,
          1
        );
        this.setState({
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `De ${month_name(
            datedebut
          )} ${datedebut.getFullYear()} à ${month_name(
            datefin
          )} ${datefin.getFullYear()}`,
          DisableButton:
            datefin.getFullYear() === this.DateNow.getFullYear() &&
            datefin.getMonth() === this.DateNow.getMonth()
              ? true
              : false,
        });
        break;
      case "Semaine":
        datedebut = new Date(
          this.state.DateDebut.getFullYear(),
          this.state.DateDebut.getMonth(),
          this.state.DateDebut.getDate() + props * 7
        );
        datefin = new Date(
          this.state.DateFin.getFullYear(),
          this.state.DateFin.getMonth(),
          this.state.DateFin.getDate() + props * 7
        );
        this.setState({
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} au ${datefin.getDate()} ${month_name(datefin)}`,
          DisableButton:
            datefin.getTime() === this.DateNow.getTime() ? true : false,
        });
        break;
      case "Jour":
        datedebut = new Date(
          this.state.DateDebut.getFullYear(),
          this.state.DateDebut.getMonth(),
          this.state.DateDebut.getDate() + props
        );
        datefin = new Date(
          this.state.DateFin.getFullYear(),
          this.state.DateFin.getMonth(),
          this.state.DateFin.getDate() + props
        );
        this.setState({
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} au ${datefin.getDate()} ${month_name(datefin)}`,
          DisableButton:
            datefin.getTime() === this.DateNow.getTime() ? true : false,
        });
        break;
      case "Heure":
        datedebut = new Date(
          this.state.DateDebut.getFullYear(),
          this.state.DateDebut.getMonth(),
          this.state.DateDebut.getDate(),
          this.state.DateDebut.getHours() + props,
          0,
          0
        );
        datefin = new Date(
          this.state.DateFin.getFullYear(),
          this.state.DateFin.getMonth(),
          this.state.DateFin.getDate(),
          this.state.DateFin.getHours() + props,
          0,
          0
        );
        this.setState({
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} ${datedebut.getHours()}h au ${datefin.getDate()} ${month_name(
            datefin
          )} ${datefin.getHours()}h`,
          DisableButton:
            datefin.getTime() === this.DateNow.getTime() ? true : false,
        });

        break;

      default:
        break;
    }
  }

  // On click Buttons Annee - Mois - Semaine - Jour - Heure
  OnClickAction(props, payload) {
    let datedebut, datefin;

    switch (props) {
      case "Jour":
        datedebut = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          this.DateNow.getDate() - 14,
          0,
          0,
          0
        );
        datefin = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          this.DateNow.getDate(),
          0,
          0,
          0
        );
        this.setState({
          action: "Jour",
          DateDebut: datedebut,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} au ${datefin.getDate()} ${month_name(datefin)}`,
          DisableButton: true,
          CustomDate: false,
          DateFin: datefin,
        });
        break;
      case "Heure":
        datedebut = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          this.DateNow.getDate() - 1,
          0,
          0,
          0
        );
        datefin = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          this.DateNow.getDate(),
          0,
          0,
          0
        );
        this.setState({
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} ${datedebut.getHours()}h au ${datefin.getDate()} ${month_name(
            datefin
          )} ${datefin.getHours()}h`,
          DisableButton: true,
          CustomDate: false,
          action: "Heure",
          DateDebut: datedebut,
          DateFin: datefin,
        });
        break;

      case "Annee":
        datedebut = new Date(this.DateNow.getFullYear() - 4, 0, 1, 0, 0, 0);
        datefin = new Date(this.DateNow.getFullYear(), 0, 1, 0, 0, 0);
        this.setState({
          action: "Annee",
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `De ${datedebut.getFullYear()} à ${datefin.getFullYear()} `,
          DisableButton: true,
          CustomDate: false,
        });
        break;
      case "Mois":
        datedebut = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth() - 11,
          1,
          0,
          0,
          0
        );
        datefin = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          1,
          0,
          0,
          0
        );
        this.setState({
          action: "Mois",
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `De ${month_name(
            datedebut
          )} ${datedebut.getFullYear()} à ${month_name(
            datefin
          )} ${datefin.getFullYear()}`,
          DisableButton: true,
          CustomDate: false,
        });
        break;

      case "Custom":
        const { start, end, time } = payload;
        datedebut = start;
        datefin = end;
        this.setState({
          action: time,
          DisableButton: true,
          DateDebut: set(datedebut, { hours: 0, minutes: 0, seconds: 0 }),
          TextInterval: `${format(datedebut, "d MMMM yyy")}  au ${format(
            datefin,
            "d MMMM yyy"
          )}`,
          DateFin: set(datefin, { hours: 0, minutes: 0, seconds: 0 }),
          CustomDate: true,
        });
        break;
      case "Semaine":
        datedebut = StartOfWeek(
          new Date(
            this.DateNow.getFullYear(),
            this.DateNow.getMonth() - 3,
            this.DateNow.getDate()
          ),
          0,
          0,
          0
        );
        datefin = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          this.DateNow.getDate(),
          0,
          0,
          0
        );
        this.setState({
          action: "Semaine",
          DisableButton: true,
          DateDebut: datedebut,
          DateFin: datefin,
          CustomDate: false,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} au ${datefin.getDate()} ${month_name(datefin)}`,
        });
        break;

      default:
        break;
    }
  }

  render() {
    return (
      <Container fluid>
        <div style={{ marginTop: "20px" }} className="animated fadeIn">
          {!this.props.siteInformation.siteId && (
            <div className="Spinner">
              <Spinner style={{ color: "#FFCD00" }} />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              marginBottom: "15px",
              borderBottom: "1px solid #B4B6B5",
            }}
          >
            <label style={{ fontSize: "18px", color: "#B4B6B5" }}>
              SORTIE STEP
            </label>
          </div>
          {this.props.siteInformation.siteId && (
            <ButtonPeriodBar
              CustomDate={this.state.CustomDate}
              DateDebut={this.state.DateDebut}
              DateFin={this.state.DateFin}
              OnClickAction={this.OnClickAction}
              action={this.state.action}
            />
          )}
          {this.props.siteInformation.siteId && (
            <React.Fragment>
              <div className="d-flex">
                <DateRangePicker
                  textInterval={this.state.TextInterval}
                  start={this.state.DateDebut}
                  end={this.state.DateFin}
                  onSubmitDate={({ start, end }) => {
                    this.OnClickAction("Custom", {
                      start,
                      end,
                      time: this.state.action,
                    });
                  }}
                  onCancelDate={() => {
                    this.OnClickAction("Semaine");
                  }}
                  className="mt-2 mb-2 flex-grow-1"
                />
                {/* <label style={{ fontSize: '20px', marginTop: '20px', color: '#FFCD00' }} ><strong>{this.state.TextInterval}</strong></label> */}
                {this.state.CustomDate === false && (
                  <ButtonToolbar style={{ marginLeft: "10px" }}>
                    <Button
                      id="previousdate"
                      size="sm"
                      className="fa fa-chevron-left fa-lg mt-2 button-icon left-button"
                      onClick={() => this.OnClichChangeDate("Previous")}
                    ></Button>
                    <Button
                      id="previousnext"
                      size="sm"
                      disabled={this.state.DisableButton}
                      className="fa fa-chevron-right fa-lg mt-2 button-icon right-button"
                      onClick={() => this.OnClichChangeDate("Next")}
                    ></Button>
                  </ButtonToolbar>
                )}
              </div>

              <CardView
                DateDebut={this.state.DateDebut}
                DateFin={this.state.DateFin}
                action={this.state.action}
                volumeWater={{
                  Titre: "volume d’effluents traités",
                  IndicateurSuiviId: "NE157",
                  Moyen: "1",
                }}
                ph={{ IndicateurSuiviId: "NE285" }}
                waterTemperature={{ IndicateurSuiviId: "NE282" }}
                waterQuality={{
                  Titre: "qualité de l’effluent traité",
                  GrandeurEvalueeId: "4",
                  EtapeTraitementId: "2",
                }}
              />
              <ButtonToolbar
                style={{ marginBottom: "10px", justifyContent: "left" }}
              >
                <Button
                  outline
                  className={
                    this.state.actionDechet === "Annee"
                      ? "btn-pill btn-selected"
                      : "btn-pill"
                  }
                  onClick={() => this.OnClickActionDechet("Annee")}
                >
                  Année
                </Button>
                <Button
                  outline
                  className={
                    this.state.actionDechet === "Mois"
                      ? "btn-pill btn-selected"
                      : "btn-pill"
                  }
                  onClick={() => this.OnClickActionDechet("Mois")}
                >
                  Mois
                </Button>
                <Button
                  outline
                  className={
                    this.state.actionDechet === "Semaine"
                      ? "btn-pill btn-selected"
                      : "btn-pill"
                  }
                  onClick={() => this.OnClickActionDechet("Semaine")}
                >
                  Semaine
                </Button>
              </ButtonToolbar>
              <div style={{ display: "-webkit-inline-box" }}>
                <label
                  style={{
                    fontSize: "18px",
                    marginTop: "20px",
                    color: "#FFCD00",
                  }}
                >
                  <strong>{this.state.TextDechet}</strong>
                </label>
                <ButtonToolbar style={{ marginLeft: "10px" }}>
                  <Button
                    size="sm"
                    className="fa fa-chevron-left fa-lg mt-4 button-icon left-button"
                    onClick={() => this.OnClichChangeDateDechet("Previous")}
                  ></Button>
                  <Button
                    size="sm"
                    disabled={this.state.DisableButtonDechet}
                    className="fa fa-chevron-right fa-lg mt-4 button-icon right-button"
                    onClick={() => this.OnClichChangeDateDechet("Next")}
                  ></Button>
                </ButtonToolbar>
              </div>
              <Card>
                <CardBody style={{ width: "100%" }}>
                  <WasteAndUnderProduct
                    DateDebut={this.state.DateDebutDechet}
                    Action={this.state.actionDechet}
                  />
                </CardBody>
              </Card>
            </React.Fragment>
          )}
        </div>
      </Container>
    );
  }
}
export default connect((state) => state)(StepOut);
