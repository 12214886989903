import { ReactKeycloakProvider } from "@react-keycloak/web";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { getAuthProvider } from "../../authentication/authProvider";
import { AuthClientTokens } from "@react-keycloak/core";
import {
  logout,
  refreshToken,
} from "../../stores/actions/authentificationActions";

export const KeyCloakProvider = (props: { children: JSX.Element }) => {
  const dispatch = useDispatch();

  const handleOnEvent = async (event: any, error: any) => {
    if (event === "onTokenExpired") {
    }
  };

  const handleOnTokens = async (tokens: AuthClientTokens) => {
    if (tokens.token && tokens.refreshToken)
      dispatch(refreshToken(tokens.token, tokens.refreshToken));
    else dispatch(logout());
  };
  return (
    <ReactKeycloakProvider
      authClient={getAuthProvider()}
      onEvent={handleOnEvent}
      onTokens={handleOnTokens}
    >
      {props.children}
    </ReactKeycloakProvider>
  );
};

export default connect((state) => state)(KeyCloakProvider);
