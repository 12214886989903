import React, { useMemo, useState } from "react";
import { useTable } from "react-table";
import { format, parse, isBefore } from "date-fns";
import { fr } from "date-fns/locale";
import "./collect.scss";
import { Collapse } from "reactstrap";

//Composant pour afficher le tableau

export function Cell(props = {}) {
  const { value = {}, column = {}, row = { values: { indc: "" } } } = props;
  const indc = row.values.indc;
  const day = parse(column.time, "P", new Date(), {
    locale: fr,
    weekStartsOn: 1,
  });
  const isBeforeToday = isBefore(day, new Date());

  let val = "";

  if (value && value.valeur && value.valeurLabo) {
    val = { v: "(routine)", vl: "(agréée)" };
  }

  if (value && value.valeur && value.valeurSiccite) {
    val = { v: "(quantité)", vs: "(siccité)" };
  }
  return (
    value && (
      <div
        className={`cellx d-flex h-100 justify-content-around  align-items-center  ${
          isBeforeToday ? "" : "disabled"
        }  ${
          isBeforeToday &&
          ((value && value.valeur) ||
            (value && value.valeurLabo) ||
            (value && value.valeurSiccite))
            ? "active"
            : ""
        }`}
      >
        {isBeforeToday && value && value.valeur && (
          <div className="text-center">
            <div>{`${value.valeur} ${
              indc.unite === null ? "" : indc.unite
            }`}</div>{" "}
            <div>
              <small className="ind">{val.v}</small>{" "}
            </div>
          </div>
        )}
        {isBeforeToday && value && value.valeurLabo && (
          <div className="text-center">
            <div>
              {" "}
              {`${value.valeurLabo} ${
                indc.unite === null ? "" : indc.unite
              }`}{" "}
            </div>{" "}
            <div>
              {" "}
              <small className="ind">{val.vl}</small>
            </div>
          </div>
        )}
        {isBeforeToday && value && value.valeurSiccite && (
          <div className="text-center">
            <div>
              {" "}
              {`${value.valeurSiccite} ${
                indc.uniteSiccite === null ? "" : indc.uniteSiccite
              }`}
            </div>
            <div>
              <small className="ind">{val.vs}</small>
            </div>
          </div>
        )}
      </div>
    )
  );
}

export function CellIndicator({ value = { libelleFr: "" } }) {
  return <div className="p-3">{value.libelleFr}</div>;
}

export const defaultPropGetter = () => ({});

export function Table({
  columns = [],
  data = [],
  getCellProps = defaultPropGetter,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      <table className="tableau table-text" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="font-sm table-text" {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="font-sm table-text"
                      {...cell.getCellProps([getCellProps(cell)])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default function TableStep({
  nameStep = "",
  data = [],
  onClickCell = () => {},
  week = [],
}) {
  const [isOpen] = useState(true);

  //dans le cas ou on veut rajoute la ligne supplémentaire
  const columns = () => [
    {
      accessor: `indc`,
      Header: "Indicateur",
      Cell: CellIndicator,
    },
    ...week.map((day, index) => ({
      Cell: Cell,
      accessor: `id${index}`,
      Header: format(day, "eee d MMM", { locale: fr }),
      time: format(day, "P", { locale: fr }),
    })),
  ];

  return (
    <div>
      <span className="font-lg" onClick={() => {}}>
        {nameStep}
      </span>
      <Collapse isOpen={isOpen}>
        <Table
          getCellProps={(cellInfo) => ({
            onClick: () => {
              const currentDay = parse(
                cellInfo.column.time,
                "dd/MM/yyyy",
                new Date(),
                { locale: fr }
              );
              const isIndiCell = cellInfo.column.id === "indc";
              if (isBefore(currentDay, new Date()) || isIndiCell) {
                onClickCell({
                  cellInfo: cellInfo.value,
                  rowInfo: cellInfo.row.values.indc,
                  date: cellInfo.column.time,
                  isIndc: cellInfo.column.id === "indc",
                });
              }
            },
          })}
          columns={useMemo(columns, [])}
          data={data}
        />
      </Collapse>
    </div>
  );
}
