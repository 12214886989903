import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import { Spinner } from "reactstrap";
import DateRangePicker from "../History/Datepicker";
import * as documentService from "../../../services/documentServices";
import fileDownload from "js-file-download";
import "../history.scss";

import { format } from "date-fns";
import { fr } from "date-fns/locale";

export default function HistoryExport({
  onClose,
  openModal,
  onSubmit,
  authentification,
  siteInformation = "f4k351t3",
}) {
  const [formatedDate, setFormatedDate] = useState(null);
  const [DateDebut, setDateDebut] = useState(null);
  const [DateFin, setDateFin] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [EtapeTraitementId, setEtapeTraitementId] = useState(null);
  const [IndicateursSuivisIds, setIndcateursSuivisIds] = useState([]);
  const [releve, setReleve] = useState(false);
  const [releveDisabled, setReleveDisabled] = useState(false);
  const [dataStep, setdataStep] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [exportAll, setExportAll] = useState(false);
  const [loadingModal, setloadingModal] = useState(false);
  const [errorDown, setErrorDown] = useState("");

  const onSubmitDate = (data) => {
    setFormatedDate({
      label: `${format(data.left, "dd/MM/yyy", { locale: fr })}-${format(
        data.right,
        "dd/MM/yyy",
        { locale: fr }
      )}`,
      value: "",
    });
    setDateDebut(data.left);
    setDateFin(data.right);
    setOpenMenu(false);
  };

  const onCancelDate = () => {
    setOpenMenu(false);
  };

  const onSubmitStep = (data) => {
    if (data !== null) {
      setEtapeTraitementId(data.value);
    } else {
      setEtapeTraitementId(null);
    }
  };

  const onSubmitIndicator = (data, triggeredAction) => {
    if (triggeredAction && triggeredAction.action === "remove-value") {
      setIndcateursSuivisIds(
        IndicateursSuivisIds.filter((x) => {
          return x.value !== triggeredAction.removedValue.value;
        })
      );
      return;
    }

    if (data.length < 9) {
      setIndcateursSuivisIds(data);
    }
  };

  useEffect(() => {
    if (siteInformation.siteId !== null) {
      documentService
        .loadEtapeTraitement(authentification, {
          siteId: siteInformation.siteId,
        })
        .then(async (res) => {
          if (`${res.status}`.startsWith("20")) {
            const data = await res.json();
            setdataStep(
              data.map((item) => ({ label: item.libelleFr, value: item.id }))
            );
          }
        })
        .catch((error) => {
          throw new Error({ message: error });
        });
    }
  }, [siteInformation.siteId, authentification]);

  useEffect(() => {
    if (IndicateursSuivisIds.length !== 1) {
      setReleveDisabled(true);
      setReleve(false);
      return;
    }

    if (
      IndicateursSuivisIds.map((x) => x.typeDonneeId).includes("_INDEX") ||
      IndicateursSuivisIds.map((x) => x.modeRenseignementId).includes("_AUTO")
    ) {
      setReleveDisabled(false);
      return;
    }

    setReleveDisabled(true);
    setReleve(false);
  }, [setReleveDisabled, IndicateursSuivisIds]);

  useEffect(() => {
    if (siteInformation.siteId !== null) {
      documentService
        .GetIndicateursSuivisFiltre(authentification, {
          siteId: siteInformation.siteId,
        })
        .then(async (res) => {
          if (`${res.status}`.startsWith("20")) {
            const data = await res.json();

            setDataFilter(
              data.map((item) => ({
                label: item.libelleFr,
                value: item.id,
                modeRenseignementId: item.modeRenseignementId,
                typeDonneeId: item.typeDonneeId,
              }))
            );
          }
        })
        .catch((error) => {
          throw new Error({ message: error });
        });
    }
  }, [siteInformation.siteId, authentification]);

  const onSubmitFilter = () => {
    setErrorDown("");
    documentService
      .DownloadHistorique(authentification, {
        DateDebut:
          DateDebut === null
            ? null
            : format(DateDebut, "d/MM/yyy", { locale: fr }),
        DateFin:
          DateFin === null ? null : format(DateFin, "d/MM/yyy", { locale: fr }),
        EtapeTraitementId,
        IndicateursSuivisIds,
        Releve: releve,
        siteId: siteInformation.siteId,
      })
      .then(async (response) => {
        setloadingModal(true);
        fileDownload(await response.blob(), "Historiques.xlsx");
        reset();
        setloadingModal(false);
        onSubmit();
      })
      .catch((error) => {
        setErrorDown(error);
      });
  };

  const CloseButton = (
    <div>
      <button onClick={onClose} className="btn close-btn"></button>
    </div>
  );

  const selRef = useRef();

  const reset = () => {
    setExportAll(false);
  };

  return (
    <Modal isOpen={openModal} size="lg" unmountOnClose={true}>
      <ModalHeader
        className="text-primary pl-5"
        close={CloseButton}
      ></ModalHeader>
      <ModalBody className="p-5">
        <div className="d-flex flex-column mb-2 mx-4 exportHistory">
          <div className="mb-4 d-flex justify-content-between">
            Précisez vos critères de téléchargement des données{" "}
            <div className="pr-4">
              {loadingModal && <Spinner style={{ color: "#FFCD00" }} />}
            </div>
          </div>
          <Select
            ref={selRef}
            value={formatedDate}
            className="flex-grow-1 mb-2 w-50"
            isSearchable={false}
            onChange={(selectedOption, triggeredAction) => {
              if (triggeredAction.action === "clear") {
                setFormatedDate(null);
                setDateDebut(null);
                setDateFin(null);
              }
            }}
            classNamePrefix="selectPicker"
            menuIsOpen={openMenu}
            openMenuOnClick={false}
            onMenuOpen={() => {
              setOpenMenu(true);
            }}
            placeholder="Date"
            isClearable={true}
            closeMenuOnSelect={false}
            closeMenuOnScroll={false}
            options={[
              {
                label: (
                  <DateRangePicker
                    onSubmitDate={onSubmitDate}
                    onCancelDate={onCancelDate}
                  />
                ),
              },
            ]}
          />
          <Select
            options={dataStep}
            isClearable={true}
            onChange={onSubmitStep}
            placeholder="Etape"
            className="flex-grow-1 flex-shrink-0 mb-2 w-50"
          />
          <Select
            isMulti
            onChange={onSubmitIndicator}
            value={IndicateursSuivisIds}
            placeholder="Indicateurs"
            options={dataFilter}
            className="flex-grow-1 flex-shrink-0 mb-2 w-50"
          />
          <label
            className={`flex-shrink-0 border px-3 py-2  chkbox w-50 ${
              exportAll || releveDisabled ? "disabled" : ""
            }`}
          >
            <input
              type="checkbox"
              disabled={releveDisabled}
              checked={releve}
              onChange={() => {
                setReleve(!releve);
              }}
            />{" "}
            Relevé{" "}
          </label>
        </div>
        <div className="d-flex justify-content-center">
          {errorDown && (
            <span className="error-field error-text">{errorDown}</span>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <button
          disabled={loadingModal}
          className="btn btn-outline-primary btn-lg mb-3"
          onClick={onClose}
        >
          Annuler
        </button>
        <button
          type="button"
          onClick={onSubmitFilter}
          disabled={loadingModal}
          className="btn btn-primary btn-lg mb-3"
        >
          Valider
        </button>
      </ModalFooter>
    </Modal>
  );
}
