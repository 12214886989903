import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  lastDayOfMonth,
  isExists,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  format,
  subYears,
  set,
  isBefore,
} from "date-fns";
import { fr } from "date-fns/locale";
import { useForm, Controller } from "react-hook-form";
import "../StepIn/datepicker.scss";

export function DateRangePicker({
  onSubmitDate,
  onCancelDate,
  noRange = false,
}) {
  const { handleSubmit, errors, control, setError, setValue } = useForm();

  const currentDay = new Date();

  const [currentLeftDay, setCurrentLeftDay] = useState(new Date());
  const [currentRightDay, setCurrentRightDay] = useState(new Date());

  const dayInterval = eachDayOfInterval({
    start: startOfMonth(currentLeftDay),
    end: endOfMonth(currentLeftDay),
  });
  const monthInterval = eachMonthOfInterval({
    start: startOfYear(currentDay),
    end: endOfYear(currentDay),
  });
  const yearInterval = eachYearOfInterval({
    start: subYears(currentDay, 5),
    end: currentDay,
  });

  const dayRightInterval = eachDayOfInterval({
    start: startOfMonth(currentRightDay),
    end: endOfMonth(currentRightDay),
  });
  const monthRightInterval = eachMonthOfInterval({
    start: startOfYear(currentDay),
    end: endOfYear(currentDay),
  });
  const yearRightInterval = eachYearOfInterval({
    start: subYears(currentDay, 5),
    end: currentDay,
  });

  useEffect(() => {
    if (
      isExists(
        currentLeftDay.getFullYear(),
        currentLeftDay.getMonth(),
        currentLeftDay.getDate()
      )
    ) {
      setValue("leftDay", {
        label: format(currentLeftDay, "d", { locale: fr }),
        value: currentLeftDay.getDate(),
      });
      setValue("leftMonth", {
        label: format(currentLeftDay, "MMM", { locale: fr }),
        value: currentLeftDay.getMonth(),
      });
      setValue("leftYear", {
        label: format(currentLeftDay, "yyy", { locale: fr }),
        value: currentLeftDay.getFullYear(),
      });
    } else {
      setValue("leftMonth", {
        label: format(lastDayOfMonth(currentLeftDay), "MMM"),
        value: currentLeftDay.getMonth(),
      });
    }

    if (
      isExists(
        currentRightDay.getFullYear(),
        currentRightDay.getMonth(),
        currentRightDay.getDate()
      )
    ) {
      setValue("rightDay", {
        label: format(currentRightDay, "d", { locale: fr }),
        value: currentRightDay.getDate(),
      });
      setValue("rightMonth", {
        label: format(currentRightDay, "MMM", { locale: fr }),
        value: currentRightDay.getMonth(),
      });
      setValue("rightYear", {
        label: format(currentRightDay, "yyy", { locale: fr }),
        value: currentRightDay.getFullYear(),
      });
    } else {
      setValue("rightMonth", {
        label: format(lastDayOfMonth(currentRightDay), "MMM"),
        value: currentRightDay.getMonth(),
      });
    }
  }, [setValue, currentLeftDay, currentRightDay]);

  return (
    <>
      <form
        id="datepicker"
        data-testid="form"
        onSubmit={handleSubmit(() => {
          //dans le cas ou on a un seul DatePicker
          if (noRange) {
            onSubmitDate({ left: currentLeftDay });
            return;
          }

          //dans le cas si on a 2 dates pciker on vérifie si les dates sont valides
          if (isBefore(currentRightDay, currentLeftDay)) {
            setError(
              "form",
              "inval",
              "la date de début ne peut être inférieure à la date de fin"
            );
            return;
          }

          onSubmitDate({ left: currentLeftDay, right: currentRightDay });
        })}
        className="d-flex align-items-center w-100"
      >
        <Controller
          as={Select}
          name="leftDay"
          options={dayInterval.map((day) => ({
            label: format(day, "d", { locale: fr }),
            value: format(day, "d"),
          }))}
          className="flex-grow-1 mr-2"
          control={control}
          onChange={(data) => {
            setCurrentLeftDay(set(currentLeftDay, { date: data[0].value }));
          }}
        />
        <Controller
          as={Select}
          options={monthInterval.map((day) => ({
            label: format(day, "MMM", { locale: fr }),
            value: day.getMonth(),
          }))}
          className="flex-grow-1 mx-2"
          name="leftMonth"
          control={control}
          onChange={(data) => {
            setCurrentLeftDay(set(currentLeftDay, { month: data[0].value }));
          }}
        />
        <Controller
          as={Select}
          options={yearInterval.map((day) => ({
            label: format(day, "yyy", { locale: fr }),
            value: format(day, "y"),
          }))}
          className="flex-grow-1 mx-2"
          name="leftYear"
          control={control}
          onChange={(data) => {
            setCurrentLeftDay(set(currentLeftDay, { year: data[0].value }));
          }}
        />
        {noRange === false && (
          <>
            <span>Au</span>
            <Controller
              as={Select}
              options={dayRightInterval.map((day) => ({
                label: format(day, "d", { locale: fr }),
                value: format(day, "d"),
              }))}
              className="flex-grow-1 mx-2"
              name="rightDay"
              control={control}
              onChange={(data) => {
                setCurrentRightDay(
                  set(currentRightDay, { date: data[0].value })
                );
              }}
            />

            <Controller
              as={Select}
              options={monthRightInterval.map((day) => ({
                label: format(day, "MMM", { locale: fr }),
                value: day.getMonth(),
              }))}
              className="flex-grow-1 mx-2"
              name="rightMonth"
              control={control}
              onChange={(data) => {
                setCurrentRightDay(
                  set(currentRightDay, { month: data[0].value })
                );
              }}
            />
            <Controller
              as={Select}
              options={yearRightInterval.map((day) => ({
                label: format(day, "yyy", { locale: fr }),
                value: format(day, "y"),
              }))}
              className="flex-grow-1 ml-2"
              name="rightYear"
              control={control}
              onChange={(data) => {
                setCurrentRightDay(
                  set(currentRightDay, { year: data[0].value })
                );
              }}
            />
          </>
        )}
      </form>
      <div className="d-flex justify-content-center mt-2 ">
        <span className="error-field">
          {errors && errors.form && errors.form.message}
        </span>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-primary mx-2 mt-2"
          type="button"
          onClick={onCancelDate}
          form="datepicker"
        >
          Annuler
        </button>
        <button
          className="btn btn-primary mx-2 mt-2"
          type="submit"
          form="datepicker"
        >
          OK
        </button>
      </div>
    </>
  );
}

function formatDate({ start = new Date(), end = new Date() }) {
  return {
    label: (
      <>
        <label style={{ fontSize: "18px", color: "#FFCD00" }}>
          <strong>
            {" "}
            <i className="fa fa-calendar pr-1"></i>{" "}
            {`Du ${format(start, "dd MMMM yyyy", { locale: fr })} au ${format(
              end,
              "dd MMMM yyyy",
              { locale: fr }
            )}`}{" "}
          </strong>
        </label>
      </>
    ),
    value: null,
  };
}

export default function DatePicker({
  onSubmitDate,
  onCancelDate,
  className,
  textInterval = "",
  noRange = false,
}) {
  const [formatedDate, setFormatedDate] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const _onSubmitDate = (data) => {
    setFormatedDate(formatDate({ start: data.left, end: data.right }));
    onSubmitDate({ start: data.left, end: data.right });
    setOpenMenu(false);
  };

  const _onCancelDate = () => {
    setOpenMenu(false);
  };

  return (
    <div id="RangeDatePicker" className={className}>
      <Select
        value={formatedDate}
        className=""
        isSearchable={false}
        onChange={(selectedOption, triggeredAction) => {
          if (triggeredAction.action === "clear") {
            setFormatedDate(null);
            //setDateDebut(sub(new Date(), { months: 3 }));
            //setDateFin(new Date());
            onCancelDate();
          }
        }}
        classNamePrefix="selectPicker"
        menuIsOpen={openMenu}
        openMenuOnClick={true}
        onMenuOpen={() => {
          setOpenMenu(true);
        }}
        placeholder={
          <label style={{ fontSize: "18px", color: "#FFCD00" }}>
            <strong>
              <i className="fa fa-calendar pr-1"></i>
              {textInterval}
            </strong>
          </label>
        }
        isClearable={true}
        closeMenuOnSelect={false}
        closeMenuOnScroll={false}
        options={[
          {
            label: (
              <DateRangePicker
                onSubmitDate={_onSubmitDate}
                onCancelDate={_onCancelDate}
                noRange={noRange}
              />
            ),
          },
        ]}
      />{" "}
    </div>
  );
}
