import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function InfoCell({
  cellModal,
  cell,
  toggleCellModal,
  onCloseAll,
  toggleBin,
  toggleEdit,
}) {
  const CloseButton = (
    <div className="d-flex align-items-center">
      <button onClick={toggleEdit} className="btn edit-btn mr-3"></button>
      <button onClick={toggleBin} className="btn bin-btn mr-4"></button>
      <button onClick={onCloseAll} className="btn close-btn"></button>
    </div>
  );

  return (
    <Modal isOpen={cellModal}>
      <ModalHeader className="" close={CloseButton}>
        <label className="firstUpper mt-1 pl-1  h4 d-block">
          {cell && cell.rowInfo && cell.rowInfo.libelleFr}
        </label>
        <label className="d-block pl-1 h6">{cell && cell.date}</label>
      </ModalHeader>
      <ModalBody className="p-5">
        <div className="display-label">
          {cell && cell.cellInfo && cell.cellInfo.valeur && (
            <div>
              {" "}
              <span className="text-primary font-weight-normal">
                Valeur:
              </span>{" "}
              {`${cell && cell.cellInfo && cell.cellInfo.valeur} ${
                cell && cell.rowInfo && cell.rowInfo.unite === null
                  ? ""
                  : cell.rowInfo.unite
              }`}{" "}
            </div>
          )}
          {cell && cell.cellInfo && cell.cellInfo.valeurLabo && (
            <div>
              <span className="text-primary font-weight-normal">
                Valeur analyse agréée:
              </span>{" "}
              {`${cell && cell.cellInfo && cell.cellInfo.valeurLabo} ${
                cell && cell.rowInfo && cell.rowInfo.unite === null
                  ? ""
                  : cell.rowInfo.unite
              }`}{" "}
            </div>
          )}
          {cell && cell.cellInfo && cell.cellInfo.valeurSiccite && (
            <div>
              <span className="text-primary font-weight-normal">
                Valeur de siccite:
              </span>{" "}
              {`${cell && cell.cellInfo && cell.cellInfo.valeurSiccite} ${
                cell && cell.rowInfo && cell.rowInfo.uniteSiccite === null
                  ? ""
                  : cell.rowInfo.uniteSiccite
              }`}{" "}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <button
          className="btn btn-outline-primary btn-lg mb-3"
          onClick={async () => {
            toggleCellModal();
          }}
        >
          Valider
        </button>
      </ModalFooter>
    </Modal>
  );
}
