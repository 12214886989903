//Librairies
import React, { useState } from "react";
import { Container } from "reactstrap";
import { Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import fileDownload from "js-file-download";
//Assets
import "./document.scss";
import "./template.scss";
import chevron from "../../assets/doc-chevron.svg";
import bin from "../../assets/doc-bin.svg";
import edit from "../../assets/doc-edit.svg";
import share from "../../assets/doc-share.svg";
import download from "../../assets/doc-download.svg";
import update from "../../assets/doc-update.svg";
//Applications
import ModalEdit from "./Modals/edit";
import ModalDelete from "./Modals/delete";
import Modalimport from "./Modals/import";
import * as DocumentServices from "../../services/documentServices";
import * as Navigation from "../../components/SaurLayout/navigation";
import Table from "./Template/Table";
import TemplateTable from "./Template/TemplateTable";
import { importFile } from "./Document";

/**
 * Charge les données pour le tableau du modèle
 * @param {*} authentification
 * @param {*} param1
 */
export function loadDataDocumentModele(
  authentification,
  { categorieDocId, siteId, pageNumber, pageSize, SortBy, SortOrder }
) {
  return DocumentServices.GetListOfModeleDocumentModele(authentification, {
    CategorieDocId: categorieDocId,
    siteId: siteId,
    PageNumber: pageNumber,
    PageSize: pageSize,
    SortBy,
    SortOrder,
  });
}

/**
 * Charge les données pour les documents générés
 * @param {*} authentification
 * @param {*} param1
 */
export function loadDataDocumentGeneres(
  authentification,
  { categorieDocId, siteId, pageNumber, pageSize, SortBy, SortOrder }
) {
  return DocumentServices.GetListOfModeleDocumentGenere(authentification, {
    CategorieDocId: categorieDocId,
    siteId: siteId,
    PageNumber: pageNumber,
    PageSize: pageSize,
    SortBy,
    SortOrder,
  });
}

function Template({ authentification, siteInformation }) {
  //gestion des modales
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [importTemplateModal, setImportTemplateModal] = useState(false);

  //récupération de la catégorie via l'url
  const pathname = useHistory().location.pathname;
  const history = useHistory();
  const categorieDocId = Navigation.getIdfromPathName(pathname);
  const categorieDocName = Navigation.getNameFromPathName(pathname);
  const GroupDocName = Navigation.getGroupByPathName(pathname);

  //gestion tableau model
  const [dataModel, setDataModel] = useState([]);
  const [loadingModel, setLoadingModel] = React.useState(true);
  const [pageCountModel, setPageCountModel] = React.useState(0);
  const [pageNumberModel, setPageNumberModel] = React.useState(1);
  const [pageSizeModel, setPageSizeModel] = React.useState(5);
  const [sortBy_Temp, setSortBy_Temp] = React.useState(null);
  const [sortOrder_Temp, setOrder_Temp] = React.useState(null);

  const onDataFetchModel = React.useCallback(
    async ({ pageSize, pageIndex, SortBy, SortOrder }) => {
      if (siteInformation.siteId !== null) {
        setLoadingModel(true);
        setPageNumberModel(pageIndex);
        setPageSizeModel(pageSize);
        setSortBy_Temp(SortBy);
        setOrder_Temp(SortOrder);

        Promise.all([
          loadDataDocumentModele(authentification, {
            siteId: siteInformation.siteId,
            categorieDocId: categorieDocId,
            pageNumber: pageIndex,
            pageSize: pageSize,
            SortBy,
            SortOrder,
          }),
          DocumentServices.GetCountOfModeleDocument(authentification, {
            siteId: siteInformation.siteId,
            categorieDocId: categorieDocId,
            typeDocId: "MODELE",
          }),
        ])
          .then(async (reponse) => {
            if (!`${reponse[0].status}`.startsWith("20")) {
              return Promise.reject(
                "erreur serveur reponse chargement document"
              );
            }
            if (!`${reponse[1].status}`.startsWith("20")) {
              return Promise.reject(
                "erreur serveur reponse chargement des pages"
              );
            }
            let data = [];
            let count = 0;
            if (reponse[0].status === 200) {
              data = await reponse[0].json();
              count = await reponse[1].json();
            }
            setLoadingModel(false);
            setPageCountModel(Math.ceil(count / pageSize));
            setDataModel(data);
            return Promise.resolve();
          })

          .catch((error) => {
            return Promise.reject("erreur serveur fetch", error);
          });
      }
    },
    [authentification, categorieDocId, siteInformation]
  );

  const onImportModel = async (data) => {
    try {
      const resultDoc = await DocumentServices.ImportFile(authentification, {
        siteId: siteInformation.siteId,
        CotegorieDocId: categorieDocId,
        TypeDocId: "MODELE",
        LibelleFr: data.LibelleFr,
        File: data.File,
      });

      if (resultDoc.status === 200) {
        onDataFetch({ pageSize: _pageSize, pageIndex: pageNumber }).then(() => {
          setImportTemplateModal(false);
        });
        onDataFetchModel({
          pageSize: pageSizeModel,
          pageIndex: pageNumberModel,
          SortBy: sortBy_Temp,
          SortOrder: sortOrder_Temp,
        });
      }
    } catch (error) {
      alert("Erreur durant l'import du model");
    }
  };

  //gestion tableau document genere
  const [_data, setData] = useState([]);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [_pageSize, setPageSize] = React.useState(20);
  const [sortBy, setSortBy] = React.useState(null);
  const [sortOrder, setOrder] = React.useState(null);

  const onDataFetch = React.useCallback(
    async ({ pageSize, pageIndex, SortBy, SortOrder }) => {
      if (siteInformation.siteId !== null) {
        setLoading(true);
        setPageNumber(pageIndex);
        setPageSize(pageSize);
        setSortBy(SortBy);
        setOrder(SortOrder);

        Promise.all([
          loadDataDocumentGeneres(authentification, {
            siteId: siteInformation.siteId,
            categorieDocId: categorieDocId,
            pageNumber: pageIndex,
            pageSize: pageSize,
            SortBy,
            SortOrder,
          }),
          DocumentServices.GetCountOfModeleDocument(authentification, {
            siteId: siteInformation.siteId,
            categorieDocId: categorieDocId,
            typeDocId: "DOC_MODELE",
          }),
        ])
          .catch(() => {
            return Promise.reject("erreur serveur");
          })
          .then(async (response) => {
            if (!`${response[0].status}`.startsWith("20")) {
              return Promise.reject("erreur serveur");
            }

            if (!`${response[1].status}`.startsWith("20")) {
              return Promise.reject("erreur serveur");
            }

            let data = [];
            let count = 0;
            if (response[0].status === 200) {
              data = await response[0].json();
              count = await response[1].json();
            }

            setPageCount(Math.ceil(count / pageSize));
            setData(data);
            setLoading(false);
            return Promise.resolve();
          });
      }
    },
    [authentification, siteInformation, categorieDocId]
  );

  //gestion de l'import
  const onImport = async (data) => {
    importFile(
      {
        authentification,
        SiteId: siteInformation.siteId,
        categorieDocId: categorieDocId,
        TypeDocId: "DOC_SIMPLE",
        LibelleFr: data.LibelleFr,
        File: data.File,
      },
      {
        onDataFetch: onDataFetch,
        pageSize: _pageSize,
        pageNumber: pageNumber,
        sortBy: sortBy,
        sortOrder: sortOrder,
        setImportModal: setImportModal,
      }
    ).catch((e) => {
      alert(e);
    });
  };

  //gestion de l'import
  const onUpdateImport = (data) => {
    DocumentServices.UpdateModeleDocument(authentification, {
      siteId: siteInformation.siteId,
      LibelleFr: data.LibelleFr,
      modeleDocumentId: row[0].values.id,
    })
      .then(async (res) => {
        if (res.status === 200) {
          onDataFetch({
            pageSize: _pageSize,
            pageIndex: pageNumber,
            SortBy: sortBy,
            SortOrder: sortOrder,
          }).then(() => {
            setEditModal(false);
          });
        }
      })
      .catch(() => {
        throw new Error("erreur serveur");
      });
  };

  //gestion du telechargement
  const onDownload = () => {
    const listModeleDocumentId = row.map((item) => item.values.id);
    DocumentServices.ExportFiles(authentification, {
      siteId: siteInformation.siteId,
      listModeleDocumentId,
    }).then(async (result) => {
      const filename =
        row.length === 1 ? row[0].values.nomFichier : `${categorieDocId}.zip`;
      fileDownload(await result.blob(), filename);
    });
  };

  //gestion de la suppression
  const onDelete = () => {
    const listModeleDocumentId = row.map((item) => item.values.id);

    DocumentServices.deleteModeleDocument(authentification, {
      siteId: siteInformation.siteId,
      listModeleDocumentId: listModeleDocumentId,
    })
      .then(async (res) => {
        if (res.status === 200) {
          onDataFetch({
            pageSize: _pageSize,
            pageIndex: pageNumber,
            SortBy: sortBy,
            SortOrder: sortOrder,
          }).then(() => {
            setDeleteModal(false);
          });
        }
      })
      .catch(() => {
        throw new Error("erreur serveur");
      });
  };

  return (
    <div id="template">
      {/* modale pour model */}
      <Modalimport
        openModal={importTemplateModal}
        onClose={() => {
          setImportTemplateModal(false);
        }}
        onSubmit={onImportModel}
      />
      {/* Modale pour document_model */}
      <ModalEdit
        tag={row.length === 1 ? row[0].values.libelleFr : ""}
        openModal={editModal}
        onClose={() => {
          setEditModal(false);
        }}
        onSubmit={onUpdateImport}
      />
      <ModalDelete
        rows={row.map((item) => item.values.nomFichier)}
        openModal={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        onSubmit={onDelete}
      />
      <Modalimport
        openModal={importModal}
        onClose={() => {
          setImportModal(false);
        }}
        onSubmit={onImport}
      />

      <Container fluid>
        <div className="doc-title py-3">
          <button
            className="btn"
            type="button"
            onClick={() => {
              history.push("/document");
            }}
          >
            <img src={chevron} alt="chevron" />
          </button>{" "}
          <label
            className="text-uppercase"
            style={{ fontSize: "18px", color: "#B4B6B5", verticalAlign: -4 }}
          >{`${GroupDocName} / ${categorieDocName}`}</label>
        </div>

        <div className="tab-content py-1 mb-4">
          <div className="template-title ml-4 mt-4 d-flex justify-content-between">
            Modèle de documents {categorieDocName}{" "}
            <div className="pr-4">
              {" "}
              {loadingModel && <Spinner style={{ color: "#FFCD00" }} />}{" "}
            </div>
          </div>
          <div className="d-flex justify-content-end mr-4 py-4">
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                setImportTemplateModal(true);
              }}
            >
              Importer un nouveau modèle
            </button>
          </div>
          <div className="px-4 mb-5">
            <TemplateTable
              datas={dataModel}
              pageNumber={pageCountModel}
              loading={loadingModel}
              onDataFetch={onDataFetch}
              onDataFetchModel={onDataFetchModel}
              pageSize={pageSizeModel}
              authentification={authentification}
              siteId={siteInformation.siteId}
            />
          </div>
        </div>

        <div className="tab-content py-1">
          <div className="template-title ml-4 mt-4 d-flex justify-content-between">
            <label>Historique de documents</label>
            <div className="pr-4">
              {loading && <Spinner style={{ color: "#FFCD00" }} />}
            </div>
          </div>
          <div className="d-flex justify-content-end py-4 px-3">
            <div className="ml-4 px-2">
              <button
                className="btn"
                onClick={() => {
                  onDataFetch({
                    pageSize: _pageSize,
                    pageIndex: pageNumber,
                    SortBy: sortBy,
                    SortOrder: sortOrder,
                  });
                  onDataFetchModel({
                    pageSize: pageSizeModel,
                    pageIndex: pageNumberModel,
                    SortBy: sortBy_Temp,
                    SortOrder: sortOrder_Temp,
                  });
                }}
              >
                <img src={update} alt="actualiser" />{" "}
                <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                  Actualiser
                </span>
              </button>
            </div>
            <div className="ml-4 px-2">
              <button
                disabled={row.length === 0 || row.length > 1}
                className="btn"
                onClick={() => {
                  setEditModal(true);
                }}
              >
                <img src={edit} alt="modifier" />{" "}
                <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                  Modifier
                </span>
              </button>
            </div>
            <div className="ml-4 px-2">
              <button
                disabled={row.length === 0}
                className="btn"
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                <img src={bin} alt="supprimer" />{" "}
                <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                  Supprimer
                </span>
              </button>
            </div>
            <div className="ml-4 px-2">
              <button
                disabled={row.length === 0}
                className="btn"
                onClick={() => {
                  onDownload();
                }}
              >
                <img src={download} alt="telecharger" />
                <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                  {" "}
                  Télécharger{" "}
                </span>
              </button>
            </div>
            <div className="ml-4 px-2">
              <button
                disabled={row.length > 0}
                className="btn"
                onClick={() => {
                  setImportModal(true);
                }}
              >
                <img src={share} alt="importer" />{" "}
                <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                  Importer
                </span>
              </button>
            </div>
          </div>

          <div className="px-4">
            <Table
              datas={_data}
              onSelectedRow={(rowItem) => {
                setRow(rowItem);
              }}
              pageNumber={pageCount}
              loading={loading}
              onDataFetch={onDataFetch}
              pageSize={_pageSize}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default connect((state) => state)(Template);
