import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

export default function DeleteIndicator({
  deleteselectionModal,
  cell,
  submitDeleteIndicator,
  loadingModal,
  errorMessage,
  onCloseAll,
  handleSubmit = () => {},
}) {
  const CloseButton = (
    <div>
      <button onClick={onCloseAll} className="btn close-btn"></button>
    </div>
  );

  return (
    <Modal isOpen={deleteselectionModal}>
      <ModalHeader close={CloseButton}></ModalHeader>
      <ModalBody>
        <form
          id="deletevaleur"
          onSubmit={handleSubmit((data) => submitDeleteIndicator(data, cell))}
        >
          <div>
            {`Êtes-vous sûr(e) de vouloir supprimer l'indicateur ${
              cell && cell.cellInfo && cell.cellInfo.libelleFr
            } ?`}
          </div>
          {loadingModal && (
            <div className="Spinner d-flex justify-content-center">
              <Spinner style={{ color: "#FFCD00" }} />
            </div>
          )}
          {errorMessage && (
            <p className="error-text d-flex justify-content-center">
              {errorMessage}
            </p>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <button
          disabled={loadingModal}
          className="btn btn-outline-primary btn-lg mb-3"
          onClick={onCloseAll}
        >
          Non
        </button>
        <button
          disabled={loadingModal}
          type="submit"
          form="deletevaleur"
          className="btn btn-primary btn-lg mb-3"
        >
          Oui
        </button>
      </ModalFooter>
    </Modal>
  );
}
