import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Spinner } from "reactstrap";
import back from "../../assets/back.svg";
import download from "../../assets/doc-download.svg";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import * as documentService from "../../services/documentServices";
import ImportHistory from "./Modals/historyExport";
import "./history.scss";
import DateRangePicker from "./History/Datepicker";
import Table from "./History/Table";

export const DataFetch = async ({
  authentification,
  SiteId,
  DateDebut,
  DateFin,
  EtapeTraitementId,
  IndicateursSuivisIds,
  PageNumber,
  PageSize,
  Releve,
}) => {
  try {
    if (SiteId !== null) {
      const response = await documentService.GetHistoriqueDonnees(
        authentification,
        {
          siteId: SiteId,
          DateDebut: DateDebut === null ? null : format(DateDebut, "d/MM/yyy"),
          DateFin: DateFin === null ? null : format(DateFin, "d/MM/yyy"),
          EtapeTraitementId,
          IndicateursSuivisIds,
          PageNumber,
          PageSize,
          Releve,
        }
      );

      if (`${response.status}`.startsWith("20")) {
        const data = await response.json();
        return await data;
      } else {
        return [];
      }
    }

    return [];
  } catch (error) {
    return [];
  }
};

function History({ authentification, siteInformation }) {
  const [formatedDate, setFormatedDate] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  //options pour les etapes
  const [dataStep, setdataStep] = useState([]);
  //options pour les filtres
  const [dataFilter, setDataFilter] = useState([]);

  //gestion du tableau
  const [loading] = useState(false);

  //gestion des indicateurs pour le tableau
  const [dateInterval, setDateInterval] = useState({ Debut: null, Fin: null });

  const [_EtapeTraitementId, setEtapeTraitementId] = useState(null);
  const [_IndicateursSuivisIds, setIndcateursSuivisIds] = useState([]);
  const [releve, setReleve] = useState(false);
  const [releveDisabled, setReleveDisabled] = useState(false);

  //gestion de la modale
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (siteInformation.siteId !== null) {
      documentService
        .loadEtapeTraitement(authentification, {
          siteId: siteInformation.siteId,
        })
        .then(async (res) => {
          if (`${res.status}`.startsWith("20")) {
            const data = await res.json();
            setdataStep(
              data.map((item) => ({ label: item.libelleFr, value: item.id }))
            );
          }
        })
        .catch((error) => {
          throw new Error({ message: error });
        });
    }
  }, [siteInformation.siteId, authentification]);

  useEffect(() => {
    if (siteInformation.siteId !== null) {
      documentService
        .GetIndicateursSuivisFiltre(authentification, {
          siteId: siteInformation.siteId,
        })
        .then(async (res) => {
          if (`${res.status}`.startsWith("20")) {
            const data = await res.json();
            setDataFilter(
              data.map((item) => ({
                label: item.libelleFr,
                value: item.id,
                modeRenseignementId: item.modeRenseignementId,
                typeDonneeId: item.typeDonneeId,
              }))
            );
          }
        })
        .catch((error) => {
          throw new Error({ message: error });
        });
    }
  }, [siteInformation.siteId, authentification]);

  useEffect(() => {
    if (_IndicateursSuivisIds.length !== 1) {
      setReleveDisabled(true);
      setReleve(false);
      return;
    }

    if (
      _IndicateursSuivisIds.map((x) => x.typeDonneeId).includes("_INDEX") ||
      _IndicateursSuivisIds.map((x) => x.modeRenseignementId).includes("_AUTO")
    ) {
      setReleveDisabled(false);
      return;
    }

    setReleveDisabled(true);
    setReleve(false);
  }, [setReleveDisabled, _IndicateursSuivisIds]);

  const onDataFetch = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      DateDebut,
      DateFin,
      EtapeTraitementId,
      IndicateursSuivisIds,
      Releve,
      SiteId,
    }) => {
      return DataFetch({
        authentification,
        DateDebut,
        DateFin,
        EtapeTraitementId,
        IndicateursSuivisIds: IndicateursSuivisIds.map((item) => item.value),
        PageNumber: pageIndex,
        PageSize: pageSize,
        Releve,
        SiteId: SiteId,
      });
    },
    [authentification]
  );

  const onDownload = () => {
    setOpenModal(true);
  };

  const onSubmitDate = (data) => {
    setFormatedDate({
      label: `${format(data.left, "dd/MM/yyy", { locale: fr })}-${format(
        data.right,
        "dd/MM/yyy",
        { locale: fr }
      )}`,
      value: "",
    });
    setDateInterval({ Debut: data.left, Fin: data.right });
    setOpenMenu(false);
  };

  const onCancelDate = () => {
    setOpenMenu(false);
  };

  const onSubmitStep = (data) => {
    if (data !== null) {
      setEtapeTraitementId(data.value);
    } else {
      setEtapeTraitementId(null);
    }
  };

  const onSubmitIndicator = (data, triggeredAction) => {
    if (triggeredAction && triggeredAction.action === "remove-value") {
      setIndcateursSuivisIds(
        _IndicateursSuivisIds.filter((x) => {
          return x.value !== triggeredAction.removedValue.value;
        })
      );
      return;
    }

    if (data.length < 9) {
      setIndcateursSuivisIds(data);
    }
  };

  const history = useHistory();

  return (
    <div className="tableauhistory">
      {/*hack pour réinitialiser les valeurs de la modale*/}
      {openModal && (
        <ImportHistory
          openModal={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          onSubmit={() => {
            setOpenModal(false);
          }}
          authentification={authentification}
          siteInformation={siteInformation}
        />
      )}
      <Container fluid>
        <div className="doc-title border-bottom py-3">
          <label
            style={{
              fontSize: "18px",
              color: "#B4B6B5",
              verticalAlign: -4,
              marginLeft: 16,
            }}
          >
            INDICATEURS STEP
          </label>
        </div>

        <div className=" py-1">
          <div className="template-title ml-4 d-flex justify-content-between mt-4 mb-4">
            <label>Historique de données</label>
            <div className="d-flex justify-content-end  px-3">
              <div className="ml-4 px-2">
                <button
                  className="btn"
                  onClick={() => {
                    onDownload();
                  }}
                >
                  <img src={download} alt="download" />
                  <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                    {" "}
                    Télécharger{" "}
                  </span>
                </button>
              </div>
              <div className="ml-4 px-2">
                <button
                  className="btn"
                  onClick={() => {
                    history.push("/collectinformations");
                  }}
                >
                  <img src={back} alt="import" />{" "}
                  <span style={{ marginLeft: 11, verticalAlign: "sub" }}>
                    Retour
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="pr-4">
            {loading && <Spinner style={{ color: "#FFCD00" }} />}
          </div>

          <div className="d-flex mb-2 mx-4">
            <Select
              value={formatedDate}
              className="flex-grow-1  mr-2 flex-shrink-0 w-25"
              isSearchable={false}
              onChange={(selectedOption, triggeredAction) => {
                if (triggeredAction.action === "clear") {
                  setFormatedDate(null);
                  setDateInterval({ Debut: null, Fin: null });
                }
              }}
              classNamePrefix="selectPicker"
              menuIsOpen={openMenu}
              openMenuOnClick={false}
              onMenuOpen={() => {
                setOpenMenu(true);
              }}
              placeholder="Date"
              isClearable={true}
              closeMenuOnSelect={false}
              closeMenuOnScroll={false}
              options={[
                {
                  label: (
                    <DateRangePicker
                      onSubmitDate={onSubmitDate}
                      onCancelDate={onCancelDate}
                    />
                  ),
                },
              ]}
            />
            <Select
              options={dataStep}
              isClearable={true}
              onChange={onSubmitStep}
              placeholder="Etape"
              className="flex-grow-1 flex-shrink-0 w-25 mx-1"
            />
            <Select
              isMulti
              onChange={onSubmitIndicator}
              value={_IndicateursSuivisIds}
              placeholder="Indicateurs"
              options={dataFilter}
              className="flex-grow-1 flex-shrink-0 w-25 mx-1"
            />
            <label className="flex-shrink-0  ml-2 border px-3 py-2 chkbox chkbox ">
              <input
                type="checkbox"
                disabled={releveDisabled}
                checked={releve}
                onChange={() => {
                  setReleve(!releve);
                }}
              />{" "}
              Relevé
            </label>
          </div>

          <div className="px-4">
            <Table
              loading={loading}
              onDataFetch={onDataFetch}
              DateDebut={dateInterval.Debut}
              DateFin={dateInterval.Fin}
              EtapeTraitementId={_EtapeTraitementId}
              IndicateursSuivisIds={_IndicateursSuivisIds}
              Releve={releve}
              SiteId={siteInformation.siteId}
              authentification={authentification}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default connect((state) => state)(History);
