import React, { Component, useState, useEffect } from "react";
import {
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
} from "@coreui/react";
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  NavLink,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import SaurHeader from "../SaurHeader/SaurHeader";
import "./SaurLayout.scss";
// sidebar nav config
import * as NAVIGATION from "./navigation";
// routes config
import routes from "../../routes/index";
import baniereSaur from "../../assets/saurBanner.png";

export function DocumentLink() {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (
      NAVIGATION.CategoriesHelper().pathArray.includes(
        history.location.pathname
      )
    ) {
      setOpen(true);
    }
  }, [history.location.pathname]);

  return (
    <li
      onClick={(e) => {
        if (e.target.id === "navDropdown") {
          setOpen(!open);
        }
      }}
      className={`nav-item nav-dropdown ${open ? "open" : ""}`}
    >
      <NavLink
        to="/document"
        className="nav-link"
        activeClassName="active"
        id="navDropdown"
      >
        <i className="nav-icon fa fa-book"></i>
        <span className="font-lg">Documents</span>
      </NavLink>
      <ul className="nav-dropdown-items">
        <li className="nav-item">
          <NAVIGATION.ANALYSES />
        </li>

        <li className="nav-item">
          <NAVIGATION.AUTRES_DOC />
        </li>
        <li className="nav-item">
          <NAVIGATION.SUIVI_DECHETS />
        </li>
        <li className="nav-item">
          <NAVIGATION.CONTRAT />
        </li>

        <li className="nav-item">
          <NAVIGATION.REGLEMENTAIRE />
        </li>
        <li className="nav-item">
          <NAVIGATION.CR_REU />
        </li>

        <li className="nav-item">
          <NAVIGATION.FACTURATION />
        </li>
        <li className="nav-item">
          <NAVIGATION.GIDAF />
        </li>

        <li className="nav-item">
          <NAVIGATION.MAINTENANCE />
        </li>
        <li className="nav-item">
          <NAVIGATION.METROLOGIE />
        </li>
        <li className="nav-item">
          <NAVIGATION.AUTRES_REPORT />
        </li>
        <li className="nav-item">
          <NAVIGATION.REPORT_ANNU />
        </li>
        <li className="nav-item">
          <NAVIGATION.REPORT_MENS />
        </li>
        <li className="nav-item">
          <NAVIGATION.SECURITE />
        </li>
        <li className="nav-item">
          <NAVIGATION.SUIVI_AGRO />
        </li>
        <li className="nav-item">
          <NAVIGATION.TRAVAUX />
        </li>
      </ul>
    </li>
  );
}

class SaurLayout extends Component {
  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <SaurHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            {/* <AppSidebarNav navConfig={navigation} {...this.props} /> */}
            <div
              tag="nav"
              className="scrollbar-container sidebar-nav ps ps-container"
            >
              <ul className="nav">
                <li className="nav-item">
                  <NavLink
                    to="/home"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="nav-icon fa fa-home"></i>
                    <span className="font-lg">Accueil</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/stepin"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="nav-icon cui-arrow-right"></i>
                    <span className="font-lg">Entrée STEP</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/collectinformations"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="nav-icon fa fa-folder-open-o"></i>
                    <span className="font-lg">Indicateurs STEP</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/stepout"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="nav-icon cui-arrow-left"></i>
                    <span className="font-lg">Sortie STEP</span>
                  </NavLink>
                </li>
                <DocumentLink />
              </ul>
            </div>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
            <div className="saur-banner">
              <img className="saur-banner-img" src={baniereSaur} />
            </div>
          </AppSidebar>
          <main className="main">
            {/* <Container fluid> */}
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={() => <route.component />}
                  />
                ) : null;
              })}
              <Redirect from="/" to="/home" />
            </Switch>
            {/* </Container> */}
          </main>
        </div>
      </div>
    );
  }
}

export default connect((state) => state)(withRouter(SaurLayout));
