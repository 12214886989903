import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { GetAccessToken } from "../authentication/token";

export async function AlarmsData(
  { dateDebut, dateFin, SiteId, EtapeTraitementId },
  authentification
) {
  let data = [];

  try {
    let result = await fetch(
      `${window.appSettings.apiendpointalarm}/DonneesAlarmes`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dateDebut: format(dateDebut, "dd/MM/yyyy"),
          dateFin: format(dateFin, "dd/MM/yyyy"),
          siteId: SiteId,
          EtapeTraitementId: EtapeTraitementId,
        }),
      }
    );

    if (result.status === 200) {
      data = await result.json();
      console.log(data);
    }

    if (result.status === 204) {
      data = [];
    }
  } catch (ex) {
    throw new Error("Erreur serveur");
  }

  return data;
}

export async function GetTauxConformite(
  authentification,
  { siteId, TypeAffichage, DateDebut, DateFine }
) {
  try {
    return await fetch(
      `${
        window.appSettings.apiendpoint
      }/DonneeIndicateur/GetTauxConformite?siteId=${siteId}&TypeAffichage=${TypeAffichage}&DateDebut=${format(
        DateDebut,
        "dd/MM/yyyy",
        { locale: fr }
      )}&DateFine=${format(DateFine, "dd/MM/yyyy", { locale: fr })}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (ex) {
    return Promise.reject();
  }
}

// Récupère le fichier à télécharger
export async function DownloadAlarmeFile(
  authentification,
  { dateDebut, dateFin, SiteId, EtapeTraitementId }
) {
  let data;

  try {
    const response = await fetch(
      `${window.appSettings.apiendpointalarm}/DonneesAlarmes/DownloadAlarmeFile`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${await GetAccessToken(authentification)}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dateDebut: format(dateDebut, "dd/MM/yyyy"),
          dateFin: format(dateFin, "dd/MM/yyyy"),
          siteId: SiteId,
          EtapeTraitementId: EtapeTraitementId,
        }),
        responseType: "stream",
      }
    );

    data = await response.blob();
  } catch (exception) {
    throw new Error("Erreur Serveur");
  }

  return data;
}
