import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { validate } from "./AddValue";

export default function EditValue({
  editModal,
  toggleValidEdit,
  cell,
  loadingModal,
  errorMessage,
  register = () => {},
  errors,
  onCloseAll,
  handleSubmit = () => {},
  getValues,
  triggerValidation,
}) {
  const CloseButton = (
    <div>
      <button onClick={onCloseAll} className="btn close-btn"></button>
    </div>
  );

  return (
    <Modal isOpen={editModal}>
      <ModalHeader close={CloseButton}>
        <label className="firstUpper mt-1 ml-4 h2 d-block">
          {cell && cell.rowInfo && cell.rowInfo.libelleFr}
        </label>
        <label className="ml-4 d-block">{cell && cell.date}</label>
      </ModalHeader>
      <ModalBody>
        <form
          data-testid="form"
          id="editvaleur"
          onSubmit={handleSubmit((data) => toggleValidEdit(data, cell))}
        >
          <label className="font-weight-normal font-lg ml-4">Valeur</label>
          <div className="edit-label">
            <input
              name="valeur"
              placeholder="Valeur"
              disabled={loadingModal}
              ref={register({
                validate: validate,
                pattern: /^\d*((,)\d+)?$/,
              })}
              className={`edit-input ${
                errors && errors.valeur ? "error-field" : ""
              }  `}
              defaultValue={cell && cell.cellInfo && cell.cellInfo.valeur}
            />{" "}
            {cell && cell.rowInfo && cell.rowInfo.unite}
          </div>
          {cell && cell.rowInfo && cell.rowInfo.valeurLabo && (
            <label className="font-weight-normal font-lg ml-4">
              Valeur analyse agréée
            </label>
          )}
          <div className="edit-label">
            {cell && cell.rowInfo && cell.rowInfo.valeurLabo && (
              <input
                name="valeurLabo"
                onChange={() => {
                  triggerValidation();
                }}
                placeholder="Valeur analyse agréée"
                disabled={loadingModal}
                ref={register({ pattern: /^\d*((,)\d+)?$/ })}
                className={`edit-input ${
                  errors && errors.valeurLabo ? "error-field" : ""
                }  `}
                defaultValue={cell && cell.cellInfo && cell.cellInfo.valeurLabo}
              />
            )}
            {cell &&
              cell.rowInfo &&
              cell.rowInfo.valeurLabo &&
              cell.rowInfo.unite}
          </div>
          {cell && cell.rowInfo && cell.rowInfo.indicateurIdSiccite && (
            <label className="font-weight-normal font-lg ml-4">
              Valeur de siccite
            </label>
          )}
          <div className="edit-label">
            {cell && cell.rowInfo && cell.rowInfo.indicateurIdSiccite && (
              <input
                placeholder="Valeur de siccité"
                onChange={() => {
                  triggerValidation();
                }}
                name="valeurSiccite"
                disabled={loadingModal}
                ref={register({ pattern: /^\d*((,)\d+)?$/ })}
                className={`edit-input ${
                  errors && errors.valeurSiccite ? "error-field" : ""
                }  `}
                defaultValue={
                  cell && cell.cellInfo && cell.cellInfo.valeurSiccite
                }
              />
            )}
            {cell &&
              cell.rowInfo &&
              cell.rowInfo.indicateurIdSiccite &&
              cell.rowInfo.uniteSiccite}
          </div>
          {loadingModal && (
            <div className="Spinner d-flex justify-content-center">
              <Spinner style={{ color: "#FFCD00" }} />
            </div>
          )}
          {errorMessage && (
            <p className="error-text d-flex justify-content-center">
              {errorMessage}
            </p>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <button
          id="subm"
          type="submit"
          disabled={loadingModal}
          form="editvaleur"
          className="btn btn-primary btn-lg mb-3"
        >
          Valider
        </button>
      </ModalFooter>
    </Modal>
  );
}
