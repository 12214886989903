//Librairies
import React, { useEffect } from "react";
import { useTable, useRowSelect, usePagination, useSortBy } from "react-table";
//Assets
import "./../document.scss";
import "./../template.scss";
//Applications
import {
  formatedDate,
  IndeterminateCheckbox,
  Tableau,
} from "../../../components/Helpers/ReactTableHelper";
import { Pagination } from "../../../components/Helpers/ReactTableHelper";

export default function Table({
  datas = [],
  onSelectedRow = () => {},
  onDataFetch = () => {},
  pageNumber = 1,
  pageSize = 20,
}) {
  const data = React.useMemo(() => datas, [datas]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id", // accessor is the "key" in the data
      },
      {
        Header: "Libellé",
        accessor: "libelleFr",
      },
      {
        Header: "Nom du Modèle",
        accessor: "nomModele",
      },
      {
        Header: "Nom du Fichier",
        accessor: "nomFichier",
      },
      {
        Header: "Période considérée",
        accessor: "periodeConsideree", // accessor is the "key" in the data,
        Cell: formatedDate,
      },
      {
        Header: "Date Ajout",
        accessor: "dateAjout", // accessor is the "key" in the data
        Cell: formatedDate,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: "id",
        pageIndex: 0,
        pageSize: pageSize,
        sortBy: [{ id: "dateAjout", desc: true }],
      },
      manualPagination: true, // Tell the usePagination
      manualSortBy: true,
      defaultCanSort: true,
      disableSortRemove: true,
      disableMultiSort: true,
      pageCount: pageNumber,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((visiblecolumn) => {
        return [
          // Let's make a column for selection
          {
            id: "selection",
            accessor: "selector",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            disableSortBy: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...visiblecolumn,
        ];
      });
    }
  );

  useEffect(() => {
    onSelectedRow(selectedFlatRows);
  }, [onSelectedRow, selectedFlatRows]);

  useEffect(() => {
    const SortBy = sortBy[0].id;
    const SortOrder = sortBy[0].desc ? "desc" : "asc";
    onDataFetch({ pageIndex, pageSize, SortBy, SortOrder });
  }, [onDataFetch, pageIndex, pageSize, sortBy]);

  return (
    <div>
      <Tableau
        isSorted={true}
        className="tableaudoc"
        prepareRow={prepareRow}
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
      />
      {rows.length > pageSize - 1 && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          previousPage={previousPage}
        />
      )}
    </div>
  );
}
