export const LocalStorage = {
  token: {
    get: () => localStorage.getItem("token"),
    set: (value) => localStorage.setItem("token", value),
  },
  idToken: {
    get: () => localStorage.getItem("idToken"),
    set: (value) => localStorage.setItem("idToken", value),
  },
  refreshToken: {
    get: () => localStorage.getItem("refreshToken"),
    set: (value) => localStorage.setItem("refreshToken", value),
  },
  userInfo: {
    email: {
      get: () => localStorage.getItem("userInfo.email"),
      set: (value) => localStorage.setItem("userInfo.email", value),
    },
    name: {
      get: () => localStorage.getItem("userInfo.name"),
      set: (value) => localStorage.setItem("userInfo.name", value),
    },
    roles: {
      get: () => localStorage.getItem("userInfo.roles"),
      set: (value) => localStorage.setItem("userInfo.roles", value),
    },
  },
  ssoResponse: {
    get: () => localStorage.getItem("sso-response"),
    set: (value) => localStorage.setItem("sso-response", value),
  },
};
