//Librairies
import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { Label } from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
//Services
import { GetAccessToken } from "../../authentication/token";

const lineSeuilMax = {
  label: null,
  fill: false,
  lineTension: 0.1,
  backgroundColor: "#F24C07",
  borderColor: "#F24C07",
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "#F24C07",
  pointBackgroundColor: "#F24C07",
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: "#F24C07",
  pointHoverBorderColor: "#F24C07",
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
  data: null,
};

const lineSeuilMin = {
  label: null,
  fill: false,
  lineTension: 0.1,
  backgroundColor: "#0a3c5c",
  borderColor: "#0a3c5c",
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "#0a3c5c",
  pointBackgroundColor: "#0a3c5c",
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: "#0a3c5c",
  pointHoverBorderColor: "#0a3c5c",
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
  data: null,
};

// Component to Get Input and Output PH
class Ph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: null,
      data: null,
      moyen: null,
      DateDebut: this.props.DateDebut,
      DateFin: this.props.DateFin,
      Action: this.props.Action,
      Unite: null,
      SiteId: this.props.siteInformation.siteId,
      line: {
        labels: null,
        datasets: [
          {
            label: "PH",
            fill: false,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "#FFCD00",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "#FFCD00",
            pointBackgroundColor: "#FFCD00",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#FFCD00",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: null,
          },
        ],
      },
    };
    this.options = {
      legend: {
        display: false,
      },
    };
  }

  async LoadData() {
    try {
      let result = await fetch(
        `${window.appSettings.apiendpoint}/DonneeIndicateur`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await GetAccessToken(
              this.props.authentification
            )}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            SiteId: this.state.SiteId,
            DateDebut: moment(this.state.DateDebut).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            DateFin: moment(this.state.DateFin).format("DD/MM/YYYY HH:mm:ss"),
            IndicateurSuiviId: this.props.IndicateurSuiviId,
            TypeAffichage: this.state.Action,
            ParametreId: null,
          }),
        }
      );
      if (result.status === 200) {
        let data = await result.json();
        this.state.line.datasets[0].data = data.data;
        this.state.line.labels = data.labels;
        this.forceUpdate();
        if (!this.state.line.datasets[1] && data.seuilMax) {
          lineSeuilMax.label = "Seuil Max";
          this.state.line.datasets.push(lineSeuilMax);
          lineSeuilMax.data = data.seuilMax;
          this.forceUpdate();
        } else {
          this.state.line.datasets[1].data = data.seuilMax;
          this.forceUpdate();
        }
        if (!this.state.line.datasets[2] && data.seuilMin) {
          this.state.line.datasets.push(lineSeuilMin);
          lineSeuilMin.label = "Seuil Min";
          lineSeuilMin.data = data.seuilMin;
          this.forceUpdate();
        } else {
          this.state.line.datasets[2].data = data.seuilMin;
          this.forceUpdate();
        }
        this.setState({ moyen: data.moyen });
      }
    } catch (ex) {}
  }

  async GetUnitIndicateur() {
    try {
      let result = await fetch(
        `${window.appSettings.apiendpoint}/DonneeIndicateur/GetUnitesIndicateursSuivis?SiteId=${this.state.SiteId}&IndicateurSuiviId=${this.props.IndicateurSuiviId}&TypeAffichage=${this.props.Action}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${await GetAccessToken(
              this.props.authentification
            )}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) {
        let data = await result.json();
        this.setState({ Unite: data.uniteIndicateurSuivi });
      }
    } catch (ex) {}
  }

  //Method executed After rendering compenont
  async componentDidMount() {
    await Promise.all([await this.LoadData(), await this.GetUnitIndicateur()]);
  }

  static getDerivedStateFromProps(nextProp, prevState) {
    if (
      nextProp.DateDebut !== prevState.DateDebut &&
      nextProp.DateFin !== prevState.DateFin
    ) {
      return {
        DateDebut: nextProp.DateDebut,
        DateFin: nextProp.DateFin,
        Action: nextProp.Action,
      };
    } else return null;
  }

  // Method de refresh data after changing prpos
  async componentDidUpdate(prevProp) {
    if (
      prevProp.DateDebut !== this.state.DateDebut &&
      prevProp.DateFin !== this.state.DateFin
    ) {
      this.setState({
        DateDebut: prevProp.DateDebut,
        DateFin: prevProp.DateFin,
        Action: prevProp.Action,
      });
      await this.LoadData();
    }
    if (prevProp.Action !== this.state.Action) {
      await this.GetUnitIndicateur();
    }
    if (this.state.SiteId !== this.props.siteInformation.siteId) {
      this.setState({ SiteId: this.props.siteInformation.siteId });
      await this.LoadData();
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <Label className="Label-Widget">
          PH {!this.state.Unite ? "" : this.state.Unite}
        </Label>
        <div>
          <Label
            className="Label-Granularite"
            style={{ display: "grid", justifyContent: "right", color: "black" }}
          >
            {this.state.Granularite}
          </Label>
        </div>
        <Line data={this.state.line} options={this.options} />
      </div>
    );
  }
}

export default connect((state) => state)(Ph);
