export enum ReduxAuthType {
  Initializing,
  LoginSuccess,
  LoginError,
  RefreshToken,
  Logout,
}

export interface ReduxAuthState {
  initializing: boolean;
  initialized: boolean;
  idToken: string;
  refreshToken: string;
  token: string;
  tokenExp: number;
  authenticated: boolean;
  userInfo: ReduxAuthUserInfo;
  state: ReduxAuthType;
}

export interface ReduxAuthUserInfo {
  name: string;
  email: string;
  roles: Array<String>;
}

export interface ReduxAuthActionPayload {
  idToken?: string;
  refreshToken?: string;
  token?: string;
  tokenExp?: number;
  userInfo?: ReduxAuthUserInfo;
}

export interface ReduxAuthAction {
  type: ReduxAuthType;
  payload: ReduxAuthActionPayload;
}
