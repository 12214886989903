//Librairies
import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
//css
import "./styles/App.scss";
import "notyf/notyf.min.css";
//prechargement
import iconclose from "./assets/icon-close.svg";
import iconclosehover from "./assets/icon-close_survol.svg";
import iconedit from "./assets/edit.svg";
import iconbin from "./assets/bin.svg";
import { allReducers } from "./stores";
import { useKeycloak } from "@react-keycloak/web";
import LoginKeyCloak from "./components/login/LoginKeyCloak";
import { SaurLayout } from "./components";
import { KeyCloakProvider } from "./components/Providers/keycloakProvider";
import {
  ReduxAuthState,
  ReduxAuthType,
} from "./types/authentificationstoretypes";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

export const App = () => {
  const { keycloak } = useKeycloak();

  return (
    <>
      {/* prechargement des icones pour éviter l'effet clipping....  la div est caché pour ne pas être visible*/}
      <div id="preload" className="d-none">
        <img src={iconclose} alt="preloadicone" />
        <img src={iconclosehover} alt="preloadicone" />
        <img src={iconedit} alt="preloadicone" />
        <img src={iconbin} alt="preloadicone" />
      </div>

      {(allReducers.getState().authentification as ReduxAuthState).state ===
      ReduxAuthType.Initializing ? (
        <LoginKeyCloak />
      ) : (
        <KeyCloakProvider>
          <React.Fragment>
            <HashRouter>
              <React.Suspense fallback={loading()}>
                <Switch>
                  <Route
                    path="/"
                    render={(props: any) => <SaurLayout {...props} />}
                  />
                </Switch>
              </React.Suspense>
            </HashRouter>
          </React.Fragment>
        </KeyCloakProvider>
      )}
    </>
  );
};

export default connect((state) => state)(App);
