import React from "react";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import { CategoriesHelper, NAMES } from "./navigation";
import "./menu.scss";

//  export function ButtonNav({onClick, btnClassName , className}){
//     <button onClick={() => { history.push(CategoriesHelper().paths.ANALYSES); }} className="btn btn-outline-primary  mx-4" ><div className="firstUpper m-0 ">{NAMES.ANALYSES}</div></button>
//  }

export default function Menu() {
  let history = useHistory();

  return (
    <div id="menu" style={{ width: "100%" }}>
      <div className="doc-title py-3 px-2 border-bottom">
        <label
          style={{
            fontSize: "18px",
            color: "#B4B6B5",
            verticalAlign: -4,
            marginLeft: 16,
          }}
        >
          DOCUMENTS
        </label>
      </div>
      <Container fluid>
        <div className="py-4 info-label">
          {/* pour mettre des infos dans le future */}
        </div>

        <div className="d-flex">
          <div className="flex-grow-1 mr-3">
            <div className="group-1">
              <label className="m-4">Suivi Réglementaire (ICPE)</label>
              <div className="d-flex flex-column">
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.ANALYSES);
                  }}
                  className="btn btn-outline-primary  mx-4"
                >
                  <div className="firstUpper m-0 ">{NAMES.ANALYSES}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.CONTROLE_REGL);
                  }}
                  className="btn btn-outline-primary  mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.CONTROLE_REGL}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.METROLOGIE);
                  }}
                  className="btn btn-outline-primary  mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.METROLOGIE}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.SUIVI_AGRO);
                  }}
                  className="btn btn-outline-primary  mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.SUIVI_AGRO}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.SUIVI_DECHETS);
                  }}
                  className="btn btn-outline-primary  mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.SUIVI_DECHETS}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.GIDAF);
                  }}
                  className="btn btn-outline-primary  mx-4"
                >
                  <div className="firstUpper text-uppercase  m-0">
                    {NAMES.GIDAF}
                  </div>
                </button>
              </div>
            </div>
            <div className="group-2 mb-3">
              <label className="m-4">Documents contractuels</label>

              <div className="d-flex flex-column">
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.CONTRAT);
                  }}
                  className="btn btn-outline-primary mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.CONTRAT}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.FACTURATION);
                  }}
                  className="btn btn-outline-primary mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.FACTURATION}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.AUTRES_DOC);
                  }}
                  className="btn btn-outline-primary mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.AUTRES_DOC}</div>
                </button>
              </div>
            </div>
          </div>

          <div className="flex-grow-1 ml-3">
            <div className="group-3">
              <label className="m-4">Sécurité</label>

              <div className="d-flex flex-column">
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.SECURITE);
                  }}
                  className="btn btn-outline-primary  mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.SECURITE}</div>
                </button>
              </div>
            </div>
            <div className="group-4">
              <label className="m-4">Travaux et maintenance</label>

              <div className="d-flex flex-column">
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.TRAVAUX);
                  }}
                  className="btn btn-outline-primary  mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.TRAVAUX}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.MAINTENANCE);
                  }}
                  className="btn btn-outline-primary  mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.MAINTENANCE}</div>
                </button>
              </div>
            </div>
            <div className="group-5">
              <label className="m-4">Reporting</label>

              <div className="d-flex flex-column">
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.REPORT_ANNU);
                  }}
                  className="btn btn-outline-primary mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.REPORT_ANNU}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.REPORT_MENS);
                  }}
                  className="btn btn-outline-primary mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.REPORT_MENS}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.CR_REU);
                  }}
                  className="btn btn-outline-primary mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.CR_REU}</div>
                </button>
                <button
                  onClick={() => {
                    history.push(CategoriesHelper().paths.AUTRES_REPORT);
                  }}
                  className="btn btn-outline-primary mx-4"
                >
                  <div className="firstUpper m-0">{NAMES.AUTRES_REPORT}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
