import React from 'react';
import { format, parseJSON } from 'date-fns';


export function formatedDate(data) {
  return data.value ? format(parseJSON(data.value), 'dd/MM/yyyy') : ""
}



export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)



export function Pagination({ canPreviousPage, gotoPage, canNextPage, pageCount, previousPage, nextPage, pageOptions, pageIndex, isSorted = false }) {
  return (<div className="pagination d-flex justify-content-between">
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
          <button aria-label="Previous btn" className="page-link mr-1" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <b>{'<<'}</b>
          </button>
        </li>
        <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
          <button className="page-link btn mx-1" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <b>{'<'}</b>
          </button>
        </li>

        <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
          <button className="page-link btn mx-1" onClick={() => nextPage()} disabled={!canNextPage}>
            <b>{'>'}</b>
          </button>
        </li>
        <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>

          <button aria-label="Next" className="page-link btn ml-1" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <b> {'>>'}</b>
          </button>
        </li>
      </ul>
    </nav>

    <span className="mt-2">
      <strong>{`${pageOptions.length !== 0 ? `Page ${pageIndex + 1} of ${pageOptions.length}` : ""}`} </strong>
    </span>
  </div>)

}


export function Tableau({ className = "", getTableProps = () => { }, headerGroups = [], prepareRow, rows = [], getTableBodyProps = () => { }, isSorted }) {
  return (
    <table className={className} {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {isSorted 
            ? headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {
                  column.isSorted
                    ? (column.isSortedDesc
                      ? <div className="text-nowrap"> {column.render('Header')} <i className="fa fa-chevron-circle-down ml-2" /></div>
                      : <div className="text-nowrap">{column.render('Header')}<i className="fa fa-chevron-circle-up ml-2" /></div>)
                    : column.render('Header')
                }
              </th>
            ))
              : headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} >
                  {column.render('Header')}
                </th>
              ))
            }
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr onClick={() => { row.toggleRowSelected() }} className={`${row.isSelected ? "selected" : ""}`} {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>

  )
}