//librairies
import React, { useState, useReducer, useEffect, useContext } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Container,
} from "reactstrap";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { format } from "react-string-format";
import moment from "moment";
import fileDownload from "js-file-download";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  format as formatD,
} from "date-fns";
import { fr } from "date-fns/locale";
//Css
import "../../styles/Button.css";
import "../../styles/Label.css";
import "../../styles/Spinner.css";
import "./home.scss";
//Assets
import ArrowIcon from "../../assets/arrow.svg";
import AlertIcon from "../../assets/AlertIcon.svg";
import StepImg from "../../assets/progres-01.png";
import ChevronImg from "../../assets/chevron.svg";
import PlaceHolderImg from "../../assets/placeholder.svg";
import iconclosehover from "../../assets/icon-close_survol.svg";
import iconenvironnementhover from "../../assets/environnement_hover.svg";
import icondirtywaterhover from "../../assets/dirtywater_hover.svg";
//Services
import * as AlarmServices from "../../services/AlarmServices";
import * as IndicatorService from "../../services/IndicatorServices";
import NotifyContext from "../../services/NotyfContext";
//Application
import VolumeWater from "../../components/VolumeWater/VolumeWater";
import DCO from "../../components/DCO/DCO";
import { BaseAction } from "../../types/baseAction";
import { DateWidgetLabel } from "../../types/dateWidgetLabel";
import { DonneesAlarmes } from "../../types/api/donneesAlarme";
import { LocationDescriptor } from "history";
import { SiteInformation } from "../../types/siteInformation";
import { ReduxAuthState } from "../../types/authentificationstoretypes";
import { SiteConfig } from "../../types/api/siteConfig";
import { baseSiteConfig } from "../../constants";
//import { StartOfWeek, month_name } from '../core'

const DateNow = new Date();

const initialState = getMonth();

export function getMonth(): DateWidgetLabel {
  let dateDebut = startOfMonth(DateNow);
  let dateFin = endOfMonth(DateNow);
  let label = `mois de ${formatD(dateDebut, "LLLL", { locale: fr })}`;
  return {
    selected: "Mois",
    label: ` Le ${label}`,
    btnLabel: label,
    dateDebut: dateDebut,
    dateFin: dateFin,
  };
}

export function getWeek(): DateWidgetLabel {
  let dateDebut = startOfWeek(DateNow, { weekStartsOn: 1, locale: fr });
  let dateFin = endOfWeek(DateNow);
  let nbrWeeks = `Semaine n° ${formatD(DateNow, "w", { locale: fr })}`;
  let label = `Du ${dateDebut.getDate()} ${formatD(dateDebut, "LLLL", {
    locale: fr,
  })} au ${dateFin.getDate()} ${formatD(dateFin, "LLLL", { locale: fr })}`;

  return {
    selected: "Semaine",
    label: label,
    btnLabel: nbrWeeks,
    dateDebut: dateDebut,
    dateFin: dateFin,
  };
}

//Cast return as any, because useReduce doesn't like the typescript type DateWidgetLabel
export function reducer(_payload: null, action: BaseAction) {
  if (action.type === "Semaine") {
    return getWeek() as any;
  } else {
    return getMonth() as any;
  }
}

export function AlertData(data: Array<DonneesAlarmes>) {
  return data.map((alert) => (
    <tr key={alert.id} className="">
      <td className="text-center">
        {!alert.dateApparition ? "-" : alert.dateApparition.split(" ")[0]}
      </td>
      <td className="text-center">
        {!alert.dateApparition ? "-" : alert.dateApparition.split(" ")[1]}
      </td>
      <td className="text-center">{alert.criticite}</td>
      <td className="text-center">{alert.libelleVoieFr}</td>
      <td className="text-center">{alert.dateRetourOk}</td>
    </tr>
  ));
}

interface HomeProps {
  authentification: ReduxAuthState;
  siteInformation: SiteInformation;
}

function Home({ authentification, siteInformation }: HomeProps) {
  const notify = useContext(NotifyContext);
  const history = useHistory();

  const goTo = (url: LocationDescriptor<unknown>) => {
    history.push(url);
  };

  //configuration des entrées et sorties DCO
  const [siteConfig, setSiteConfig] = useState<SiteConfig>();

  //gestion bouton alarm en entrée
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [alertData, setAlertData] = useState<Array<DonneesAlarmes>>([]);
  const CloseButton = (
    <button onClick={toggle} className="btn close-btn"></button>
  );

  //gestion bouton alarm en performance step
  const [modalMiddle, setModalMiddle] = useState(false);
  const toggleMiddle = () => setModalMiddle(!modalMiddle);
  const [alertDataMiddle, setAlertDataMiddle] = useState<Array<DonneesAlarmes>>(
    []
  );
  const CloseButtonMiddle = (
    <button onClick={toggleMiddle} className="btn close-btn"></button>
  );

  //gestion bouton alarm en sortie
  const [modalOut, setModalOut] = useState(false);
  const toggleOut = () => setModalOut(!modalOut);
  const [alertDataOut, setAlertDataOut] = useState<Array<DonneesAlarmes>>([]);
  const CloseButtonOut = (
    <button onClick={toggleOut} className="btn close-btn"></button>
  );

  //gestion de la période
  const [dateState, dateStateDispach] = useReducer(reducer, initialState);

  //variable taux de conformité
  const [conform, setConform] = useState("");
  const [charge, setCharge] = useState("");

  //au chargement de la page
  useEffect(() => {
    if (siteInformation.siteId !== null) {
      IndicatorService.getSiteConfig(authentification, siteInformation.siteId)
        .then(setSiteConfig)
        .catch((error) => {
          //notify.error(error.message)
        });
      AlarmServices.AlarmsData(
        {
          SiteId: siteInformation.siteId, //SiteId : 49176SE00002
          dateDebut: dateState.dateDebut,
          dateFin: dateState.dateFin,
          EtapeTraitementId: 1,
        },
        authentification
      )
        .then(setAlertData)
        .catch((error) => {
          //notify.error(error.message)
        });

      AlarmServices.AlarmsData(
        {
          SiteId: siteInformation.siteId, //SiteId : 49176SE00002
          dateDebut: dateState.dateDebut,
          dateFin: dateState.dateFin,
          EtapeTraitementId: 2,
        },
        authentification
      )
        .then(setAlertDataOut)
        .catch((error) => {
          // notify.error(error.message)
        });

      AlarmServices.AlarmsData(
        {
          SiteId: siteInformation.siteId, //SiteId : 49176SE00002
          dateDebut: dateState.dateDebut,
          dateFin: dateState.dateFin,
          EtapeTraitementId: 3,
        },
        authentification
      )
        .then(setAlertDataMiddle)
        .catch((error) => {
          // notify.error(error.message)
        });

      AlarmServices.GetTauxConformite(authentification, {
        siteId: siteInformation.siteId,
        TypeAffichage: "Jour",
        DateDebut: dateState.dateDebut,
        DateFine: dateState.dateFin,
      })
        .then(async (res) => {
          if (res.status === 200) {
            const result = await res.json();
            setConform(result.tauxConformiteConcentration);
            setCharge(result.tauxConformiteCharge);
          }
        })
        .catch(() => {
          //console.log('catch')
        });
    }
  }, [siteInformation.siteId, authentification, dateState, notify]);

  /**
   * telechargement des alertes
   * @param {string} name
   * @param {number} EtapeTraitementId
   * @param {function} toggle
   */
  const onDownload =
    (name: string, EtapeTraitementId: number, toggleCallBack: () => void) =>
    () => {
      AlarmServices.DownloadAlarmeFile(authentification, {
        dateDebut: dateState.dateDebut,
        dateFin: dateState.dateFin,
        SiteId: siteInformation.siteId,
        EtapeTraitementId,
      })
        .then((data) => {
          fileDownload(
            data,
            format(
              (window as any).appSettings.namefileExportAlarme, // Cast windon on as any, tant que le system d'appSettings n'est pas changé
              name,
              moment(dateState.dateDebut).format("DDMMYYYY"),
              moment(dateState.dateFin).format("DDMMYYYY")
            )
          );
          toggleCallBack();
        })
        .catch((error) => {
          //notify.error(error.message);
        });
    };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader
          toggle={toggle}
          className="text-primary pl-5"
          close={CloseButton}
        >
          Sortie usine / Entrée STEP - Alertes{" "}
        </ModalHeader>
        <ModalBody className="p-5">
          {alertData && alertData.length > 0 ? (
            <>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center">Date apparition</th>
                    <th className="text-center">Heure apparition</th>
                    <th className="text-center">Criticité</th>
                    <th className="text-center">Libellé alarme</th>
                    <th className="text-center">Date et heure de résolution</th>
                  </tr>
                </thead>
                <tbody>{AlertData(alertData)}</tbody>
              </table>
            </>
          ) : (
            <div className="d-flex justify-content-center alert-text ">
              Aucune Alerte
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <button
            disabled={alertData && alertData.length === 0}
            className="btn btn-outline-primary btn-lg mb-3"
            onClick={onDownload("SortieUsine_EntréeSTEP", 1, toggle)}
          >
            Télécharger
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalMiddle} toggle={toggleMiddle} size="xl">
        <ModalHeader
          toggle={toggleMiddle}
          className="text-primary pl-5"
          close={CloseButtonMiddle}
        >
          Performance STEP - Alertes
        </ModalHeader>
        <ModalBody className="p-5">
          {alertDataMiddle && alertDataMiddle.length > 0 ? (
            <>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center">Date apparition</th>
                    <th className="text-center">Heure apparition</th>
                    <th className="text-center">Criticité</th>
                    <th className="text-center">Libellé alarme</th>
                    <th className="text-center">Date et heure de résolution</th>
                  </tr>
                </thead>
                <tbody>{AlertData(alertDataMiddle)}</tbody>
              </table>
            </>
          ) : (
            <div className="d-flex justify-content-center alert-text ">
              Aucune Alerte
            </div>
          )}
        </ModalBody>

        <ModalFooter className="d-flex justify-content-center">
          <button
            disabled={alertDataMiddle && alertDataMiddle.length === 0}
            className="btn btn-outline-primary btn-lg mb-3"
            onClick={onDownload("Performance_STEP_Alertes", 3, toggleMiddle)}
          >
            Télécharger
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalOut} toggle={toggleOut} size="xl">
        <ModalHeader
          toggle={toggleOut}
          className="text-primary pl-5"
          close={CloseButtonOut}
        >
          Rejets / Sortie STEP - Alertes
        </ModalHeader>
        <ModalBody className="p-5">
          {alertDataOut && alertDataOut.length > 0 ? (
            <>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center">Date apparition</th>
                    <th className="text-center">Heure apparition</th>
                    <th className="text-center">Criticité</th>
                    <th className="text-center">Libellé alarme</th>
                    <th className="text-center">Date et heure de résolution</th>
                  </tr>
                </thead>
                <tbody>{AlertData(alertDataOut)}</tbody>
              </table>
            </>
          ) : (
            <div className="d-flex justify-content-center alert-text ">
              Aucune Alerte
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <button
            disabled={alertDataOut && alertDataOut.length === 0}
            className="btn btn-outline-primary btn-lg mb-3"
            onClick={onDownload("Rejets_Sortie STEP_Alertes", 2, toggleOut)}
          >
            Télécharger
          </button>
        </ModalFooter>
      </Modal>

      {!siteInformation.siteId && (
        <div className="Spinner">
          <Spinner style={{ color: "#FFCD00" }} />
        </div>
      )}
      {siteInformation.siteId && (
        <div className="backgroundwave">
          <div id="preload" className="d-none">
            <img src={iconclosehover} alt="preloadicone" />
            <img src={iconenvironnementhover} alt="preloadicone" />
            <img src={icondirtywaterhover} alt="preloadicone" />
          </div>
          <Container fluid>
            <div className="contain">
              <div className="animated fadeIn mt-3 mx-4 wave">
                <div className="d-flex justify-content-between">
                  <div className="Label-Week ml-n4">
                    <strong>{dateState.label}</strong>
                  </div>
                  <div className="d-flex  justify-content-end">
                    <button
                      type="button"
                      onClick={() => dateStateDispach({ type: "Mois" })}
                      className={`${
                        dateState.selected === "Mois" ? "btn-selected" : ""
                      } btn-pill btn btn-secondary text-nowrap firstUpper w-100`}
                    >
                      {getMonth().btnLabel}
                    </button>
                    <button
                      type="button"
                      onClick={() => dateStateDispach({ type: "Semaine" })}
                      className={`${
                        dateState.selected === "Semaine" ? "btn-selected" : ""
                      } btn-pill btn btn-secondary text-nowrap w-100`}
                    >
                      {getWeek().btnLabel}
                    </button>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="d-flex flex-grow-1 justify-content-center position-relative ">
                    <div className="d-flex flex-column align-items-center">
                      <button
                        onClick={() => goTo("/stepin")}
                        type="button"
                        className="btn btn-img dirtywater"
                      ></button>
                      <img
                        src={ArrowIcon}
                        alt="arrow"
                        className="arrow-btn-img"
                        style={{ right: -45 }}
                      />
                      <label>Sortie usine / Entrée STEP</label>
                    </div>
                  </div>

                  <div className="d-flex flex-grow-1 justify-content-center position-relative">
                    {/* <Link to="/collectinformations" >    <img src={StepImg} type="image" className="btn-img" /> </Link> */}
                    <div className="d-flex flex-column align-items-center">
                      <button
                        onClick={() => {}}
                        type="button"
                        className="btn btn-img performance"
                      ></button>
                      <img
                        src={ArrowIcon}
                        alt="arrow"
                        className="arrow-btn-img"
                        style={{ right: -15 }}
                      />
                      <label>Performance STEP</label>
                    </div>
                  </div>

                  <div className="d-flex flex-grow-1 justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                      <button
                        onClick={() => goTo("/stepout")}
                        type="button"
                        className="btn btn-img environnement"
                      ></button>
                      <label>Rejets / Sortie STEP</label>
                    </div>
                  </div>
                </div>

                <div className="pt-3 d-flex no-gutters flex-wrap">
                  <div className="col-md-4 col-12 pr-lg-2 mb-1">
                    <button
                      tabIndex={0}
                      type="button"
                      onClick={toggle}
                      className={` ${
                        alertData && alertData.length > 0 ? "d-flex" : "d-none"
                      } w-100 btn-go flex-grow-1 align-items-center pl-3  home-btn home-btn-alert text-decoration-none`}
                    >
                      <img className="mr-2" src={AlertIcon} alt="arrow" />{" "}
                      <div>
                        {alertData && alertData.length === 1
                          ? `${alertData && alertData[0].libelleVoieFr}`
                          : `${alertData && alertData.length} alertes`}
                      </div>
                    </button>
                  </div>

                  {/* d-none d-xl-block */}
                  <div className="col-12 col-md-4 flex-grow-1 px-md-4 mb-1">
                    <button
                      tabIndex={0}
                      type="button"
                      onClick={toggleMiddle}
                      className={` ${
                        alertDataMiddle && alertDataMiddle.length > 0
                          ? "d-flex"
                          : "d-none"
                      } w-100 btn-go flex-grow-1 align-items-center pl-3  home-btn home-btn-alert text-decoration-none`}
                    >
                      <img className="mr-2" src={AlertIcon} alt="arrow" />{" "}
                      <div>
                        {alertDataMiddle && alertDataMiddle.length === 1
                          ? `${
                              alertDataMiddle &&
                              alertDataMiddle[0].libelleVoieFr
                            }`
                          : `${
                              alertDataMiddle && alertDataMiddle.length
                            } alertes`}
                      </div>
                    </button>
                  </div>
                  <div className="col 12 col-md-4 flex-grow-1 btn-go mb-1">
                    <button
                      tabIndex={0}
                      type="button"
                      onClick={toggleOut}
                      className={` ${
                        alertDataOut && alertDataOut.length > 0
                          ? "d-flex"
                          : "d-none"
                      } w-100 btn-go flex-grow-1 align-items-center pl-3  home-btn home-btn-alert text-decoration-none`}
                    >
                      <img className="mr-2" src={AlertIcon} alt="arrow" />{" "}
                      <div>
                        {alertDataOut && alertDataOut.length === 1
                          ? `${alertDataOut && alertDataOut[0].libelleVoieFr}`
                          : `${alertDataOut && alertDataOut.length} alertes`}
                      </div>
                    </button>
                  </div>
                </div>

                <div className="d-flex flex-wrap no-gutters">
                  <div className="flex-grow-1 col-12 col-md-4 mt-3 pr-lg-2">
                    <div className="flex-grow-1  widget mb-3">
                      <div className="p-3 widget">
                        <VolumeWater
                          mini={true}
                          DateDebut={dateState.dateDebut}
                          DateFin={dateState.dateFin}
                          Action="Jour"
                          IndicateurSuiviId="NE162"
                          Titre="Volume Effluents bruts"
                          Moyen="0"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1 p-3 widget">
                      <DCO
                        etapeTraitementId="1"
                        dateDebut={dateState.dateDebut}
                        dateFin={dateState.dateFin}
                        action="Jour"
                        indicateurSuiviId={siteConfig?.homePageDCOIn ?? "NE4"}
                        titre="DCO entrée"
                        moyen="0"
                        authentification={authentification}
                        siteInformation={siteInformation}
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1  col-12 col-md-4  mt-3 px-lg-2">
                    <div className="mx-md-4 flex-grow-1  widget mb-3 placehold">
                      <div className="ml-4 pt-3 placehold-title">Énergie</div>
                      <div className="justify-content-center d-flex">
                        <img src={PlaceHolderImg} alt="placeholder" />
                      </div>
                    </div>
                    <div className="mx-md-4 flex-grow-1  widget mb-3 placehold ">
                      <div className="ml-4 pt-3 placehold-title">Réactifs</div>{" "}
                      <div className="justify-content-center d-flex">
                        {" "}
                        <img src={PlaceHolderImg} alt="placeholder" />{" "}
                      </div>{" "}
                    </div>
                    <div className="mx-md-4 flex-grow-1  widget placehold ">
                      <div className="ml-4 pt-3 placehold-title">
                        Sous-produits
                      </div>{" "}
                      <div className="justify-content-center d-flex">
                        {" "}
                        <img src={PlaceHolderImg} alt="placeholder" />{" "}
                      </div>{" "}
                    </div>
                  </div>
                  <div className="flex-grow-1  mt-3 col-md-4 pl-lg-2">
                    <div className="flex-grow-1  widget mb-3 p-3">
                      <VolumeWater
                        mini={true}
                        DateDebut={dateState.dateDebut}
                        DateFin={dateState.dateFin}
                        Action="Jour"
                        IndicateurSuiviId="NE157"
                        Titre="volume effluents traités"
                        Moyen="0"
                      />
                    </div>
                    <div className="flex-grow-1  widget p-3">
                      <DCO
                        etapeTraitementId="2"
                        dateDebut={dateState.dateDebut}
                        dateFin={dateState.dateFin}
                        action="Jour"
                        indicateurSuiviId={siteConfig?.homePageDCOIn ?? "NE5"}
                        titre="DCO sortie"
                        moyen="0"
                        authentification={authentification}
                        siteInformation={siteInformation}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex Label-Widget py-3 justify-content-end no-gutters">
                  <div className="col 12 col-md-4 d-none d-xl-block flex-grow-1 btn-go "></div>
                  <div className="col-12 col-md-4 d-none d-xl-block  flex-grow-1"></div>
                  <div className="col-md-4 col-12 pl-lg-2">
                    <button
                      tabIndex={1}
                      disabled
                      className="py-4 px-2 border-0 border-0 btn-go w-100 d-flex flex-grow-1 align-items-center pl-3 home-btn text-decoration-none home-btn-info justify-content-between flex-wrap"
                    >
                      <div className="info-title mx-auto pb-2">
                        {" "}
                        Conformité DCO
                      </div>
                      <div className="d-flex mx-auto">
                        <div className="px-4 border-right">
                          <div className="text-primary">
                            {conform ? `${conform} %` : "-"}
                          </div>
                          <div>
                            <small className="text-black-50">
                              Concentration
                            </small>
                          </div>
                        </div>
                        <div className="px-4">
                          <div className="text-primary">
                            {charge ? `${charge} %` : "-"}
                          </div>
                          <div>
                            <small className="text-black-50">Charge</small>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>

                <Link
                  to="/collectinformations"
                  className="d-flex flex-grow-1 btn-go align-items-center pl-3 home-btn home-text justify-content-between text-decoration-none"
                >
                  <span className="d-flex align-items-center">
                    <span className="home-btn-plus mr-4">+</span>
                    <span className="font-lg">Ajouter des données STEP</span>
                  </span>
                  <img className="mr-3" src={ChevronImg} alt="arrow" />
                </Link>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}

export default connect((state) => state)(Home);
