// import { SsoResponse } from "../../interfaces/ssoResponse";
import { ReduxAuthType } from "../../types/authentificationstoretypes";
import { LocalStorage } from "../../utils/localStorage";
import { parseJwt } from "../../utils/utils";
import Keycloak from "keycloak-js";
import { useKeycloak } from "@react-keycloak/web";

export const login = (responce: Keycloak, userInfos: any) => {
  const parsedToken = parseJwt(responce.token!);
  LocalStorage.token.set(responce.token ?? "");
  LocalStorage.refreshToken.set(responce.refreshToken ?? "");
  console.log("responce", responce);
  console.log("parsedToken", parsedToken);
  return {
    type: ReduxAuthType.LoginSuccess,
    payload: {
      token: responce.token,
      userInfo: {
        name: userInfos.email, //responce.profile.preferred_username,
        email: userInfos.email,
        roles: userInfos.roles,
      },
      refreshToken: responce.refreshToken,
    },
  };
};

export const refreshToken = (token: string, refreshToken: string) => {
  try {
    const parsedToken = parseJwt(token!);
    LocalStorage.token.set(token ?? "");
    LocalStorage.refreshToken.set(refreshToken ?? "");
    console.log("refreshToken", token);
    return {
      type: ReduxAuthType.LoginSuccess,
      payload: {
        token: token,
        userInfo: {
          name: parsedToken.email, //profile.preferred_username,
          email: parsedToken.email,
          roles: parsedToken.roles,
        },
        refreshToken: refreshToken,
      },
    };
  } catch {
    logout();
  }
};

export const logout = () => {
  localStorage.setItem("logginWith", "");
  localStorage.clear();
  return {
    type: ReduxAuthType.Initializing,
    payload: {},
  };
};

export {};
