//Librairies
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  //Card, CardBody, CardColumns,
  Button,
  ButtonToolbar,
  Spinner,
  Container,
} from "reactstrap";
import { set, format } from "date-fns";
//Application
// import VolumeWater from '../../components/VolumeWater/VolumeWater'
// import Ph from '../../components/PH/Ph.js'
// import WaterQuality from '../../components/WaterQuality/WaterQuality.js'
// import WaterTemperature from '../../components/WaterTemperature/WaterTemperature.js'
import DateRangePicker from "../StepIn/Datepicker";
import { StartOfWeek, month_name } from "../core";
//Assets
import "../../styles/Button.css";
import "../../styles/Label.css";
import "../../styles/Spinner.css";
import { ButtonPeriodBar, CardView } from "../StepOut/StepOut";

class StepIn extends Component {
  constructor(props) {
    super(props);
    this.DateNow = new Date();
    this.OnClickAction = this.OnClickAction.bind(this);
    this.OnClichChangeDate = this.OnClichChangeDate.bind(this);

    let datedebut = new Date(
      this.DateNow.getFullYear(),
      this.DateNow.getMonth(),
      this.DateNow.getDate() - 14
    );
    let datefin = new Date(
      this.DateNow.getFullYear(),
      this.DateNow.getMonth(),
      this.DateNow.getDate(),
      0,
      0,
      0
    );
    this.state = {
      action: "Jour",
      DateDebut: datedebut,
      DateFin: datefin,
      TextInterval: `Du ${datedebut.getDate()} ${month_name(
        datedebut
      )} au ${datefin.getDate()} ${month_name(datefin)}`,
      DisableButton: true,
      CustomDate: false,
    };
  }

  // On click Next or Privious
  OnClichChangeDate(props) {
    if (props === "Next") {
      this.ChangeDate(1);
    } else {
      this.ChangeDate(-1);
    }
  }

  // Method to change date
  ChangeDate(prop) {
    let datedebut, datefin;
    switch (this.state.action) {
      case "Semaine":
        datedebut = new Date(
          this.state.DateDebut.getFullYear(),
          this.state.DateDebut.getMonth(),
          this.state.DateDebut.getDate() + prop * 7
        );
        datefin = new Date(
          this.state.DateFin.getFullYear(),
          this.state.DateFin.getMonth(),
          this.state.DateFin.getDate() + prop * 7
        );
        this.setState({
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} au ${datefin.getDate()} ${month_name(datefin)}`,
          DisableButton:
            datefin.getFullYear() === this.DateNow.getFullYear() &&
            datefin.getMonth() === this.DateNow.getMonth() &&
            datefin.getDate() === this.DateNow.getDate()
              ? true
              : false,
        });
        break;
      case "Heure":
        datedebut = new Date(
          this.state.DateDebut.getFullYear(),
          this.state.DateDebut.getMonth(),
          this.state.DateDebut.getDate(),
          this.state.DateDebut.getHours() + prop,
          0,
          0
        );
        datefin = new Date(
          this.state.DateFin.getFullYear(),
          this.state.DateFin.getMonth(),
          this.state.DateFin.getDate(),
          this.state.DateFin.getHours() + prop,
          0,
          0
        );
        this.setState({
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} ${datedebut.getHours()}h au ${datefin.getDate()} ${month_name(
            datefin
          )} ${datefin.getHours()}h`,
          DisableButton:
            datefin.getFullYear() === this.DateNow.getFullYear() &&
            datefin.getMonth() === this.DateNow.getMonth() &&
            datefin.getDate() === this.DateNow.getDate() &&
            datefin.getHours() === 0
              ? true
              : false,
        });
        break;
      case "Jour":
        datedebut = new Date(
          this.state.DateDebut.getFullYear(),
          this.state.DateDebut.getMonth(),
          this.state.DateDebut.getDate() + prop
        );
        datefin = new Date(
          this.state.DateFin.getFullYear(),
          this.state.DateFin.getMonth(),
          this.state.DateFin.getDate() + prop
        );
        this.setState({
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} au ${datefin.getDate()} ${month_name(datefin)}`,
          DisableButton:
            datefin.getFullYear() === this.DateNow.getFullYear() &&
            datefin.getMonth() === this.DateNow.getMonth() &&
            datefin.getDate() === this.DateNow.getDate()
              ? true
              : false,
        });
        break;
      case "Annee":
        datedebut = new Date(this.state.DateDebut.getFullYear() + prop, 0, 1);
        datefin = new Date(this.state.DateFin.getFullYear() + prop, 0, 1);
        this.setState({
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `De ${datedebut.getFullYear()} à ${datefin.getFullYear()} `,
          DisableButton:
            datefin.getFullYear() === this.DateNow.getFullYear() ? true : false,
        });
        break;
      case "Mois":
        datedebut = new Date(
          this.state.DateDebut.getFullYear(),
          this.state.DateDebut.getMonth() + prop,
          1
        );
        datefin = new Date(
          this.state.DateFin.getFullYear(),
          this.state.DateFin.getMonth() + prop,
          1
        );
        this.setState({
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `De ${month_name(
            datedebut
          )} ${datedebut.getFullYear()} à ${month_name(
            datefin
          )} ${datefin.getFullYear()}`,
          DisableButton:
            datefin.getFullYear() === this.DateNow.getFullYear() &&
            datefin.getMonth() === this.DateNow.getMonth()
              ? true
              : false,
        });
        break;

      default:
        break;
    }
  }

  // On click Buttons Annee - Mois - Semaine - Jour - Heure
  OnClickAction(props, payload) {
    let datedebut, datefin;

    switch (props) {
      case "Custom":
        const { start, end, time } = payload;
        datedebut = start;
        datefin = end;
        this.setState({
          action: time,
          DateDebut: set(datedebut, { hours: 0, minutes: 0, seconds: 0 }),
          DateFin: set(datefin, { hours: 0, minutes: 0, seconds: 0 }),
          TextInterval: `${format(datedebut, "d MMMM yyy")}  au ${format(
            datefin,
            "d MMMM yyy"
          )}`,
          DisableButton: true,
          CustomDate: true,
        });
        break;

      case "Mois":
        datedebut = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth() - 11,
          1,
          0,
          0,
          0
        );
        datefin = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          1,
          0,
          0,
          0
        );
        this.setState({
          action: "Mois",
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `De ${month_name(
            datedebut
          )} ${datedebut.getFullYear()} à ${month_name(
            datefin
          )} ${datefin.getFullYear()}`,
          DisableButton: true,
          CustomDate: false,
        });
        break;
      case "Semaine":
        datedebut = StartOfWeek(
          new Date(
            this.DateNow.getFullYear(),
            this.DateNow.getMonth() - 3,
            this.DateNow.getDate()
          ),
          0,
          0,
          0
        );
        datefin = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          this.DateNow.getDate(),
          0,
          0,
          0
        );
        this.setState({
          action: "Semaine",
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} au ${datefin.getDate()} ${month_name(datefin)}`,
          DisableButton: true,
          CustomDate: false,
        });
        break;
      case "Annee":
        datedebut = new Date(this.DateNow.getFullYear() - 4, 0, 1, 0, 0, 0);
        datefin = new Date(this.DateNow.getFullYear(), 0, 1, 0, 0, 0);
        this.setState({
          action: "Annee",
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `De ${datedebut.getFullYear()} à ${datefin.getFullYear()} `,
          DisableButton: true,
          CustomDate: false,
        });
        break;
      case "Jour":
        datedebut = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          this.DateNow.getDate() - 14,
          0,
          0,
          0
        );
        datefin = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          this.DateNow.getDate(),
          0,
          0,
          0
        );
        this.setState({
          action: "Jour",
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} au ${datefin.getDate()} ${month_name(datefin)}`,
          DisableButton: true,
          CustomDate: false,
        });
        break;
      case "Heure":
        datedebut = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          this.DateNow.getDate() - 1,
          0,
          0,
          0
        );
        datefin = new Date(
          this.DateNow.getFullYear(),
          this.DateNow.getMonth(),
          this.DateNow.getDate(),
          0,
          0,
          0
        );
        this.setState({
          action: "Heure",
          DateDebut: datedebut,
          DateFin: datefin,
          TextInterval: `Du ${datedebut.getDate()} ${month_name(
            datedebut
          )} ${datedebut.getHours()}h au ${datefin.getDate()} ${month_name(
            datefin
          )} ${datefin.getHours()}h`,
          DisableButton: true,
          CustomDate: false,
        });
        break;

      default:
        break;
    }
  }

  render() {
    return (
      <Container fluid>
        <div style={{ marginTop: "20px" }} className="animated fadeIn">
          {!this.props.siteInformation.siteId && (
            <div className="Spinner">
              <Spinner style={{ color: "#FFCD00" }} />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              marginBottom: "15px",
              borderBottom: "1px solid #B4B6B5",
            }}
          >
            <label style={{ fontSize: "18px", color: "#B4B6B5" }}>
              ENTRÉE STEP
            </label>
          </div>
          {this.props.siteInformation.siteId && (
            <React.Fragment>
              <div className="">
                <ButtonPeriodBar
                  CustomDate={this.state.CustomDate}
                  DateDebut={this.state.DateDebut}
                  DateFin={this.state.DateFin}
                  OnClickAction={this.OnClickAction}
                  action={this.state.action}
                />
              </div>
              <div className="d-flex">
                <DateRangePicker
                  textInterval={this.state.TextInterval}
                  start={this.state.DateDebut}
                  end={this.state.DateFin}
                  onSubmitDate={({ start, end }) => {
                    this.OnClickAction("Custom", {
                      start,
                      end,
                      time: this.state.action,
                    });
                  }}
                  onCancelDate={() => {
                    this.OnClickAction("Semaine");
                  }}
                  className="mt-2 mb-2 flex-grow-1"
                />
                {this.state.CustomDate === false && (
                  <ButtonToolbar style={{ marginLeft: "10px" }}>
                    <Button
                      size="sm"
                      className="fa fa-chevron-left fa-lg mt-2 button-icon left-button"
                      onClick={() => this.OnClichChangeDate("Previous")}
                    ></Button>
                    <Button
                      size="sm"
                      disabled={this.state.DisableButton}
                      className="fa fa-chevron-right fa-lg mt-2 button-icon right-button"
                      onClick={() => this.OnClichChangeDate("Next")}
                    ></Button>
                  </ButtonToolbar>
                )}
              </div>
              <CardView
                DateDebut={this.state.DateDebut}
                action={this.state.action}
                DateFin={this.state.DateFin}
                volumeWater={{
                  IndicateurSuiviId: "NE162",
                  Titre: "Volume Effluents bruts",
                  Moyen: "1",
                }}
                ph={{ IndicateurSuiviId: "NE284" }}
                waterTemperature={{ IndicateurSuiviId: "NE130" }}
                waterQuality={{
                  Titre: "qualité de l’effluent brut",
                  GrandeurEvalueeId: "4",
                  EtapeTraitementId: "1",
                }}
              />
            </React.Fragment>
          )}
        </div>
      </Container>
    );
  }
}
export default connect((state) => state)(StepIn);
